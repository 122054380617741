import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { loadServices } from './services.actions';
import { ServicesPartialState } from './services.reducer';
import {
  getServices,
  getServicesError,
  getServicesLoading,
  getServicesState,
} from './services.selectors';

@Injectable()
export class ServicesFacade {
  servicesState$ = this.store.select(getServicesState);
  services$ = this.store.select(getServices);
  servicesLoading$ = this.store.select(getServicesLoading);
  servicesError$ = this.store.select(getServicesError);

  constructor(private store: Store<ServicesPartialState>) {}

  getServices(): void {
    this.store.dispatch(loadServices());
  }
}
