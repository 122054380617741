import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MetaEffects } from './meta.effects';
import { MetaFacade } from './meta.facade';
import { initialMetaState, META_FEATURE_KEY, reducer } from './meta.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(META_FEATURE_KEY, reducer, {
      initialState: initialMetaState,
    }),
    EffectsModule.forFeature([MetaEffects]),
  ],
  providers: [MetaFacade],
})
export class MetaModule {}
