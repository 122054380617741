<div class="contacts" #contactsWrap>
  <div class="contacts__noise">
    <app-noise></app-noise>
  </div>
  <div class="container">
    <div class="contacts__inner">
      <div
        class="contacts__title-wrap contacts__title-wrap_1"
        (mouseover)="titleLineAnimation(true)"
        (mouseleave)="titleLineAnimation(false)"
      >
        <div class="cell cell_space-2"></div>
        <div class="cell cell_space-4">
          <h2 class="contacts__title">
            <span
              class="contacts__title-inner"
              #title
              appSplitText
              [maskPadding]="2.3"
              [maskPaddingUnits]="'rem'"
              >Офис</span
            >
            <span
              class="contacts__title-inner contacts__title-inner_mobile"
              #title
              appSplitText
              [maskPadding]="2.3"
              [maskPaddingUnits]="'rem'"
              >Офис на</span
            >
          </h2>
        </div>
        <div class="cell cell_space-2 contacts__description-cell">
          <p class="text text_caps contacts__description">
            <span class="contacts__description-inner" #description appSplitText>
              Да, мы находимся <br />
              в < 1 км от кремля
            </span>
            <span
              class="contacts__description-icon"
              #icon
              app-icon
              [iconName]="iconName.MapPin"
              [iconSize]="iconSize.M"
            ></span>
          </p>
        </div>
      </div>
      <div
        class="contacts__title-wrap contacts__title-wrap_2"
        (mouseover)="titleLineAnimation(true)"
        (mouseleave)="titleLineAnimation(false)"
      >
        <div class="cell"></div>
        <div class="cell cell_space-7">
          <h2 class="contacts__title">
            <span
              class="contacts__title-inner"
              #title
              appSplitText
              [maskPadding]="2.3"
              [maskPaddingUnits]="'rem'"
              >На красном</span
            >
            <span
              class="contacts__title-inner contacts__title-inner_mobile"
              #title
              appSplitText
              [maskPadding]="2.3"
              [maskPaddingUnits]="'rem'"
              >красном</span
            >
            <span class="contacts__title-line">
              <svg
                #line
                bdtSvgDrawStroke
                width="850"
                height="206"
                viewBox="0 0 850 206"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M299.175 82.897C664.197-22.49 319.645-19.185 30.993 59.737c-51.34 14.036-78.064 60.684 311.515 54.002 560.074-9.607 462.987 20.77 432.978 30.117-169.18 49.014-29.579 61.099 72.514 60.087"
                  stroke="#EB4626"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </h2>
        </div>
      </div>
      <div
        class="contacts__title-wrap contacts__title-wrap_3"
        (mouseover)="titleLineAnimation(true)"
        (mouseleave)="titleLineAnimation(false)"
      >
        <div class="cell cell_space-3"></div>
        <div class="cell cell_space-5">
          <h2 class="contacts__title">
            <span
              class="contacts__title-inner"
              #title
              appSplitText
              [maskPadding]="2.3"
              [maskPaddingUnits]="'rem'"
              >Октябре</span
            >
            <span
              class="contacts__title-inner contacts__title-inner_mobile"
              #title
              appSplitText
              [maskPadding]="2.3"
              [maskPaddingUnits]="'rem'"
              >Октябре</span
            >
          </h2>
        </div>
      </div>
      <div class="contacts__info-wrap">
        <div class="cell cell_space-3"></div>
        <div class="cell cell_space-3">
          <p class="contacts__info">
            <span
              class="subtitle subtitle_medium contacts__info-address"
              #info
              appSplitText
              >Берсеневский переулок, 5 стр. 1</span
            ><br />
            <app-link
              #link
              class="contacts__info-tel"
              [title]="'+7 (499) 704 63 34'"
              [href]="'tel:+74997046334'"
              [reverse]="true"
              [linkSize]="linkSize.M"
              [iconSize]="iconSize.S"
            ></app-link>
            <br />
            <app-link
              #link
              class="contacts__info-email"
              [title]="'hello@betaagency.ru'"
              [href]="'mailto:hello@betaagency.ru'"
              [linkSize]="linkSize.M"
              [iconSize]="iconSize.S"
            ></app-link>
          </p>
        </div>
        <div class="cell cell_space-2">
          <app-button
            #button
            [buttonTitle]="'Связаться с бетой'"
            [url]="'mailto:hello@betaagency.ru'"
            [iconSize]="iconSize.S"
            [iconName]="iconName.ButtonArrowRight"
            [lineColor]="buttonLineColor.Red"
            [lineType]="buttonLineType.Three"
            [reverseColor]="true"
          ></app-button>
        </div>
      </div>
      <div class="contacts__images">
        <div class="contacts__bg" #bg></div>
        <div class="contacts__images-inner">
          <div class="contacts__image">
            <div
              class="contacts__image-mask"
              appParallax
              [trigger]="contactsWrap"
              [speedDesktop]="2"
              [topPaddingDesktop]="-0.3"
            >
              <div
                class="contacts__image-inner"
                appParallax
                [trigger]="contactsWrap"
                [speedDesktop]="-2"
                [topPaddingDesktop]="-0.3"
              ></div>
            </div>
          </div>

          <div class="contacts__image">
            <div
              class="contacts__image-mask"
              appParallax
              [trigger]="contactsWrap"
              [speedDesktop]="4.5"
              [topPaddingDesktop]="-0.3"
            >
              <div
                class="contacts__image-inner"
                appParallax
                [trigger]="contactsWrap"
                [speedDesktop]="-2"
                [topPaddingDesktop]="-0.3"
              ></div>
            </div>
          </div>
          <div class="contacts__image">
            <div
              class="contacts__image-mask"
              appParallax
              [trigger]="contactsWrap"
              [speedDesktop]="6"
              [topPaddingDesktop]="-0.3"
            >
              <div
                class="contacts__image-inner"
                appParallax
                [trigger]="contactsWrap"
                [speedDesktop]="-2"
                [topPaddingDesktop]="-0.3"
              ></div>
            </div>
          </div>
          <div class="contacts__image">
            <div
              class="contacts__image-mask"
              appParallax
              [trigger]="contactsWrap"
              [speedDesktop]="7"
              [topPaddingDesktop]="-0.3"
            >
              <div
                class="contacts__image-inner"
                appParallax
                [trigger]="contactsWrap"
                [speedDesktop]="-1"
                [topPaddingDesktop]="-0.3"
              ></div>
            </div>
          </div>
          <div class="contacts__image">
            <div
              class="contacts__image-mask"
              appParallax
              [trigger]="contactsWrap"
              [speedDesktop]="9"
              [topPaddingDesktop]="-0.3"
            >
              <div
                class="contacts__image-inner"
                appParallax
                [trigger]="contactsWrap"
                [speedDesktop]="-1"
                [topPaddingDesktop]="-0.3"
              ></div>
            </div>
          </div>
        </div>
        <div class="contacts__image-description-wrap">
          <div class="cell cell_space-6"></div>
          <div class="cell">
            <p class="text text_caps contacts__image-description">
              Мы за личное общение. <br />
              приходите в гости <br />
              (особенно по пятницам)
            </p>
          </div>
          <div class="cell"></div>
        </div>
      </div>
    </div>
  </div>
</div>
