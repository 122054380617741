import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProjectsFacade } from '@beta/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, mapTo, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ProjectsResolver implements Resolve<boolean> {
  constructor(private projectsFacade: ProjectsFacade) {}

  resolve(): Observable<boolean> {
    return this.projectsFacade.projectsState$.pipe(
      tap((projectsState) => {
        if (
          !projectsState.projects &&
          !projectsState.loading &&
          !projectsState.error
        ) {
          this.projectsFacade.getProjects();
        }
      }),
      filter(
        (projectsState) =>
          !projectsState.loading &&
          !projectsState.error &&
          !!projectsState.projects,
      ),
      take(1),
      mapTo(true),
      untilDestroyed(this),
    );
  }
}
