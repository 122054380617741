// TODO: сделать токены приватными (окружение)
export const airtableBaseToken = 'appuUQco95ZFRsyYT';

export interface AirtableRecord {
  id: string;
  fields: { [key: string]: any };
  createdTime: string;
}

export interface AirtableResponse {
  records: AirtableRecord[];
}

export class AirtableImage {
  public src!: string;

  deserialize(image: any): AirtableImage {
    if (image['url']) {
      this.src = image['url'];
    }
    return this;
  }
}

export enum Lang {
  Ru = 'ru',
  En = 'en',
}
