import { HeaderImage } from '@beta/api';
import { Action, createReducer, on } from '@ngrx/store';

import {
  loadHeaderImages,
  loadHeaderImagesFailure,
  loadHeaderImagesSuccess,
} from './header-images.actions';

export const HEADER_IMAGES_FEATURE_KEY = 'headerImages';

export interface HeaderImagesState {
  images: HeaderImage[] | undefined;
  loading: boolean;
  error: string | null;
}

export interface HeaderImagesPartialState {
  readonly [HEADER_IMAGES_FEATURE_KEY]: HeaderImagesState;
}

export const initialHeaderImagesState: HeaderImagesState = {
  images: undefined,
  loading: false,
  error: null,
};

const headerImagesReducer = createReducer(
  initialHeaderImagesState,
  on(loadHeaderImages, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadHeaderImagesSuccess, (state, { images }) => ({
    ...state,
    images,
    loading: false,
  })),
  on(loadHeaderImagesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: HeaderImagesState | undefined, action: Action) =>
  headerImagesReducer(state, action);
