import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
} from '@angular/core';
import { ResizeService, SmoothScroll, SmoothScrollService } from '@beta/core';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap } from 'gsap';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';

import { getRelativeValue } from '../helpers';

@UntilDestroy()
@Directive({
  selector: '[appParallax], appParallax',
})
export class ParallaxDirective implements AfterViewInit {
  @Input() trigger!: HTMLElement;
  @Input() speedDesktop = 0;
  @Input() speedTablet = 0;
  @Input() speedMobile = 0;
  @Input() topPaddingDesktop = 0;
  @Input() topPaddingTablet = 0;
  @Input() topPaddingMobile = 0;

  private triggerTop = 0;
  private triggerOffsetHeight = 0;

  constructor(
    private el: ElementRef<HTMLElement>,
    private smoothScrollService: SmoothScrollService,
    private resizeService: ResizeService,
    @Inject(WINDOW) private window: Window,
  ) {}

  ngAfterViewInit(): void {
    this.updateValues();
    this.init();
    this.onResize();
  }

  updateValues(): void {
    this.window.requestAnimationFrame(() => {
      this.triggerTop =
        (this.smoothScrollService.smoothScroll?.offsetY || 0) +
        this.trigger.getBoundingClientRect().top;
      this.triggerOffsetHeight = this.trigger.offsetHeight;
    });
  }

  onResize(): void {
    this.resizeService.currentResolution$
      .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.updateValues();
      });
  }

  init(): void {
    this.smoothScrollService.smoothScroll$
      .pipe(
        filter((value): value is SmoothScroll => !!value),
        untilDestroyed(this),
      )
      .subscribe((value) => {
        this.window.requestAnimationFrame(() => {
          if (this.window.innerWidth > 1024) {
            this.parallaxAnimation(
              value.offsetY,
              this.speedDesktop,
              this.topPaddingDesktop,
              1920,
            );
          }
        });
        // } else if (this.window.innerWidth > 428) {
        //   this.parallaxAnimation(
        //     value.offsetY,
        //     this.speedTablet,
        //     this.topPaddingTablet,
        //     1024,
        //   );
        // } else {
        //   this.parallaxAnimation(
        //     value.offsetY,
        //     this.speedMobile,
        //     this.topPaddingMobile,
        //     428,
        //   );
        // }
      });
  }

  parallaxAnimation(
    scroll: number,
    speed: number,
    topPadding: number,
    width: number,
  ): void {
    const start =
      this.triggerTop -
      this.window.innerHeight -
      (this.triggerOffsetHeight / 100) * (topPadding * 100);
    const end = this.triggerTop + this.triggerOffsetHeight;
    const shift = speed * ((100 * this.window.innerWidth) / width / 100) * 100;
    gsap.set(this.el.nativeElement, {
      y: getRelativeValue(start, end, shift, -shift, scroll),
    });
  }
}
