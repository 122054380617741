import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { TransitionService } from '@beta/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap, Power2, Power3 } from 'gsap';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-transition',
  templateUrl: './transition.component.html',
  styleUrls: ['./transition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionComponent implements AfterViewInit {
  @ViewChild('bgOne') bgOne!: ElementRef<HTMLElement>;
  @ViewChild('bgTwo') bgTwo!: ElementRef<HTMLElement>;

  constructor(private transitionService: TransitionService) {}

  tlTransition = gsap.timeline();

  ngAfterViewInit(): void {
    this.setDefaultPosition();
    this.initHideAnimation();

    this.transitionService.transitionCloseStart$
      .pipe(
        filter((closeStart) => closeStart),
        untilDestroyed(this),
      )
      .subscribe(() => {
        console.log('CLOSE START');
        this.tlTransition.resume();
      });

    combineLatest([
      this.transitionService.transitionCloseEnd$,
      this.transitionService.transitionOpenStart$,
    ])
      .pipe(
        filter(([closeEnd, openStart]) => closeEnd && openStart),
        untilDestroyed(this),
      )
      .subscribe(() => {
        console.log('OPEN START');
        this.tlTransition.resume();
      });
  }

  initHideAnimation(): void {
    this.tlTransition
      .clear()
      .fromTo(
        this.bgTwo.nativeElement,
        {
          scaleY: 0,
        },
        {
          scaleY: 1,
          duration: 0.8,
          ease: Power3.easeInOut,
        },
      )
      .set(this.bgOne.nativeElement, {
        scaleY: 1,
      })
      .set(this.bgTwo.nativeElement, {
        transformOrigin: '50% 0',
      })
      .add(() => {
        this.tlTransition.pause();
        console.log('CLOSE END');
        console.log('NAVIGATION...');
        this.transitionService.transitionCloseEnd = true;
      })
      .fromTo(
        this.bgTwo.nativeElement,
        {
          scaleY: 1,
        },
        {
          scaleY: 0,
          duration: 0.8,
          ease: Power3.easeInOut,
          immediateRender: false,
        },
      )
      .fromTo(
        this.bgOne.nativeElement,
        {
          scaleY: 1,
        },
        {
          scaleY: 0,
          duration: 0.8,
          ease: Power2.easeInOut,
          immediateRender: false,
        },
        0.9,
      )
      .add(() => {
        console.log('OPEN END');
        this.transitionService.transitionOpenEnd = true;
      }, '-=0.5')
      .add(() => {
        this.tlTransition.restart().pause();
      })
      .pause();
  }

  setDefaultPosition(): void {
    gsap.set(this.bgOne.nativeElement, {
      scaleY: 0,
      transformOrigin: '50% 0',
    });

    gsap.set(this.bgTwo.nativeElement, {
      scaleY: 0,
      transformOrigin: '50% 100%',
    });
  }
}
