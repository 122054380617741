<div class="preloader">
  <div class="preloader__bg preloader__bg_1" #bgOne></div>
  <div class="preloader__bg preloader__bg_2" #bgTwo></div>
  <div class="container preloader__container">
    <div class="preloader__inner">
      <div class="preloader__title-wrap">
        <div class="cell"></div>
        <div class="cell">
          <span class="title preloader__title">
            <span
              class="preloader__title-part"
              #logo
              appSplitText
              [maskPadding]="1.5"
              [maskPaddingUnits]="'rem'"
              >Бета</span
            >
            <span
              class="preloader__title-part"
              #logo
              appSplitText
              [maskPadding]="1.5"
              [maskPaddingUnits]="'rem'"
              >Это</span
            >
          </span>
        </div>
        <div class="cell cell_space-5">
          <div class="preloader__subtitle-wrap">
            <div class="preloader__subtitle-wrap-inner">
              <span class="title preloader__subtitle">Дизайн</span>
              <span class="title preloader__subtitle" #subtitle>
                <span class="preloader__subtitle-part">Дизайн</span>
                <span class="preloader__subtitle-part">Разработка</span>
                <span class="preloader__subtitle-part">«Без правок»</span>
                <span class="preloader__subtitle-part">Дизайн</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
