import clamp from 'lodash-es/clamp';

export function getRelativeValue(
  aFrom: number,
  aTo: number,
  bFrom: number,
  bTo: number,
  dynamic: number,
): number {
  const bDiff =
    ((bTo - bFrom) * ((100 * (dynamic - aFrom)) / (aTo - aFrom))) / 100;

  return bTo < bFrom
    ? clamp(bFrom + bDiff, bTo, bFrom)
    : clamp(bFrom + bDiff, bFrom, bTo);
}
