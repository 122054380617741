export interface CursorPosition {
  x: number;
  y: number;
}

export enum CursorActionType {
  Active = 'active',
  Hide = 'hide',
}

export interface CursorAction {
  action: CursorActionType;
  active: boolean;
}
