import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  LogoService,
  ResizeService,
  SmoothScroll,
  SmoothScrollService,
} from '@beta/core';
import { getRelativeValue, SplitTextDirective } from '@beta/shared';
import {
  ButtonIconColor,
  ButtonIconComponent,
  ButtonIconSize,
  IconName,
  IconSize,
  LinkComponent,
} from '@beta/ui-common';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap, Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';

import { socialLinks } from './footer.common';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements AfterViewInit {
  @ViewChild('footer') footer!: ElementRef<HTMLElement>;
  @ViewChild('bg') bg!: ElementRef<HTMLElement>;
  @ViewChild('logo') logo!: ElementRef<HTMLElement>;
  @ViewChild('button', { read: ButtonIconComponent })
  button!: ButtonIconComponent;

  @ViewChildren('linkEl', { read: LinkComponent }) linkEl =
    new QueryList<LinkComponent>();

  @ViewChild('event', {
    read: SplitTextDirective,
  })
  event!: SplitTextDirective;

  iconName = IconName;
  iconSize = IconSize;
  buttonIconColor = ButtonIconColor;
  buttonIconSize = ButtonIconSize;
  socialLinks = socialLinks;

  isShowed = false;
  tlShow = gsap.timeline();

  private footerTop = 0;
  private logoTop = 0;

  constructor(
    private smoothScrollService: SmoothScrollService,
    private logoService: LogoService,
    private resizeService: ResizeService,
    @Inject(WINDOW) private window: Window,
  ) {}

  ngAfterViewInit(): void {
    this.updateValues();
    this.initShowAnimation();
    this.showAnimation();
    this.logoAnimation();

    this.onResize();
  }

  updateValues(): void {
    this.window.requestAnimationFrame(() => {
      this.footerTop =
        (this.smoothScrollService.smoothScroll?.offsetY || 0) +
        this.footer.nativeElement.getBoundingClientRect().top;
      this.logoTop =
        (this.smoothScrollService.smoothScroll?.offsetY || 0) +
        this.logo.nativeElement.getBoundingClientRect().top;
    });
  }

  onResize(): void {
    this.resizeService.currentResolution$
      .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.updateValues();
      });
  }

  logoAnimation(): void {
    this.smoothScrollService.smoothScroll$
      .pipe(
        filter((value): value is SmoothScroll => !!value),
        untilDestroyed(this),
      )
      .subscribe((scroll) => {
        this.window.requestAnimationFrame(() => {
          const start = this.footerTop - this.window.innerHeight;
          const end = this.logoTop - this.window.innerHeight;
          this.logoService.logoPosition = getRelativeValue(
            start,
            end,
            0,
            -200,
            scroll.offsetY,
          );
        });
      });
  }

  showAnimation(): void {
    ScrollTrigger.create({
      trigger: this.footer.nativeElement,
      once: true,
      start: '80% 100%',
      onEnter: () => {
        this.tlShow.resume();
      },
    });
  }

  scrollTop(): void {
    if (this.smoothScrollService.scrollbar) {
      this.smoothScrollService.scrollbar.scrollTo(0, 0, 2000);
    } else {
      gsap.set(this.window, {
        scrollTo: {
          y: 0,
        },
      });
    }
  }

  initShowAnimation(): void {
    this.tlShow.clear();
    this.linkEl.forEach((item, index) => {
      this.tlShow.add(item.tlShow, index * 0.1);
    });

    this.tlShow
      .fromTo(
        this.event.wordEls,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        1,
      )
      .fromTo(
        this.bg.nativeElement,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: Power2.easeOut,
        },
        0.5,
      )
      // .fromTo(
      //   this.logo.nativeElement,
      //   {
      //     opacity: 0,
      //     yPercent: 30,
      //   },
      //   {
      //     yPercent: 0,
      //     opacity: 1,
      //     duration: 1,
      //     ease: Power2.easeOut,
      //   },
      //   0.5,
      // )

      .add(this.button.tlShow, 0)
      .add(() => {
        this.isShowed = true;
      })
      .pause();
  }
}
