import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SplitTextDirective } from '@beta/shared';
import {
  ButtonComponent,
  ButtonLineColor,
  ButtonLineType,
  IconName,
  IconSize,
  LinkComponent,
  LinkSize,
} from '@beta/ui-common';
import { SvgDrawStrokeDirective } from '@ng-blue-duct-tape/svg-draw-stroke';
import { Back, gsap, Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsComponent implements AfterViewInit, OnInit {
  @ViewChild('contactsWrap') contactsWrap!: ElementRef<HTMLElement>;

  @ViewChild('line', { read: SvgDrawStrokeDirective })
  line!: SvgDrawStrokeDirective;

  @ViewChildren('title', { read: SplitTextDirective }) title =
    new QueryList<SplitTextDirective>();

  @ViewChild('info', { read: SplitTextDirective })
  info!: SplitTextDirective;

  @ViewChild('description', { read: SplitTextDirective })
  description!: SplitTextDirective;

  @ViewChild('icon', { read: ElementRef }) icon!: ElementRef<HTMLElement>;
  @ViewChild('bg') bg!: ElementRef<HTMLElement>;

  @ViewChildren('link', { read: LinkComponent }) link =
    new QueryList<LinkComponent>();

  @ViewChild('button', { read: ButtonComponent })
  button!: ButtonComponent;

  iconName = IconName;
  iconSize = IconSize;
  buttonLineColor = ButtonLineColor;
  buttonLineType = ButtonLineType;
  linkSize = LinkSize;

  tlLine = gsap.timeline();
  tlShow = gsap.timeline();
  isShowed = false;

  ngOnInit(): void {
    this.decodeImages();
  }

  ngAfterViewInit(): void {
    this.initTitleLineAnimation();
    this.iniShowAnimation();
    this.showAnimation();
  }

  showAnimation(): void {
    ScrollTrigger.create({
      trigger: this.contactsWrap.nativeElement,
      once: true,
      start: '0 50%',
      onEnter: () => {
        this.tlShow.resume();
      },
    });
  }

  decodeImages(): void {
    const images = [
      '/assets/images/contacts/contacts-1.jpg',
      '/assets/images/contacts/contacts-2.jpg',
      '/assets/images/contacts/contacts-3.jpg',
      '/assets/images/contacts/contacts-4.jpg',
      '/assets/images/contacts/contacts-5.jpg',
    ];

    images.forEach((src) => {
      const image = new Image();
      image.src = src;
      image.decode();
    });
  }

  initTitleLineAnimation(): void {
    this.tlLine
      .clear()
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: 0,
        },
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
          duration: 1,
          ease: Power2.easeInOut,
        },
      )
      .pause();
  }

  iniShowAnimation(): void {
    const title = this.title.map((item) => item.wordEls);
    const description = this.description.wordEls;
    const info = this.info.wordEls;
    // const image = this.image.map((item) => item.nativeElement);
    // const imageMask = this.imageMask.map((item) => item.nativeElement);
    // const imageInner = this.imageInner.map((item) => item.nativeElement);

    this.tlShow
      .clear()
      .fromTo(
        title,
        {
          yPercent: 120,
        },
        {
          yPercent: 0,
          stagger: 0.1,
          duration: 1,
          ease: Power2.easeOut,
        },
        0,
      )
      .fromTo(
        description,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        0,
      )
      .fromTo(
        this.icon.nativeElement,
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 0.5,
          ease: Back.easeOut,
        },
        0,
      )
      .fromTo(
        info,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        0.6,
      )
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: 1,
          ease: Power2.easeInOut,
        },
        1,
      );

    this.link.forEach((item) => {
      this.tlShow.add(item.tlShow, 0.6);
    });

    this.tlShow
      .fromTo(
        this.bg.nativeElement,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: Power2.easeOut,
        },
        1,
      )
      // .fromTo(
      //   imageMask,
      //   {
      //     yPercent: -110,
      //   },
      //   {
      //     yPercent: 0,
      //     duration: 1,
      //     stagger: 0.1,
      //     ease: Power2.easeInOut,
      //   },
      //   0,
      // )
      // .fromTo(
      //   imageInner,
      //   {
      //     yPercent: 110,
      //     scale: 3,
      //     stagger: 0.1,
      //     filter: 'grayscale(100%) brightness(0.4)',
      //   },
      //   {
      //     yPercent: 0,
      //     filter: 'grayscale(0%) brightness(1)',
      //     scale: 1,
      //     duration: 1,
      //     stagger: 0.1,
      //     ease: Power2.easeInOut,
      //   },
      //   0,
      // )
      .add(this.button.tlShow, 0.6)
      .add(() => {
        this.isShowed = true;
      })
      .pause();
  }

  titleLineAnimation(hover: boolean): void {
    if (!this.isShowed) {
      return;
    }
    this.tlLine.reversed(!hover).resume();
  }
}
