import clamp from 'lodash-es/clamp';

import * as I from '../interfaces';
import { eventScope, getPosition } from '../utils';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function selectHandler(scrollbar: I.Scrollbar) {
  const addEvent = eventScope(scrollbar);
  const { containerEl, contentEl } = scrollbar;

  let isSelected = false;
  let animationID: number;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function scroll({ x, y }) {
    if (!x && !y) return;

    const { offset, limit } = scrollbar;
    // DISALLOW delta transformation
    scrollbar.setMomentum(
      clamp(offset.x + x, 0, limit.x) - offset.x,
      clamp(offset.y + y, 0, limit.y) - offset.y,
    );

    animationID = requestAnimationFrame(() => {
      scroll({ x, y });
    });
  }

  addEvent(window, 'mousemove', (evt: MouseEvent) => {
    if (!isSelected) return;

    cancelAnimationFrame(animationID);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const dir = calcMomentum(scrollbar, evt);

    scroll(dir);
  });

  addEvent(contentEl, 'selectstart', (evt: Event) => {
    evt.stopPropagation();
    cancelAnimationFrame(animationID);

    isSelected = true;
  });

  addEvent(window, 'mouseup blur', () => {
    cancelAnimationFrame(animationID);

    isSelected = false;
  });

  // patch for touch devices
  if (scrollbar.options.accelerate) {
    addEvent(containerEl, 'scroll', (evt: Event) => {
      evt.preventDefault();
      // eslint-disable-next-line no-multi-assign
      containerEl.scrollTop = containerEl.scrollLeft = 0;
    });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function calcMomentum(scrollbar: I.Scrollbar, evt: MouseEvent) {
  const { top, right, bottom, left } = scrollbar.bounding;
  const { x, y } = getPosition(evt);

  const res = {
    x: 0,
    y: 0,
  };

  const padding = 20;

  if (x === 0 && y === 0) return res;

  if (x > right - padding) {
    res.x = x - right + padding;
  } else if (x < left + padding) {
    res.x = x - left - padding;
  }

  if (y > bottom - padding) {
    res.y = y - bottom + padding;
  } else if (y < top + padding) {
    res.y = y - top - padding;
  }

  res.x *= 2;
  res.y *= 2;

  return res;
}
