import { Injectable } from '@angular/core';
import { Lang } from '@beta/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  private _currentLang = new BehaviorSubject<Lang>(Lang.Ru);

  get currentLang(): Lang {
    return this._currentLang.value;
  }

  set currentLang(value: Lang) {
    this._currentLang.next(value);
  }

  get currentLang$(): Observable<Lang> {
    return this._currentLang.asObservable();
  }
}
