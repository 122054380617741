import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SERVICES_FEATURE_KEY, ServicesState } from './services.reducer';

export const getServicesState =
  createFeatureSelector<ServicesState>(SERVICES_FEATURE_KEY);

export const getServices = createSelector(
  getServicesState,
  (state) => state.services,
);

export const getServicesLoading = createSelector(
  getServicesState,
  (state) => state.loading,
);

export const getServicesError = createSelector(
  getServicesState,
  (state) => state.error,
);
