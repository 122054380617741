// eslint-disable-next-line max-classes-per-file
import { AirtableImage, AirtableRecord, Lang } from '../api.common';

export class ServiceData {
  public title!: string;
}

export class Service {
  public id!: string;
  public slug!: string;
  public images!: AirtableImage[];
  public sticker!: string;
  public lang: { [key in Lang]: ServiceData } = {
    [Lang.Ru]: new ServiceData(),
    [Lang.En]: new ServiceData(),
  };

  deserialize(record: AirtableRecord): Service {
    this.id = record.id;

    if (record.fields) {
      if (record.fields['slug']) {
        this.slug = record.fields['slug'];
      }
      if (record.fields['sticker']) {
        this.sticker = record.fields['sticker'];
      }
      if (record.fields['title_ru']) {
        this.lang[Lang.Ru].title = record.fields['title_ru'];
      }
      if (record.fields['title_en']) {
        this.lang[Lang.En].title = record.fields['title_en'];
      }
      if (record.fields['images'] && record.fields['images']?.length) {
        this.images = record.fields['images'].map((image: any) =>
          new AirtableImage().deserialize(image),
        );
      }
    }
    return this;
  }
}
