import * as I from '../interfaces';
import { setStyle } from '../utils';

import { TrackDirection } from './direction';
import { ScrollbarThumb } from './thumb';

export class ScrollbarTrack implements I.ScrollbarTrack {
  readonly thumb: ScrollbarThumb;

  /**
   * Track element
   */
  readonly element = document.createElement('div');

  private _isShown = false;

  constructor(direction: TrackDirection, thumbMinSize: number = 0) {
    this.element.className = `scrollbar-track scrollbar-track-${direction}`;

    this.thumb = new ScrollbarThumb(direction, thumbMinSize);

    this.thumb.attachTo(this.element);
  }

  /**
   * Attach to scrollbar container element
   *
   * @param scrollbarContainer Scrollbar container element
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  attachTo(scrollbarContainer: HTMLElement) {
    scrollbarContainer.appendChild(this.element);
  }

  /**
   * Show track immediately
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  show() {
    if (this._isShown) {
      return;
    }

    this._isShown = true;
    this.element.classList.add('show');
  }

  /**
   * Hide track immediately
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  hide() {
    if (!this._isShown) {
      return;
    }

    this._isShown = false;
    this.element.classList.remove('show');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  update(scrollOffset: number, containerSize: number, pageSize: number) {
    setStyle(this.element, {
      display: pageSize <= containerSize ? 'none' : 'block',
    });

    this.thumb.update(scrollOffset, containerSize, pageSize);
  }
}
