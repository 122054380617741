<!--<a-->
<!--  [routerLink]="link.slug"-->
<!--  [routerLinkActive]="'navigation__link_active'"-->
<!--  [class.navigation__link_disabled]="disabled"-->
<!--  (click)="scrollTo()"-->
<!--  class="navigation__link"-->
<!--  appCursorAction-->
<!--  [cursorAction]="cursorActionType.Hide"-->
<!--  (mouseover)="linkAnimation(true)"-->
<!--  (mouseleave)="linkAnimation(false)"-->
<!--&gt;-->
<a
  [class.navigation__link_disabled]="disabled"
  (click)="scrollTo()"
  class="navigation__link"
  appCursorAction
  [cursorAction]="cursorActionType.Hide"
  (mouseover)="linkAnimation(true)"
  (mouseleave)="linkAnimation(false)"
>
  <span class="navigation__link-inner">
    <span class="navigation__link-line">
      <ng-container *ngIf="link.lineType === linkLineType.One">
        <svg
          #linkLine
          bdtSvgDrawStroke
          width="86"
          height="74"
          viewBox="0 0 86 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 64.381C16.81 50.65 32.406 18.652 52.926 11.72 78.578 3.055 40.02 33.113 41.031 36.795c1.066 3.875 18.371-7.8 25.093-1.155.44.435 14.432 12.508-34.588 22.954"
            [attr.stroke]="'#' + link.lineColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="link.lineType === linkLineType.Two">
        <svg
          #linkLine
          bdtSvgDrawStroke
          width="86"
          height="75"
          viewBox="0 0 86 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.372 40.46c11.38-45.91-8.095-19.613-16.247 5.398-2.875 6.395-1.948 14.648 24.76-3.493C73.268 19.69 69.86 27.13 69.142 29.922 64.687 45.031 74.025 42.4 79.27 39.187"
            [attr.stroke]="'#' + link.lineColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="link.lineType === linkLineType.Three">
        <svg
          #linkLine
          bdtSvgDrawStroke
          width="86"
          height="74"
          viewBox="0 0 86 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M58.745 14.513c-3.37-.484-15.886 1.146-38.999 11.54-2.46.99-4.803 2.917 5.5 2.69 10.304-.225 16.556 3.45 18.393 5.316 2.775 2.217 5.816 8.634-4.216 16.56-9.374 7.406-19.425 6.894-22.868 5.387-2.916-1.723-6.33-6.465 3.34-11.659C31.98 37.855 49.96 41.635 50.773 41.8l2.788.567c.387.079 1.535-.05 3.026-1.199 1.864-1.435-1.614-3.957-4.402 2.613-2.23 5.255 11.158 2.23 18.131.06"
            [attr.stroke]="'#' + link.lineColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="link.lineType === linkLineType.Four">
        <svg
          #linkLine
          bdtSvgDrawStroke
          width="86"
          height="74"
          viewBox="0 0 86 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.348 53.777c7.291-13.324 23.398-34.458 29.493-12.41 7.618 27.56-20.429 21.802 2.356-7.403 18.228-23.363 15.512-7.068 12.416 6.358-1.747 7.579 3.674 12.492 30.861-32.293"
            [attr.stroke]="'#' + link.lineColor"
            stroke-linecap="round"
            stroke-width="1"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="link.lineType === linkLineType.Five">
        <svg
          #linkLine
          bdtSvgDrawStroke
          width="87"
          height="74"
          viewBox="0 0 87 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.57 37.27c30.943-19.626-.791-11.629-25.376 3.408-4.372 2.675-5.615 8.374 30.188-.991C86.855 26.223 78.686 31.7 76.16 33.389c-14.33 9.11-1.138 7.346 8.263 4.974"
            [attr.stroke]="'#' + link.lineColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </svg>
      </ng-container>
    </span>
    <span class="text text_default navigation__link-title"
      ><span #title class="navigation__link-title-inner">{{ link.title }}</span
      ><span
        class="text text_caps navigation__link-counter"
        *ngIf="
          link.slug === 'vacancies' && (vacancies$ | async)?.length as counter
        "
        >({{ counter }})</span
      >
    </span>
    <span class="navigation__link-line-through"></span>
  </span>
</a>
