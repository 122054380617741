import { Service } from '@beta/api';
import { createAction, props } from '@ngrx/store';

export enum ServicesActionType {
  LOAD_SERVICES = '[Services] Load Services',
  LOAD_SERVICES_SUCCESS = '[Services] Load Services Success',
  LOAD_SERVICES_FAILURE = '[Services] Load Services Failure',
}

export const loadServices = createAction(ServicesActionType.LOAD_SERVICES);

export const loadServicesSuccess = createAction(
  ServicesActionType.LOAD_SERVICES_SUCCESS,
  props<{
    services: Service[];
  }>(),
);

export const loadServicesFailure = createAction(
  ServicesActionType.LOAD_SERVICES_FAILURE,
  props<{ error: string }>(),
);
