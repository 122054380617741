import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { LogoColor, LogoNavigation, LogoType } from './logo.common';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  private _logoPosition = new BehaviorSubject<number>(0);
  private _logoScale = new BehaviorSubject<number>(1);
  private _logoType = new BehaviorSubject<LogoType>(LogoType.Default);
  private _logoColor = new BehaviorSubject<LogoColor>(LogoColor.Light);
  private _logoReset = new Subject<boolean>();
  private _logoLine = new BehaviorSubject<boolean>(true);
  private _logoNavigation = new BehaviorSubject<LogoNavigation>(
    LogoNavigation.Top,
  );

  private _logoEl = new BehaviorSubject<HTMLElement | undefined>(undefined);

  get logoEl(): HTMLElement | undefined {
    return this._logoEl.value;
  }

  set logoEl(value: HTMLElement | undefined) {
    this._logoEl.next(value);
  }

  get logoScale(): number {
    return this._logoScale.value;
  }

  set logoScale(value: number) {
    this._logoScale.next(value);
  }

  get logoScale$(): Observable<number> {
    return this._logoScale.asObservable();
  }

  get logoPosition(): number {
    return this._logoPosition.value;
  }

  set logoPosition(value: number) {
    this._logoPosition.next(value);
  }

  get logoPosition$(): Observable<number> {
    return this._logoPosition.asObservable();
  }

  get logoType(): LogoType {
    return this._logoType.value;
  }

  set logoType(value: LogoType) {
    this._logoType.next(value);
  }

  get logoType$(): Observable<LogoType> {
    return this._logoType.asObservable();
  }

  get logoColor(): LogoColor {
    return this._logoColor.value;
  }

  set logoColor(value: LogoColor) {
    this._logoColor.next(value);
  }

  get logoColor$(): Observable<LogoColor> {
    return this._logoColor.asObservable();
  }

  set logoReset(value: boolean) {
    this._logoReset.next(value);
  }

  get logoReset$(): Observable<boolean> {
    return this._logoReset.asObservable();
  }

  get logoLine(): boolean {
    return this._logoLine.value;
  }

  set logoLine(value: boolean) {
    this._logoLine.next(value);
  }

  get logoLine$(): Observable<boolean> {
    return this._logoLine.asObservable();
  }

  get logoNavigation(): LogoNavigation {
    return this._logoNavigation.value;
  }

  set logoNavigation(value: LogoNavigation) {
    this._logoNavigation.next(value);
  }

  get logoNavigation$(): Observable<LogoNavigation> {
    return this._logoNavigation.asObservable();
  }
}
