import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { loadProjects } from './projects.actions';
import { ProjectsPartialState } from './projects.reducer';
import {
  getProjects,
  getProjectsError,
  getProjectsLoading,
  getProjectsState,
} from './projects.selectors';

@Injectable()
export class ProjectsFacade {
  projectsState$ = this.store.select(getProjectsState);
  projects$ = this.store.select(getProjects);
  projectsLoading$ = this.store.select(getProjectsLoading);
  projectsError$ = this.store.select(getProjectsError);

  constructor(private store: Store<ProjectsPartialState>) {}

  getProjects(): void {
    this.store.dispatch(loadProjects());
  }
}
