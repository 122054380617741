import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  LogoNavigation,
  LogoService,
  PreloaderService,
  ResizeService,
  SmoothScroll,
  SmoothScrollService,
  TransitionService,
} from '@beta/core';
import { getRelativeValue } from '@beta/shared';
import { SvgDrawStrokeDirective } from '@ng-blue-duct-tape/svg-draw-stroke';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap, Power2 } from 'gsap';
import { combineLatest, NEVER, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements AfterViewInit {
  @ViewChild('logo') logo!: ElementRef<HTMLElement>;
  @ViewChild('logoPartWrap') logoPartWrap!: ElementRef<HTMLElement>;
  @ViewChildren('logoInner') logoInner = new QueryList<
    ElementRef<HTMLElement>
  >();

  @ViewChild('line', { read: SvgDrawStrokeDirective })
  line!: SvgDrawStrokeDirective;

  public logoType$ = this.logoService.logoType$;
  public logoColor$ = this.logoService.logoColor$;
  public logoLine$ = this.logoService.logoLine$;

  isShowed = false;
  tlShow = gsap.timeline();
  tlLine = gsap.timeline();

  constructor(
    @Inject(WINDOW) private window: Window,
    private smoothScrollService: SmoothScrollService,
    private logoService: LogoService,
    private preloaderService: PreloaderService,
    private resizeService: ResizeService,
    private transitionService: TransitionService,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.logoService.logoEl = this.logo.nativeElement;
    this.initLineAnimation();
    this.initShowAnimation();
    this.logoAnimation();
    this.setLineState();

    this.logoService.logoReset$
      .pipe(
        filter((reset) => reset),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.tlShow.restart().pause();
      });

    combineLatest([
      this.preloaderService.preloaderIsHidden$,
      this.transitionService.transitionOpenEnd$,
    ])
      .pipe(
        filter(([preloader, transition]) => preloader && transition),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.tlShow.resume();
      });
  }

  initShowAnimation(): void {
    this.tlShow
      .clear()
      .fromTo(
        this.logoInner.map((item) => item.nativeElement),
        {
          yPercent: 110,
        },
        {
          yPercent: 0,
          stagger: 0.3,
          duration: 0.9,
          ease: Power2.easeOut,
        },
      )
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: 1,
          ease: Power2.easeInOut,
        },
        0.5,
      )
      .add(() => {
        this.isShowed = true;
      })
      .pause();
  }

  initLineAnimation(): void {
    this.tlLine
      .clear()
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: 0,
        },
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
          duration: 1,
          ease: Power2.easeInOut,
        },
      )
      .pause();
  }

  lineAnimation(hover: boolean): void {
    if (!this.isShowed || this.logoService.logoScale < 1) {
      return;
    }
    this.tlLine.reversed(!hover).resume();
  }

  logoAnimation(): void {
    this.logoService.logoPosition$
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((position) => {
        gsap.set(this.logoPartWrap.nativeElement, {
          yPercent: position,
        });
      });

    this.smoothScrollService.smoothScroll$
      .pipe(
        filter((value): value is SmoothScroll => !!value),
        switchMap((value) => {
          if (this.resizeService.isMobile) {
            gsap.set(this.logo.nativeElement, {
              scale: 1,
              y: 0,
            });
            return NEVER;
          }
          return of(value);
        }),
        untilDestroyed(this),
      )
      .subscribe((value) => {
        gsap.set(this.logo.nativeElement, {
          scale: getRelativeValue(
            0,
            this.window.innerHeight,
            1,
            0.2012987013,
            value.offsetY,
          ),
          // y: value.offsetY,
          force3D: true,
        });

        this.logoService.logoScale = Number(
          gsap.getProperty(this.logo.nativeElement, 'scale'),
        );
      });
  }

  setLineState(): void {
    this.logoService.logoScale$
      .pipe(
        filter(() => this.isShowed),
        map((val) => val >= 1),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this.tlLine.reversed(state).resume();
      });
  }

  navigate(): void {
    if (!this.isShowed) {
      return;
    }
    if (this.logoService.logoNavigation === LogoNavigation.Top) {
      if (
        this.smoothScrollService.scrollbar &&
        this.smoothScrollService.scrollSpeed === 0 &&
        this.logoService.logoScale < 1
      ) {
        this.smoothScrollService.scrollbar.scrollTo(0, 0, 1500);
      }
    }
    if (this.logoService.logoNavigation === LogoNavigation.Home) {
      this.router.navigate(['/']);
    }
  }
}
