export enum ButtonIconSize {
  S = 's',
  M = 'm',
  L = 'l',
}

export enum ButtonIconColor {
  Black = '#000000',
  White = '#ffffff',
  DarkGray = '#232323',
  DarkRed = '#d30203',
  Red = '#eb4626',
  Orange = '#f67f1b',
  Yellow = '#ffe600',
  Blue = '#31b5ff',
  Purple = '#854dff',
  Green = '#009e60',
  Pink = '#ff87c8',
}
