import { Meta } from '@beta/api';
import { createAction, props } from '@ngrx/store';

export enum MetaActionType {
  LOAD_META = '[Meta] Load Meta',
  LOAD_META_SUCCESS = '[Meta] Load Meta Success',
  LOAD_META_FAILURE = '[Meta] Load Meta Failure',
}

export const loadMeta = createAction(MetaActionType.LOAD_META);

export const loadMetaSuccess = createAction(
  MetaActionType.LOAD_META_SUCCESS,
  props<{
    meta: Meta[];
  }>(),
);

export const loadMetaFailure = createAction(
  MetaActionType.LOAD_META_FAILURE,
  props<{ error: string }>(),
);
