<div
  class="button"
  [class.button_reversed]="reverseColor"
  (mousemove)="buttonAnimation(true, $event)"
  (mouseleave)="buttonAnimation(false)"
  #button
  appCursorAction
>
  <ng-container *ngIf="!url">
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </ng-container>

  <ng-container *ngIf="url">
    <a [href]="url">
      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
    </a>
  </ng-container>
</div>

<ng-template #buttonTemplate>
  <div class="button__line button__back-line">
    <ng-container *ngIf="lineType === buttonLineType.One">
      <svg
        bdtSvgDrawStroke
        #buttonBackLine
        width="340"
        height="226"
        viewBox="0 0 340 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          [attr.stroke]="'#' + lineColor"
          d="M231.619 17.74c-24.948 4.955-71.511 20.376-141.363 58.075-9.96 4.879-19.145 13.613 23.8 9.515 42.945-4.097 70.188 9.281 78.441 16.483 12.273 8.378 26.963 34.15-12.453 70.209-29.05 26.576-61.392 32.779-81.044 31.654"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-container>

    <ng-container *ngIf="lineType === buttonLineType.Two">
      <svg
        bdtSvgDrawStroke
        #buttonBackLine
        width="340"
        height="226"
        viewBox="0 0 340 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          [attr.stroke]="'#' + lineColor"
          d="M101.561 197.995c208.57-7.588 160.392-70.281 158.899-72.382-22.789-32.086-97.544-1.983-103.749-13.311C154.908 99.76 334.96.931 223.553 18.009"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-container>

    <ng-container *ngIf="lineType === buttonLineType.Three">
      <svg
        bdtSvgDrawStroke
        #buttonBackLine
        width="340"
        height="226"
        viewBox="0 0 340 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          [attr.stroke]="'#' + lineColor"
          d="M138.48 88.036c41.36-77.872-53.271-36.73-111.025 47.624-5.606 8.187-10.342 24.652-2.044 33.659"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-container>
  </div>
  <div class="button__inner" #buttonInner>
    <span class="button__noise">
      <app-noise></app-noise>
    </span>
    <span class="text text_default-spec button__text">{{ buttonTitle }}</span>
    <span class="button__icon"></span>
  </div>

  <div class="button__line button__front-line">
    <ng-container *ngIf="lineType === buttonLineType.One">
      <svg
        bdtSvgDrawStroke
        #buttonFrontLine
        width="340"
        height="226"
        viewBox="0 0 340 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          [attr.stroke]="'#' + lineColor"
          d="M102.316 203.792c-6.763-.093-12.298-1.021-16.07-2.346C70.5 195.912 58 175.5 96.562 151.905c48.929-29.936 124.679-20.501 128.125-20.062l11.814 1.506s7.498.651 12.26-5.915c4.762-6.567-7.969-15.974-17.566 12.219-7.772 22.832 47.204 5.885 75.658-5.281"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-container>

    <ng-container *ngIf="lineType === buttonLineType.Two">
      <svg
        bdtSvgDrawStroke
        #buttonFrontLine
        width="340"
        height="226"
        viewBox="0 0 340 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          [attr.stroke]="'#' + lineColor"
          d="M223.539 18.012c-26.601 4.08-53.007 17.626-77.537 36.07C88.328 97.446 41.034 167.883 26.01 205.986"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-container>

    <ng-container *ngIf="lineType === buttonLineType.Three">
      <svg
        bdtSvgDrawStroke
        #buttonFrontLine
        width="340"
        height="226"
        viewBox="0 0 340 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          [attr.stroke]="'#' + lineColor"
          d="M24.53 168.282c.26.333.534.657.825.973 10.3 11.157 40.658 10.804 114.289-31.115 185.417-105.562 149.98-43.179 138.548-28.709-42.409 53.678 12.651 53.69 39.184 35.302"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-container>
  </div>

  <div class="button__content" #buttonContent>
    <span
      class="text text_default-spec button__text"
      #buttonText
      appSplitText
      >{{ buttonTitle }}</span
    >
    <span
      class="button__icon"
      #buttonIcon
      app-icon
      [iconName]="iconName"
      [iconSize]="iconSize"
    ></span>
  </div>
</ng-template>
