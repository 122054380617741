import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { SmoothScrollService } from '@beta/core';
import { SplitTextDirective } from '@beta/shared';
import {
  ButtonIconColor,
  ButtonIconComponent,
  ButtonIconSize,
  IconName,
  IconSize,
  LinkComponent,
} from '@beta/ui-common';
import { Back, gsap, Power2 } from 'gsap';

@Component({
  selector: 'app-header-footer, [app-header-footer]',
  templateUrl: './header-footer.component.html',
  styleUrls: ['./header-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderFooterComponent implements AfterViewInit {
  @Input() parent!: HTMLElement;
  @Input() description!: string;
  @Input() iconName!: IconName;
  @ViewChild('link', {
    read: LinkComponent,
  })
  link!: LinkComponent;

  @ViewChild('button', {
    read: ButtonIconComponent,
  })
  button!: ButtonIconComponent;

  @ViewChild('icon', { read: ElementRef }) icon!: ElementRef<HTMLElement>;

  @ViewChild('descriptionEl', { read: SplitTextDirective })
  descriptionEl!: SplitTextDirective;

  iconSize = IconSize;
  buttonIconColor = ButtonIconColor;
  buttonIconSize = ButtonIconSize;
  iconNameType = IconName;

  tlShow = gsap.timeline();
  isShowed = false;
  constructor(private smoothScrollService: SmoothScrollService) {}

  ngAfterViewInit(): void {
    this.initShowAnimation();
  }

  scrollBottom(): void {
    if (this.smoothScrollService.scrollbar) {
      this.smoothScrollService.scrollbar.scrollTo(
        0,
        this.parent.offsetHeight,
        1800,
      );
    }
  }

  initShowAnimation(): void {
    const description = this.descriptionEl.wordEls;
    this.tlShow
      .fromTo(
        description,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        0,
      )
      .fromTo(
        this.icon.nativeElement,
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 0.5,
          ease: Back.easeOut,
        },
        0,
      )
      .add(this.link.tlShow, 0)
      .add(this.button.tlShow, 0)
      .add(() => {
        this.isShowed = true;
      });
  }
}
