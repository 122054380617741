import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HeaderImagesEffects } from './header-images.effects';
import { HeaderImagesFacade } from './header-images.facade';
import {
  HEADER_IMAGES_FEATURE_KEY,
  initialHeaderImagesState,
  reducer,
} from './header-images.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(HEADER_IMAGES_FEATURE_KEY, reducer, {
      initialState: initialHeaderImagesState,
    }),
    EffectsModule.forFeature([HeaderImagesEffects]),
  ],
  providers: [HeaderImagesFacade],
})
export class HeaderImagesModule {}
