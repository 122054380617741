<div
  class="button"
  appCursorAction
  #button
  [ngClass]="['button_' + buttonSize]"
  (mousemove)="buttonAnimation(true, $event)"
  (mouseleave)="buttonAnimation(false)"
>
  <div
    class="button__inner"
    #buttonInner
    [style.background-color]="buttonColor"
    [style.border-color]="borderColor"
  ></div>
  <div
    class="button__icon"
    #buttonIcon
    app-icon
    [iconSize]="iconSize"
    [iconName]="iconName"
  ></div>
</div>
