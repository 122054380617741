import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { VacanciesFacade } from '@beta/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, mapTo, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class VacanciesResolver implements Resolve<boolean> {
  constructor(private vacanciesFacade: VacanciesFacade) {}

  resolve(): Observable<boolean> {
    return this.vacanciesFacade.vacanciesState$.pipe(
      tap((vacanciesState) => {
        if (
          !vacanciesState.vacancies &&
          !vacanciesState.loading &&
          !vacanciesState.error
        ) {
          this.vacanciesFacade.getVacancies();
        }
      }),
      filter(
        (vacanciesState) =>
          !vacanciesState.loading &&
          !vacanciesState.error &&
          !!vacanciesState.vacancies,
      ),
      take(1),
      mapTo(true),
      untilDestroyed(this),
    );
  }
}
