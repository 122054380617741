import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SocialWidgetComponent } from './social-widget.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SocialWidgetComponent],
  exports: [SocialWidgetComponent],
})
export class SocialWidgetModule {}
