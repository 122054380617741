<div class="ticker-item" #item appCursorAction>
  <h2 class="spec ticker-item__text">Мечта</h2>
  <div class="ticker-item__line">
    <svg
      #line
      bdtSvgDrawStroke
      width="588"
      height="154"
      viewBox="0 0 588 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M207.145 62.072c251.98-78.257 14.13-75.803-185.13-17.198-35.441 10.423-53.89 45.063 215.043 40.1 386.629-7.133 319.607 15.424 298.892 22.364-116.783 36.396-20.426 45.37 50.05 44.619"
        stroke="#FFE600"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <span class="mini-tags mini-tags_tag ticker-item__badge" #badge>Большая</span>
  <div class="ticker-item__logo" #logo></div>
</div>
<div class="ticker-item__separator">
  <div
    class="ticker-item__separator-icon"
    #icon
    app-icon
    [iconName]="iconName.Star"
    [iconSize]="iconSize.L"
  ></div>
</div>
