import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { NavigationColor } from './navigation.common';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _eventIsVisible = new BehaviorSubject<boolean>(false);
  private _eventIsEnabled = new BehaviorSubject<boolean>(false);
  private _navigationColor = new BehaviorSubject<NavigationColor>(
    NavigationColor.Light,
  );

  private _navigationEl = new BehaviorSubject<HTMLElement | undefined>(
    undefined,
  );

  get navigationEl(): HTMLElement | undefined {
    return this._navigationEl.value;
  }

  set navigationEl(value: HTMLElement | undefined) {
    this._navigationEl.next(value);
  }

  get navigationColor(): NavigationColor {
    return this._navigationColor.value;
  }

  set navigationColor(value: NavigationColor) {
    this._navigationColor.next(value);
  }

  get navigationColor$(): Observable<NavigationColor> {
    return this._navigationColor.asObservable();
  }

  get eventIsVisible(): boolean {
    return this._eventIsVisible.value;
  }

  set eventIsVisible(value: boolean) {
    this._eventIsVisible.next(value);
  }

  get eventIsVisible$(): Observable<boolean> {
    return this._eventIsVisible.asObservable();
  }

  get eventIsEnabled(): boolean {
    return this._eventIsEnabled.value;
  }

  set eventIsEnabled(value: boolean) {
    this._eventIsEnabled.next(value);
  }

  get eventIsEnabled$(): Observable<boolean> {
    return this._eventIsEnabled.asObservable();
  }
}
