import { Injectable } from '@angular/core';
import {
  HeaderImagesFacade,
  MetaFacade,
  ProjectsFacade,
  ServicesFacade,
  VacanciesFacade,
} from '@beta/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LoadingProgress } from './loading-progress.common';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LoadingProgressService {
  private _loadingProgress = new BehaviorSubject<LoadingProgress>({
    chunks: [],
    percent: 0,
    loaded: false,
  });

  constructor(
    private projectsFacade: ProjectsFacade,
    private vacanciesFacade: VacanciesFacade,
    private servicesFacade: ServicesFacade,
    private metaFacade: MetaFacade,
    private headerImagesFacade: HeaderImagesFacade,
  ) {
    this.getLoadingProgress();
  }

  get loadingProgress(): LoadingProgress {
    return this._loadingProgress.value;
  }

  set loadingProgress(value: LoadingProgress) {
    this._loadingProgress.next(value);
  }

  get loadingProgress$(): Observable<LoadingProgress> {
    return this._loadingProgress.asObservable();
  }

  getLoadingProgress(): void {
    combineLatest([
      this.metaFacade.metaState$.pipe(
        filter((metaState) => !metaState.loading),
        map((metaState) => !!metaState.meta?.length),
      ),
      this.headerImagesFacade.headerImagesState$.pipe(
        filter((headerImagesState) => !headerImagesState.loading),
        map((headerImagesState) => !!headerImagesState.images?.length),
      ),
      this.projectsFacade.projectsState$.pipe(
        filter((projectsState) => !projectsState.loading),
        map((projectsState) => !!projectsState.projects?.length),
      ),
      this.vacanciesFacade.vacanciesState$.pipe(
        filter((vacanciesState) => !vacanciesState.loading),
        map((vacanciesState) => !!vacanciesState.vacancies?.length),
      ),
      this.servicesFacade.servicesState$.pipe(
        filter((servicesState) => !servicesState.loading),
        map((servicesState) => !!servicesState.services?.length),
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe((chunks) => {
        this.loadingProgress = {
          chunks,
          percent:
            (chunks.filter((chunk) => chunk).length / chunks.length) * 100,
          loaded: !chunks.includes(false),
        };
      });
  }
}
