<div class="projects" #projectsWrap>
  <div class="container">
    <div class="projects__inner">
      <div class="projects__header">
        <div class="cell"></div>
        <div class="cell cell_space-5">
          <h2
            class="projects__title"
            (mouseover)="titleLineAnimation(true)"
            (mouseleave)="titleLineAnimation(false)"
          >
            <span class="projects__title-line">
              <svg
                bdtSvgDrawStroke
                #line
                width="259"
                height="320"
                viewBox="0 0 259 320"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M85.985 273.256c239.779-96.01 158.172-145.127 155.557-146.954-39.941-27.89-117.606 46.603-126.407 27.837-8.36-17.828 161.151-204.37 38.476-137.871C55.481 69.463 4.269 245.728 2 318"
                  stroke="#EB4626"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span
              class="projects__title-inner"
              #title
              appSplitText
              [maskPadding]="2.3"
              [maskPaddingUnits]="'rem'"
              >Проекты</span
            >
            <!--            TODO: Вернуть каунтер-->
            <span
              class="subtitle subtitle_large projects__title-counter"
              #counter
              appSplitText
              >{{ projects.length || '' }}</span
            >
          </h2>
        </div>
        <div class="cell cell_space-2 projects__description-cell">
          <p class="text text_caps projects__description">
            <span class="projects__description-inner" #description appSplitText>
              Которыми мы <br />
              действительно <br />
              гордимся
            </span>
            <span
              class="projects__description-icon"
              #icon
              app-icon
              [iconName]="iconName.Like"
              [iconSize]="iconSize.M"
            ></span>
          </p>
        </div>
      </div>
      <div class="projects__content">
        <div class="projects__content-header">
          <div class="cell cell_space-3"></div>
          <div class="cell">
            <span class="text text_caps" #subtitle appSplitText>Подборка</span>
          </div>
          <div class="cell">
            <span class="text text_caps" #subtitle appSplitText>За 10 лет</span>
            <span
              class="text text_caps projects__content-date-mobile"
              #subtitle
              appSplitText
              >2012 - 2022</span
            >
          </div>
          <div class="cell">
            <span class="text text_caps" #subtitle appSplitText
              >2012 - 2022</span
            >
          </div>
          <div class="cell"></div>
          <div class="cell cell_reverse">
            <span class="text text_default" #subtitle appSplitText
              >Это бета</span
            >
          </div>
        </div>
        <div class="projects-list__wrap">
          <ul class="list projects-list">
            <li
              class="projects-list__item"
              app-projects-item
              #item
              *ngFor="let project of projects; let i = index; let last = last"
              [index]="i"
              [active]="i === currentProjectIndex"
              [activeNext]="
                currentProjectIndex !== undefined &&
                i === currentProjectIndex + 1
              "
              [last]="last"
              [project]="project"
              [currentIndex]="currentProjectIndex"
              [activeItemOnFastMove]="activeItemOnFastMove$"
              (selectedIndex)="hoverItem($event)"
            ></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
