import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CursorActionModule, SplitTextModule } from '@beta/shared';
import { ButtonModule, IconModule } from '@beta/ui-common';
import { SvgDrawStrokeModule } from '@ng-blue-duct-tape/svg-draw-stroke';

import { ProjectsComponent } from './projects.component';
import { ProjectsItemComponent } from './projects-item';

@NgModule({
  declarations: [ProjectsComponent, ProjectsItemComponent],
  imports: [
    CommonModule,
    SplitTextModule,
    IconModule,
    CursorActionModule,
    SvgDrawStrokeModule,
    ButtonModule,
  ],
  exports: [ProjectsComponent, ProjectsItemComponent],
})
export class ProjectsModule {}
