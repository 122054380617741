import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ResizeService } from '@beta/core';
import { IconName, IconSize } from '@beta/ui-common';
import { SvgDrawStrokeDirective } from '@ng-blue-duct-tape/svg-draw-stroke';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Back, gsap, Power2 } from 'gsap';
import { distinctUntilChanged, skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-ticker-item, [app-ticker-item]',
  templateUrl: './ticker-item.component.html',
  styleUrls: ['./ticker-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TickerItemComponent implements AfterViewInit, OnChanges {
  @Input() active = false;
  @ViewChild('item') item!: ElementRef<HTMLElement>;
  @ViewChild('badge') badge!: ElementRef<HTMLElement>;
  @ViewChild('logo') logo!: ElementRef<HTMLElement>;
  @ViewChild('icon', { read: ElementRef }) icon!: ElementRef<HTMLElement>;
  @ViewChild('line', { read: SvgDrawStrokeDirective })
  line!: SvgDrawStrokeDirective;

  iconName = IconName;
  iconSize = IconSize;

  tlItem = gsap.timeline();
  isShowed = true;

  constructor(private resizeService: ResizeService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resizeService.isMobile) {
      return;
    }
    this.tlItem.reversed(!changes?.active.currentValue).resume();
  }

  ngAfterViewInit(): void {
    this.initItemAnimation();
    this.onResize();

    if (this.resizeService.isMobile) {
      this.tlItem.reversed(false).resume();
    }
  }

  initItemAnimation(): void {
    this.tlItem
      .clear()
      .fromTo(
        this.badge.nativeElement,
        {
          yPercent: 100,
          scale: 0,
          rotation: 90,
        },
        {
          yPercent: 0,
          scale: 1,
          rotation: -8,
          duration: 0.5,
          ease: Back.easeOut,
        },
        0,
      )
      .fromTo(
        this.logo.nativeElement,
        {
          yPercent: 100,
          scale: 0,
          rotation: 90,
        },
        {
          yPercent: 0,
          scale: 1,
          rotation: -10,
          duration: 0.5,
          ease: Back.easeOut,
        },
        0.3,
      )
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: 1,
          ease: Power2.easeInOut,
        },
        0,
      )
      .pause();
  }

  onResize(): void {
    this.resizeService.currentResolution$
      .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        if (this.resizeService.isMobile) {
          this.tlItem.reversed(false).resume();
        } else {
          this.tlItem.reversed(false).progress(0).pause();
        }
      });
  }
}
