import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CursorActionModule, SplitTextModule } from '@beta/shared';
import { ButtonModule, IconModule } from '@beta/ui-common';
import { SvgDrawStrokeModule } from '@ng-blue-duct-tape/svg-draw-stroke';

import { ServicesComponent } from './services.component';
import { ServicesItemComponent } from './services-item';

@NgModule({
  declarations: [ServicesComponent, ServicesItemComponent],
  imports: [
    CommonModule,
    CursorActionModule,
    IconModule,
    ButtonModule,
    SplitTextModule,
    SvgDrawStrokeModule,
  ],
  exports: [ServicesComponent, ServicesItemComponent],
})
export class ServicesModule {}
