// eslint-disable-next-line max-classes-per-file
import { AirtableImage, AirtableRecord, Lang } from '../api.common';

export class MetaData {
  public title!: string;
  public description!: string;
  public keywords!: string;
}

export class Meta {
  public id!: string;
  public page!: string;
  public shareImage!: AirtableImage;
  public url!: string;
  public lang: { [key in Lang]: MetaData } = {
    [Lang.Ru]: new MetaData(),
    [Lang.En]: new MetaData(),
  };

  deserialize(record: AirtableRecord): Meta {
    this.id = record.id;

    if (record.fields) {
      if (record.fields['page']) {
        this.page = record.fields['page'];
      }
      if (record.fields['title_ru']) {
        this.lang[Lang.Ru].title = record.fields['title_ru'];
      }
      if (record.fields['title_en']) {
        this.lang[Lang.En].title = record.fields['title_en'];
      }
      if (record.fields['description_ru']) {
        this.lang[Lang.Ru].description = record.fields['description_ru'];
      }
      if (record.fields['description_en']) {
        this.lang[Lang.En].description = record.fields['description_en'];
      }
      if (record.fields['keywords_ru']) {
        this.lang[Lang.Ru].keywords = record.fields['keywords_ru'];
      }
      if (record.fields['keywords_en']) {
        this.lang[Lang.En].keywords = record.fields['keywords_en'];
      }
      if (record.fields['url']) {
        this.url = record.fields['url'];
      }
      if (record.fields['shareImage'] && record.fields['shareImage']?.length) {
        this.shareImage = new AirtableImage().deserialize(
          record.fields['shareImage'][0],
        );
      }
    }
    return this;
  }
}
