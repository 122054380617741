import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount } from 'rxjs/operators';

import { airtableBaseToken, AirtableResponse } from '../api.common';

import { HeaderImage } from './header-images.common';

@Injectable({
  providedIn: 'root',
})
export class HeaderImagesService {
  constructor(private http: HttpClient) {}

  get(): Observable<HeaderImage[]> {
    return this.http
      .get<AirtableResponse>(
        `v0/${airtableBaseToken}/Header_Images`,
        // {
        //   headers: {
        //     Authorization: `Bearer ${airtableAuthToken}`,
        //   },
        //   params: {
        //     view: 'Grid view',
        //   },
        // },
      )
      .pipe(
        map((response) =>
          response.records.map((record) =>
            new HeaderImage().deserialize(record),
          ),
        ),
        publishLast(),
        refCount(),
      );
  }
}
