<a
  #item
  (mouseover)="selectItem()"
  class="projects-item"
  [href]="project.url || 'javascript:void(0)'"
  [target]="project.url ? '_blank' : '_self'"
  rel="noopener noreferrer"
  [class.projects-item_disabled]="!project.url"
  [class.projects-item_active]="active"
  [class.projects-item_active-next]="activeNext"
  [class.projects-item_last]="last"
  *ngIf="currentLang$ | async as lang"
>
  <div class="cell"></div>
  <div class="cell cell_space-2 projects-item__image-cell">
    <div class="projects-item__image" appCursorAction #image>
      <div
        class="projects-item__image-inner"
        [style.background-image]="'url(' + project?.preview?.src || '' + ')'"
        #imageInner
      ></div>
    </div>
  </div>
  <div class="cell cell_space-5">
    <div class="projects-item__content">
      <div class="projects-item__border projects-item__border_top" #border>
        <div class="projects-item__border-inner"></div>
      </div>
      <span class="title projects-item__title" #titleWrap>
        <span class="text text_caps projects-item__counter"
          >{{ (index + 1 + '').padStart(2, '0') }} /</span
        >
        <span class="projects-item__title-inner">{{
          project.lang[lang].title
        }}</span>
        <span class="projects-item__title-icon" #titleIcon></span>
      </span>
      <div class="projects-item__info" #info>
        <div class="cell cell_space-2 projects-item__info-cell">
          <span class="text text_caps projects-item__award">{{
            project.lang[lang].awards || '&nbsp;'
          }}</span>
        </div>
        <div class="cell cell_space-2 projects-item__info-cell">
          <p
            class="text text_default projects-item__description"
            [innerHTML]="project.lang[lang].descriptionHtml"
          ></p>
        </div>
        <div class="cell"></div>
      </div>
      <div
        class="projects-item__icon"
        #icon
        app-icon
        [iconName]="iconName.ArrowRight"
        [iconSize]="iconSize.XL"
      ></div>
      <div
        class="projects-item__icon-mobile"
        #iconMobile
        app-icon
        [iconName]="iconName.ArrowRight"
        [iconSize]="iconSize.XL"
      ></div>
      <div
        class="projects-item__border projects-item__border_bottom"
        #border
        *ngIf="last"
      >
        <div class="projects-item__border-inner"></div>
      </div>
    </div>
  </div>
</a>
<div class="projects-item__footer" #itemFooter *ngIf="last">
  <div class="cell cell_space-3"></div>
  <div class="cell cell_space-5 projects-item__footer-cell">
    <app-button
      #button
      [buttonTitle]="'Связаться с нами'"
      [url]="'mailto:hello@betaagency.ru'"
      [iconSize]="iconSize.S"
      [iconName]="iconName.ButtonArrowRight"
      [lineColor]="buttonLineColor.Red"
      [lineType]="buttonLineType.Two"
    ></app-button>
    <!--    <span-->
    <!--      class="text text_description projects-item__footer-date-range"-->
    <!--      appSplitText-->
    <!--      #dateRange-->
    <!--      >2012 - 2021</span-->
    <!--    >-->
    <!--    TODO: вернуть год-->
    <span
      class="text text_default projects-item__footer-date-range"
      appSplitText
      #dateRange
      >больше проектов - скоро</span
    >
  </div>
</div>
