import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ServicesEffects } from './services.effects';
import { ServicesFacade } from './services.facade';
import {
  initialServicesState,
  reducer,
  SERVICES_FEATURE_KEY,
} from './services.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(SERVICES_FEATURE_KEY, reducer, {
      initialState: initialServicesState,
    }),
    EffectsModule.forFeature([ServicesEffects]),
  ],
  providers: [ServicesFacade],
})
export class ServicesModule {}
