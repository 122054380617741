import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CursorComponent } from './cursor.component';

@NgModule({
  declarations: [CursorComponent],
  imports: [CommonModule],
  exports: [CursorComponent],
})
export class CursorModule {}
