import { Injectable } from '@angular/core';
import { HeaderImagesService } from '@beta/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  HeaderImagesActionType,
  loadHeaderImagesFailure,
  loadHeaderImagesSuccess,
} from './header-images.actions';

@UntilDestroy()
@Injectable()
export class HeaderImagesEffects {
  loadHeaderImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderImagesActionType.LOAD_HEADER_IMAGES),
      switchMap(() =>
        this.headerImagesService.get().pipe(
          // tap((headerImages) => {
          //   console.log(headerImages, 'headerImages');
          // }),
          map((headerImages) =>
            loadHeaderImagesSuccess({ images: headerImages }),
          ),
          catchError((error) =>
            of(
              loadHeaderImagesFailure({
                error: error.message || 'Server Error',
              }),
            ),
          ),
        ),
      ),
      untilDestroyed(this),
    ),
  );

  constructor(
    private actions$: Actions,
    private headerImagesService: HeaderImagesService,
  ) {}
}
