import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

import { SplitAction, tagTypes, textTypes } from './split-text.common';

@Directive({
  selector: '[appSplitText], appSplitText',
})
export class SplitTextDirective implements AfterViewInit {
  @Input() deferInit = false;
  @Input() maskPadding: number | undefined;
  @Input() maskPaddingUnits: 'px' | 'em' | 'rem' | '%' | undefined;

  private allEls: HTMLElement[] = [];
  private srcText = '';

  public charsEls: HTMLElement[] = [];
  public wordEls: HTMLElement[] = [];
  public wordsLine: HTMLElement[][] = [];
  public nativeElement!: HTMLElement;

  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.nativeElement = this.el.nativeElement;
    if (!this.deferInit) {
      this.init();
    }
  }

  public init(): void {
    this.saveSrcText();
    this.splitNodes(this.el.nativeElement.childNodes, SplitAction.Words);
    this.el.nativeElement.innerHTML = '';

    this.allEls.forEach((el, index) => {
      this.el.nativeElement.appendChild(el);
      if (index !== this.allEls.length - 1) {
        const spaceEl = this.renderer.createElement('span');
        this.renderer.addClass(spaceEl, 'space');
        this.renderer.setStyle(spaceEl, 'display', 'inline');
        this.renderer.setStyle(spaceEl, 'text-transform', 'inherit');
        this.renderer.setStyle(spaceEl, 'text-indent', 0);
        this.renderer.setProperty(spaceEl, 'innerHTML', ' ');
        this.renderer.appendChild(this.el.nativeElement, spaceEl);
      }
    });

    this.splitIntoLine();
  }

  private saveSrcText(): void {
    this.srcText = this.el.nativeElement.innerHTML;
  }

  splitNodes(
    childNodes: NodeListOf<ChildNode>,
    splitAction: SplitAction,
    mask = true,
  ): void {
    for (let i = 0; i < childNodes.length; i += 1) {
      const node = childNodes[i];
      const isTag = tagTypes.includes(node.nodeType);
      const isText = textTypes.includes(node.nodeType);

      if (isText) {
        if (node.textContent) {
          const wordsEls: HTMLElement[] = [];
          node.textContent
            .trim()
            .split(' ')
            .forEach((word) => {
              const charsEls: HTMLElement[] = [];
              const chars = word.split('');

              const wordEl = this.renderer.createElement('span');
              this.renderer.addClass(wordEl, 'word');
              this.renderer.setStyle(wordEl, 'display', 'inline-block');
              this.renderer.setStyle(wordEl, 'text-transform', 'inherit');
              this.renderer.setStyle(wordEl, 'text-indent', 0);

              if (splitAction === SplitAction.Chars) {
                chars.forEach((char) => {
                  const charEl = this.renderer.createElement('span');
                  this.renderer.addClass(charEl, 'char');
                  this.renderer.setStyle(charEl, 'display', 'inline-block');
                  this.renderer.setStyle(charEl, 'text-transform', 'inherit');
                  this.renderer.setStyle(charEl, 'text-indent', 0);
                  this.renderer.setProperty(charEl, 'innerHTML', char);
                  charsEls.push(charEl);
                  this.charsEls.push(charEl);
                });

                for (let k = 0; k < charsEls.length; k += 1) {
                  this.renderer.appendChild(wordEl, charsEls[k]);
                }
              } else if (splitAction === SplitAction.Words) {
                this.renderer.setProperty(wordEl, 'innerHTML', word);
              }

              if (mask) {
                const wordMaskEl = this.renderer.createElement('span');
                this.renderer.addClass(wordMaskEl, 'word-mask');
                this.renderer.setStyle(wordMaskEl, 'display', 'inline-flex');
                this.renderer.setStyle(wordMaskEl, 'text-transform', 'inherit');
                this.renderer.setStyle(wordMaskEl, 'overflow', 'hidden');
                this.renderer.setStyle(wordMaskEl, 'text-indent', 0);

                if (this.maskPadding && this.maskPaddingUnits) {
                  this.renderer.setStyle(
                    wordMaskEl,
                    'padding',
                    `${this.maskPadding}${this.maskPaddingUnits} 0`,
                  );
                  this.renderer.setStyle(
                    wordMaskEl,
                    'margin',
                    `${-this.maskPadding}${this.maskPaddingUnits} 0`,
                  );
                }

                this.renderer.appendChild(wordMaskEl, wordEl);
                this.allEls.push(wordMaskEl);
              } else {
                this.allEls.push(wordEl);
              }
              wordsEls.push(wordEl);
            });
          this.wordEls.push(...wordsEls);
        }
      } else if (isTag) {
        if (node.nodeName === 'BR') {
          const brEl = this.renderer.createElement('br');
          this.allEls.push(brEl);
        }
      }
    }
  }

  splitIntoLine(): void {
    let startPoint: number | undefined;
    let lineIndex = 0;

    this.wordEls.forEach((word) => {
      if (startPoint === undefined) {
        startPoint = word.offsetTop;
        this.wordsLine.push([]);
      }

      if (startPoint !== word.offsetTop) {
        startPoint = word.offsetTop;
        lineIndex += 1;
        this.wordsLine.push([]);
      }
      this.wordsLine[lineIndex].push(word);
    });
  }

  public removeSplit(): void {
    this.el.nativeElement.innerHTML = this.srcText;
  }
}
