import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AnchorEl } from './anchor.common';

@Injectable({
  providedIn: 'root',
})
export class AnchorService {
  private _anchorEls = new BehaviorSubject<AnchorEl[]>([]);

  get anchorEls(): AnchorEl[] {
    return this._anchorEls.value;
  }

  set anchorEls(value: AnchorEl[]) {
    this._anchorEls.next(value);
  }

  get anchorEls$(): Observable<AnchorEl[]> {
    return this._anchorEls.asObservable();
  }

  public addAnchor(anchorEl: AnchorEl): void {
    this.anchorEls = [...this.anchorEls, anchorEl];
  }
}
