import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Project } from '@beta/api';
import { LangService, ResizeService } from '@beta/core';
import { SplitTextDirective } from '@beta/shared';
import {
  ButtonComponent,
  ButtonLineColor,
  ButtonLineType,
  IconName,
  IconSize,
} from '@beta/ui-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Back, gsap, Power2 } from 'gsap';
import { Observable, of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-projects-item, [app-projects-item]',
  templateUrl: './projects-item.component.html',
  styleUrls: ['./projects-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsItemComponent implements AfterViewInit, OnChanges {
  @Input() project!: Project;
  @Input() index!: number;
  @Input() currentIndex: number | undefined;
  @Input() active = false;
  @Input() activeNext = false;
  @Input() last = false;
  @Input() activeItemOnFastMove: Observable<boolean> = of(false);
  @Output() selectedIndex = new EventEmitter<number | undefined>();

  @ViewChild('item') item!: ElementRef<HTMLElement>;
  @ViewChild('itemFooter') itemFooter!: ElementRef<HTMLElement>;
  @ViewChild('info') info!: ElementRef<HTMLElement>;
  @ViewChild('image') image!: ElementRef<HTMLElement>;
  @ViewChild('imageInner') imageInner!: ElementRef<HTMLElement>;
  @ViewChild('icon', { read: ElementRef }) icon!: ElementRef<HTMLElement>;
  @ViewChild('iconMobile', { read: ElementRef })
  iconMobile!: ElementRef<HTMLElement>;

  @ViewChild('titleWrap') titleWrap!: ElementRef<HTMLElement>;

  @ViewChild('titleIcon') titleIcon!: ElementRef<HTMLElement>;
  @ViewChildren('border') border = new QueryList<ElementRef<HTMLElement>>();

  @ViewChild('dateRange', { read: SplitTextDirective })
  dateRange!: SplitTextDirective;

  @ViewChild('button', { read: ButtonComponent })
  button!: ButtonComponent;

  public currentLang$ = this.langService.currentLang$;

  iconName = IconName;
  iconSize = IconSize;
  buttonLineColor = ButtonLineColor;
  buttonLineType = ButtonLineType;
  tlShow = gsap.timeline();
  tlItem = gsap.timeline();
  tlFastMove = gsap.timeline();
  isShowed = false;
  fastMove = false;

  constructor(
    private langService: LangService,
    private resizeService: ResizeService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['active']) {
      this.tlItem
        .timeScale(1.5)
        .reversed(!changes['active'].currentValue)
        .resume();
      if (!this.fastMove) {
        this.tlFastMove.reversed(!changes['active'].currentValue).resume();
      }
    }
  }

  ngAfterViewInit(): void {
    this.activeItemOnFastMove
      .pipe(untilDestroyed(this))
      .subscribe((fastMove) => {
        this.fastMove = fastMove;
        this.tlFastMove.reversed(!fastMove && !this.active).resume();
      });
    this.initShowAnimation();
    this.initItemAnimation();
    // this.onResize();
  }

  initShowAnimation(): void {
    const border = this.border.map((item) => item.nativeElement);

    this.tlShow
      .clear()
      // .set(
      //   border,
      //   {
      //     backgroundColor: 'rgba(0, 0, 0, 1)',
      //   },
      //   0,
      // )
      .fromTo(
        border,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 0.7,
          ease: Power2.easeInOut,
          clearProps: 'scaleX',
        },
        0,
      );
    // .fromTo(
    //   border,
    //   {
    //     backgroundColor: 'rgba(0, 0, 0, 1)',
    //   },
    //   {
    //     backgroundColor: 'rgba(0, 0, 0, 0.07)',
    //     duration: 0.6,
    //     ease: Power2.easeInOut,
    //     clearProps: 'backgroundColor',
    //   },
    // )
    // .fromTo(
    //   this.iconMobile.nativeElement,
    //   {
    //     xPercent: -100,
    //     opacity: 0,
    //   },
    //   {
    //     xPercent: 0,
    //     opacity: 1,
    //     duration: 0.5,
    //     ease: Power2.easeInOut,
    //   },
    //   0,
    // );

    if (this.last) {
      this.tlShow.add(this.button.tlShow, '-=1').fromTo(
        this.dateRange.wordEls,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        '-=1',
      );
    }

    this.tlShow.add(() => {
      this.isShowed = true;
    });
  }

  initItemAnimation(): void {
    this.tlFastMove.clear().fromTo(
      this.titleWrap.nativeElement,
      {
        opacity: 0.1,
      },
      {
        opacity: 1,
        duration: 0.5,
        ease: Power2.easeInOut,
      },
      0,
    );
    this.tlItem
      .clear()
      .fromTo(
        this.image.nativeElement,
        {
          yPercent: -110,
        },
        {
          yPercent: 0,
          duration: 0.7,
          ease: Power2.easeInOut,
        },
        0,
      )
      .fromTo(
        this.imageInner.nativeElement,
        {
          yPercent: 110,
          // scale: 3,
          // filter: 'grayscale(100%) brightness(0.4)',
        },
        {
          yPercent: 0,
          // filter: 'grayscale(0%) brightness(1)',
          // scale: 1,
          duration: 0.7,
          ease: Power2.easeInOut,
        },
        0,
      )
      .fromTo(
        this.titleWrap.nativeElement,
        {
          yPercent: 116,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeInOut,
        },
        0,
      )
      .fromTo(
        this.info.nativeElement,
        {
          yPercent: 10,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          duration: 0.3,
          ease: Power2.easeInOut,
        },
        0.2,
      )
      .fromTo(
        this.icon.nativeElement,
        {
          xPercent: -100,
          opacity: 0,
        },
        {
          xPercent: 0,
          opacity: 1,
          duration: 0.5,
          ease: Power2.easeInOut,
        },
        0,
      )
      .fromTo(
        this.iconMobile.nativeElement,
        {
          xPercent: -100,
          opacity: 0,
        },
        {
          xPercent: 0,
          opacity: 1,
          duration: 0.5,
          ease: Power2.easeInOut,
        },
        0,
      )
      .fromTo(
        this.titleIcon.nativeElement,
        {
          scale: 0,
          xPercent: 65,
          yPercent: -20,
          rotate: 80,
        },
        {
          scale: 1,
          rotate: -10,
          duration: 0.7,
          ease: Back.easeOut,
        },
        0,
      )
      .pause();
  }

  selectItem(): void {
    if (!this.isShowed || this.resizeService.isMobile) {
      return;
    }
    this.selectedIndex.next(this.index);
  }

  // onResize(): void {
  //   this.resizeService.currentResolution$
  //     .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
  //     .subscribe(() => {
  //       this.initItemAnimation();
  //       if (this.currentIndex === this.index) {
  //         this.tlItem.progress(1).pause();
  //       }
  //       if (this.isShowed && this.resizeService.isMobile) {
  //         this.tlItem.progress(0).pause();
  //         this.selectedIndex.next(undefined);
  //       }
  //     });
  // }
}
