import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { LoadingProgressService, PreloaderService } from '@beta/core';
import { SplitTextDirective } from '@beta/shared';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap, Power2, Power3 } from 'gsap';
import { BehaviorSubject, combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreloaderComponent implements AfterViewInit {
  @ViewChildren('logo', { read: SplitTextDirective })
  logo = new QueryList<SplitTextDirective>();

  @ViewChild('subtitle') subtitle!: ElementRef<HTMLElement>;
  @ViewChild('bgOne') bgOne!: ElementRef<HTMLElement>;
  @ViewChild('bgTwo') bgTwo!: ElementRef<HTMLElement>;

  firsIterationEnd = new BehaviorSubject<boolean>(false);
  tlLoop = gsap.timeline({
    repeat: -1,
  });

  tlShow = gsap.timeline({
    delay: 0.5,
  });

  tlHide = gsap.timeline();

  constructor(
    @Inject(WINDOW) private window: Window,
    private loadingProgressService: LoadingProgressService,
    private preloaderService: PreloaderService,
  ) {}

  ngAfterViewInit(): void {
    const step = 100 / 4;
    this.tlShow
      .clear()
      .fromTo(
        this.logo.map((item) => item.wordEls),
        {
          yPercent: 145,
        },
        {
          yPercent: 0,
          stagger: 0.1,
          duration: 1,
          ease: Power2.easeOut,
        },
        0.2,
      )
      .from(
        this.subtitle.nativeElement,
        {
          yPercent: step,
          duration: 1.4,
          ease: Power3.easeInOut,
        },
        0,
      )
      .add(() => {
        this.tlLoop.resume();
      });

    this.tlLoop
      .clear()
      .to(this.subtitle.nativeElement, {
        yPercent: -step,
        duration: 1.2,
        ease: Power3.easeInOut,
      })
      .to(this.subtitle.nativeElement, {
        yPercent: -step * 2,
        duration: 1.2,
        ease: Power3.easeInOut,
      })
      .add(() => {
        if (!this.firsIterationEnd.value) {
          this.firsIterationEnd.next(true);
        }
      })
      .to(this.subtitle.nativeElement, {
        yPercent: -step * 3,
        duration: 1.2,
        ease: Power3.easeInOut,
      })
      .pause();

    this.initHideAnimation();

    combineLatest([
      this.loadingProgressService.loadingProgress$,
      this.firsIterationEnd.asObservable(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([loadingProgress, firsIterationEnd]) => {
        if (loadingProgress.loaded && firsIterationEnd) {
          this.tlLoop.pause();
          this.tlHide.resume();
        }
      });
  }

  initHideAnimation(): void {
    this.tlHide
      .clear()
      .add(() => {
        this.window.scrollTo(0, 0);
      })
      .fromTo(
        this.logo.map((item) => item.wordEls).reverse(),
        {
          yPercent: 0,
        },
        {
          yPercent: 145,
          stagger: 0.1,
          duration: 1,
          ease: Power2.easeOut,
          immediateRender: false,
        },
        0,
      )
      .to(
        this.subtitle.nativeElement,
        {
          yPercent: 100 / 4,
          duration: 1.4,
          ease: Power3.easeInOut,
        },
        0,
      )
      .to(
        this.bgTwo.nativeElement,
        {
          scaleY: 0,
          duration: 0.9,
          ease: Power3.easeOut,
        },
        0.5,
      )
      .to(
        this.bgOne.nativeElement,
        {
          scaleY: 0,
          duration: 0.7,
          ease: Power2.easeOut,
        },
        0.8,
      )

      .add(() => {
        this.preloaderService.preloaderIsHidden = true;
      })
      .pause();
  }
}
