import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { IconName, icons, IconSize } from './icon.common';

@Component({
  selector: 'app-icon, [app-icon]',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() iconName: IconName = IconName.LinkArrow;
  @Input() iconSize: IconSize = IconSize.XS;

  constructor(private sanitizer: DomSanitizer) {}

  get _icon(): SafeHtml {
    const icon = icons.find((item) => item.name === this.iconName)?.icons[
      this.iconSize
    ];

    if (!icon) {
      console.warn('Icon or Size does not exist');
    }

    return this.sanitizer.bypassSecurityTrustHtml(icon || '');
  }
}
