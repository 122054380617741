import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { loadMeta } from './meta.actions';
import { MetaPartialState } from './meta.reducer';
import {
  getMeta,
  getMetaError,
  getMetaLoading,
  getMetaState,
} from './meta.selectors';

@Injectable()
export class MetaFacade {
  metaState$ = this.store.select(getMetaState);
  meta$ = this.store.select(getMeta);
  metaLoading$ = this.store.select(getMetaLoading);
  metaError$ = this.store.select(getMetaError);

  constructor(private store: Store<MetaPartialState>) {}

  getMeta(): void {
    this.store.dispatch(loadMeta());
  }
}
