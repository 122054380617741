import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ResizeService } from '@beta/core';
import { Back, gsap, Power2 } from 'gsap';

import { IconName, IconSize } from '../icon';

import { ButtonIconColor, ButtonIconSize } from './button-icon.common';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconComponent implements AfterViewInit {
  @Input() iconName: IconName = IconName.LinkArrow;
  @Input() iconSize: IconSize = IconSize.XS;
  @Input() buttonColor: ButtonIconColor = ButtonIconColor.Yellow;
  @Input() buttonSize: ButtonIconSize = ButtonIconSize.S;
  @Input() borderColor: string | undefined;
  @Output() hovered = new EventEmitter<boolean>();

  @ViewChild('button') button!: ElementRef<HTMLElement>;
  @ViewChild('buttonInner') buttonInner!: ElementRef<HTMLElement>;
  @ViewChild('buttonIcon', { read: ElementRef })
  buttonIcon!: ElementRef<HTMLElement>;

  tlShow = gsap.timeline();
  isShowed = false;

  constructor(private resizeService: ResizeService) {}

  ngAfterViewInit(): void {
    this.setInitPosition();
    this.initShowAnimation();
  }

  initShowAnimation(): void {
    this.tlShow
      .clear()
      .fromTo(
        this.buttonInner.nativeElement,
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 0.8,
          ease: Back.easeOut,
        },
        0,
      )
      .fromTo(
        this.buttonIcon.nativeElement,
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 0.5,
          ease: Back.easeOut,
        },
        0.2,
      )
      .add(() => {
        this.isShowed = true;
      });
  }

  setInitPosition(): void {
    gsap.set([this.buttonInner.nativeElement, this.buttonIcon.nativeElement], {
      xPercent: -50,
      yPercent: -50,
    });
  }

  buttonAnimation(hover: boolean, event?: MouseEvent): void {
    if (!this.isShowed || this.resizeService.isMobile) {
      return;
    }
    if (hover && event) {
      this.hovered.emit(true);
      const x = event.offsetX - this.button.nativeElement.offsetWidth / 2;
      const y = event.offsetY - this.button.nativeElement.offsetHeight / 2;
      gsap.to(this.buttonInner.nativeElement, {
        x: x * 0.4,
        y: y * 0.4,
        direction: 1,
        ease: Power2.easeOut,
      });
      gsap.to(this.buttonIcon.nativeElement, {
        x: x * 0.6,
        y: y * 0.6,
        direction: 1,
        ease: Power2.easeOut,
      });
    } else {
      this.hovered.emit(false);
      gsap.to([this.buttonInner.nativeElement, this.buttonIcon.nativeElement], {
        x: 0,
        y: 0,
        direction: 1,
        ease: Power2.easeOut,
      });
    }
  }
}
