import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CursorActionDirective } from './cursor-action.directive';

@NgModule({
  declarations: [CursorActionDirective],
  imports: [CommonModule],
  exports: [CursorActionDirective],
})
export class CursorActionModule {}
