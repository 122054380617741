import { Project } from '@beta/api';
import { createAction, props } from '@ngrx/store';

export enum ProjectsActionType {
  LOAD_PROJECTS = '[Projects] Load Projects',
  LOAD_PROJECTS_SUCCESS = '[Projects] Load Projects Success',
  LOAD_PROJECTS_FAILURE = '[Projects] Load Projects Failure',
}

export const loadProjects = createAction(ProjectsActionType.LOAD_PROJECTS);

export const loadProjectsSuccess = createAction(
  ProjectsActionType.LOAD_PROJECTS_SUCCESS,
  props<{
    projects: Project[];
  }>(),
);

export const loadProjectsFailure = createAction(
  ProjectsActionType.LOAD_PROJECTS_FAILURE,
  props<{ error: string }>(),
);
