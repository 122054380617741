import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransitionService {
  private _transitionCloseStart = new BehaviorSubject<boolean>(false);
  private _transitionCloseEnd = new BehaviorSubject<boolean>(false);
  private _transitionOpenStart = new BehaviorSubject<boolean>(false);
  private _transitionOpenEnd = new BehaviorSubject<boolean>(true);

  get transitionCloseStart(): boolean {
    return this._transitionCloseStart.value;
  }

  set transitionCloseStart(value: boolean) {
    this._transitionCloseStart.next(value);
  }

  get transitionCloseStart$(): Observable<boolean> {
    return this._transitionCloseStart.asObservable();
  }

  get transitionCloseEnd(): boolean {
    return this._transitionCloseEnd.value;
  }

  set transitionCloseEnd(value: boolean) {
    this._transitionCloseEnd.next(value);
  }

  get transitionCloseEnd$(): Observable<boolean> {
    return this._transitionCloseEnd.asObservable();
  }

  get transitionOpenStart(): boolean {
    return this._transitionOpenStart.value;
  }

  set transitionOpenStart(value: boolean) {
    this._transitionOpenStart.next(value);
  }

  get transitionOpenStart$(): Observable<boolean> {
    return this._transitionOpenStart.asObservable();
  }

  get transitionOpenEnd(): boolean {
    return this._transitionOpenEnd.value;
  }

  set transitionOpenEnd(value: boolean) {
    this._transitionOpenEnd.next(value);
  }

  get transitionOpenEnd$(): Observable<boolean> {
    return this._transitionOpenEnd.asObservable();
  }
}
