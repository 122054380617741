import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CanDeactivate, NavigationEnd, Router } from '@angular/router';
import { Meta } from '@beta/api';
import {
  LangService,
  LogoNavigation,
  LogoService,
  LogoType,
  MetaDataService,
  NavigationService,
  TransitionService,
} from '@beta/core';
import { MetaFacade } from '@beta/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TransitionGuard implements CanDeactivate<boolean> {
  constructor(
    private router: Router,
    private transitionService: TransitionService,
    private logoService: LogoService,
    private navigationService: NavigationService,
    private metaFacade: MetaFacade,
    private title: Title,
    private langService: LangService,
    private metaDataService: MetaDataService,
  ) {
    combineLatest([
      this.router.events.pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      ),
      this.metaFacade.meta$.pipe(
        filter((meta): meta is Meta[] => !!meta),
        take(1),
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([event, meta]) => {
        this.transitionService.transitionOpenStart = true;
        if (event.urlAfterRedirects === '/') {
          this.logoService.logoType = LogoType.Default;
          this.navigationService.eventIsVisible = true;
          this.logoService.logoLine = true;
          this.logoService.logoNavigation = LogoNavigation.Top;
        } else {
          this.logoService.logoType = LogoType.Small;
          this.navigationService.eventIsVisible = false;
          this.logoService.logoLine = false;
          this.logoService.logoNavigation = LogoNavigation.Home;
        }
        this.logoService.logoReset = true;

        const metaData = meta.find(
          (item) => item.page === event.urlAfterRedirects,
        );

        if (metaData) {
          this.metaDataService.setMeta(metaData);
          this.title.setTitle(
            metaData.lang[this.langService.currentLang].title,
          );
        }
      });
  }

  canDeactivate(): Observable<boolean> {
    this.transitionService.transitionCloseEnd = false;
    this.transitionService.transitionOpenStart = false;
    this.transitionService.transitionOpenEnd = false;
    this.transitionService.transitionCloseStart = true;

    return this.transitionService.transitionCloseEnd$.pipe(
      filter((closeEnd) => closeEnd),
      take(1),
      untilDestroyed(this),
    );
  }
}
