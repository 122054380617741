import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CursorActionModule, SplitTextModule } from '@beta/shared';
import { IconModule } from '@beta/ui-common';
import { SvgDrawStrokeModule } from '@ng-blue-duct-tape/svg-draw-stroke';

import { NavigationComponent } from './navigation.component';
import { NavigationLinkComponent } from './navigation-link';

@NgModule({
  declarations: [NavigationComponent, NavigationLinkComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    CursorActionModule,
    SvgDrawStrokeModule,
    SplitTextModule,
  ],
  exports: [NavigationComponent],
})
export class NavigationModule {}
