import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { loadHeaderImages } from './header-images.actions';
import { HeaderImagesPartialState } from './header-images.reducer';
import {
  getHeaderImages,
  getHeaderImagesError,
  getHeaderImagesLoading,
  getHeaderImagesState,
} from './header-images.selectors';

@Injectable()
export class HeaderImagesFacade {
  headerImagesState$ = this.store.select(getHeaderImagesState);
  headerImages$ = this.store.select(getHeaderImages);
  headerImagesLoading$ = this.store.select(getHeaderImagesLoading);
  headerImagesError$ = this.store.select(getHeaderImagesError);

  constructor(private store: Store<HeaderImagesPartialState>) {}

  getHeaderImages(): void {
    this.store.dispatch(loadHeaderImages());
  }
}
