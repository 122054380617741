import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { SplitTextDirective } from '@beta/shared';
import {
  ButtonIconColor,
  ButtonIconComponent,
  ButtonIconSize,
  IconName,
  IconSize,
} from '@beta/ui-common';
import { SvgDrawStrokeDirective } from '@ng-blue-duct-tape/svg-draw-stroke';
import { gsap, Power2 } from 'gsap';

import { HeaderType } from '../header.common';

@Component({
  selector: 'app-header-title, [app-header-title]',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderTitleComponent implements AfterViewInit {
  @Input() title = '';
  @Input() counter: number | null | undefined;
  @Input() showreel = false;
  @Input() currentHeaderType!: HeaderType;
  @ViewChild('line', { read: SvgDrawStrokeDirective })
  line!: SvgDrawStrokeDirective;

  @ViewChild('titleEl', { read: SplitTextDirective })
  titleEl!: SplitTextDirective;

  @ViewChild('counterEl', { read: SplitTextDirective })
  counterEl!: SplitTextDirective;

  @ViewChild('button', { read: ButtonIconComponent })
  button!: ButtonIconComponent;

  iconName = IconName;
  iconSize = IconSize;
  buttonIconColor = ButtonIconColor;
  buttonIconSize = ButtonIconSize;

  headerType = HeaderType;
  isShowed = false;
  tlShow = gsap.timeline();
  tlLine = gsap.timeline();

  ngAfterViewInit(): void {
    this.initTitleLineAnimation();
    this.iniShowAnimation();
  }

  iniShowAnimation(): void {
    const title = this.titleEl.wordEls;
    const counter = this.counterEl.wordEls;
    this.tlShow.clear().fromTo(
      title,
      {
        yPercent: 120,
      },
      {
        yPercent: 0,
        stagger: 0.1,
        duration: 1,
        ease: Power2.easeOut,
      },
      0,
    );

    if (
      this.currentHeaderType === this.headerType.Projects ||
      this.currentHeaderType === this.headerType.Vacancies
    ) {
      this.tlShow.fromTo(
        counter,
        {
          yPercent: 120,
        },
        {
          yPercent: 0,
          duration: 1,
          ease: Power2.easeOut,
        },
        0.7,
      );
    }

    this.tlShow.fromTo(
      this.line.stokeElements[0].element,
      {
        strokeDashoffset: this.line.stokeElements[0].strokeTotal,
      },
      {
        strokeDashoffset: 0,
        duration: 1,
        ease: Power2.easeInOut,
      },
      1,
    );

    if (this.showreel && this.button) {
      this.tlShow.add(this.button.tlShow, 0.5);
    }
    this.tlShow.add(() => {
      this.isShowed = true;
    });
  }

  initTitleLineAnimation(): void {
    this.tlLine
      .clear()
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: 0,
        },
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
          duration: 1,
          ease: Power2.easeInOut,
        },
      )
      .pause();
  }

  titleLineAnimation(hover: boolean): void {
    if (!this.isShowed) {
      return;
    }
    this.tlLine.reversed(!hover).resume();
  }
}
