import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { SplitTextDirective } from '@beta/shared';
import { gsap, Power2, Power4 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { IconName, IconSize } from '../icon';

import { LinkSize } from './link.common';

@Component({
  selector: 'app-link, [app-link]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent implements AfterViewInit {
  @Input() title = 'Link Title';
  @Input() href = 'href';
  @Input() blank = false;
  @Input() reverse = false;
  @Input() iconName: IconName = IconName.ArrowRightV;
  @Input() iconSize: IconSize = IconSize.XS;
  @Input() linkSize: LinkSize = LinkSize.S;

  @ViewChild('text', {
    read: SplitTextDirective,
  })
  text!: SplitTextDirective;

  @ViewChild('underline') underline!: ElementRef<HTMLElement>;
  @ViewChild('icon', { read: ElementRef }) icon!: ElementRef<HTMLElement>;
  @ViewChild('link') link!: ElementRef<HTMLElement>;

  linkSizeType = LinkSize;
  isShowed = false;
  underlineReversed = false;
  tlShow = gsap.timeline();
  tlUnderline = gsap.timeline({
    onUpdate: () => {
      if (this.underlineReversed !== this.tlUnderline.progress() >= 0.5) {
        this.underlineReversed = !this.underlineReversed;
        this.cd.detectChanges();
      }
    },
  });

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.initUnderlineAnimation();
    this.initShowAnimation();
    this.onLeaveTrigger();
  }

  initShowAnimation(): void {
    this.tlShow.clear().fromTo(
      this.text.wordEls,
      {
        yPercent: 105,
      },
      {
        yPercent: 0,
        duration: 0.5,
        ease: Power2.easeOut,
      },
      0,
    );
    if (!this.reverse) {
      this.tlShow.fromTo(
        this.underline.nativeElement,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 0.5,
          ease: Power4.easeInOut,
          clearProps: 'all',
        },
        0.4,
      );
    }
    this.tlShow
      .fromTo(
        this.icon.nativeElement,
        {
          xPercent: -50,
          opacity: 0,
        },
        {
          opacity: 1,
          xPercent: 0,
          duration: 0.5,
          clearProps: 'all',
          ease: Power4.easeInOut,
        },
        0.4,
      )
      .add(() => {
        this.isShowed = true;
      });
  }

  initUnderlineAnimation(): void {
    this.tlUnderline
      .clear()
      .fromTo(
        this.icon.nativeElement,
        {
          xPercent: 0,
        },
        {
          xPercent: 50,
          duration: 0.25,
          ease: Power2.easeInOut,
        },
        0,
      )
      .fromTo(
        this.underline.nativeElement,
        {
          scaleX: this.reverse ? 0 : 1,
        },
        {
          scaleX: this.reverse ? 1 : 0,
          duration: 0.25,
          ease: Power4.easeInOut,
        },
        0,
      )
      .fromTo(
        this.underline.nativeElement,
        {
          scaleX: this.reverse ? 1 : 0,
        },
        {
          scaleX: this.reverse ? 0 : 1,
          duration: 0.25,
          ease: Power4.easeInOut,
          immediateRender: false,
        },
      )
      .pause();
  }

  underlineAnimation(hover: boolean): void {
    if (!this.isShowed) {
      return;
    }
    this.tlUnderline.reversed(!hover).resume();
  }

  onLeaveTrigger(): void {
    ScrollTrigger.create({
      trigger: this.link.nativeElement,
      end: '100% 0',
      invalidateOnRefresh: true,
      onLeave: (trigger) => {
        if (this.isShowed) {
          this.text.removeSplit();
          trigger.kill();
        }
      },
    });
  }
}
