import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HeaderImagesFacade } from '@beta/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, mapTo, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HeaderImagesResolver implements Resolve<boolean> {
  constructor(private headerImagesFacade: HeaderImagesFacade) {}

  resolve(): Observable<boolean> {
    return this.headerImagesFacade.headerImagesState$.pipe(
      tap((headerImagesState) => {
        if (
          !headerImagesState.images &&
          !headerImagesState.loading &&
          !headerImagesState.error
        ) {
          this.headerImagesFacade.getHeaderImages();
        }
      }),
      filter(
        (headerImagesState) =>
          !headerImagesState.loading &&
          !headerImagesState.error &&
          !!headerImagesState.images,
      ),
      take(1),
      mapTo(true),
      untilDestroyed(this),
    );
  }
}
