import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _menuIsActive = new BehaviorSubject<boolean>(false);

  get menuIsActive(): boolean {
    return this._menuIsActive.value;
  }

  set menuIsActive(value: boolean) {
    this._menuIsActive.next(value);
  }

  get menuIsActive$(): Observable<boolean> {
    return this._menuIsActive.asObservable();
  }
}
