import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CursorActionModule } from '@beta/shared';

import { IconModule } from '../icon';

import { ButtonIconComponent } from './button-icon.component';

@NgModule({
  declarations: [ButtonIconComponent],
  imports: [CommonModule, IconModule, CursorActionModule],
  exports: [ButtonIconComponent],
})
export class ButtonIconModule {}
