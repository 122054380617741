import debounce from 'lodash-es/debounce';

import * as I from '../interfaces';
import { eventScope } from '../utils';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function resizeHandler(scrollbar: I.Scrollbar) {
  const addEvent = eventScope(scrollbar);

  addEvent(window, 'resize', debounce(scrollbar.update.bind(scrollbar), 300));
}
