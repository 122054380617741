// eslint-disable-next-line max-classes-per-file
import { AirtableImage, AirtableRecord, Lang } from '../api.common';

export class ProjectData {
  public title!: string;
  // public titleHtml!: string;
  public description!: string;
  public descriptionHtml!: string;
  public awards!: string;
}

export class Project {
  public id!: string;
  public slug!: string;
  public preview!: AirtableImage;
  public url!: string;
  public featured!: boolean;
  public lang: { [key in Lang]: ProjectData } = {
    [Lang.Ru]: new ProjectData(),
    [Lang.En]: new ProjectData(),
  };

  deserialize(record: AirtableRecord): Project {
    this.id = record.id;

    if (record.fields) {
      if (record.fields['slug']) {
        this.slug = record.fields['slug'];
      }
      if (record.fields['title_ru']) {
        this.lang[Lang.Ru].title = record.fields['title_ru'];
      }
      // if (record.fields['title_ru_html']) {
      //   this.lang[Lang.Ru].titleHtml = record.fields['title_ru_html'];
      // }
      if (record.fields['title_en']) {
        this.lang[Lang.En].title = record.fields['title_en'];
      }
      // if (record.fields['title_en_html']) {
      //   this.lang[Lang.En].titleHtml = record.fields['title_en_html'];
      // }
      if (record.fields['description_ru']) {
        this.lang[Lang.Ru].description = record.fields['description_ru'];
      }
      if (record.fields['description_ru_html']) {
        this.lang[Lang.Ru].descriptionHtml =
          record.fields['description_ru_html'];
      }
      if (record.fields['description_en']) {
        this.lang[Lang.En].description = record.fields['description_en'];
      }
      if (record.fields['description_en_html']) {
        this.lang[Lang.En].descriptionHtml =
          record.fields['description_en_html'];
      }
      if (record.fields['awards_ru']) {
        this.lang[Lang.Ru].awards = record.fields['awards_ru'];
      }
      if (record.fields['awards_en']) {
        this.lang[Lang.En].awards = record.fields['awards_en'];
      }
      if (record.fields['preview'] && record.fields['preview']?.length) {
        this.preview = new AirtableImage().deserialize(
          record.fields['preview'][0],
        );
        const image = new Image();
        image.src = this.preview.src;
        image.decode();
      }
      if (record.fields['url']) {
        this.url = record.fields['url'];
      }
      this.featured = !!record.fields['featured'];
    }
    return this;
  }
}
