import { AirtableRecord } from '../api.common';

export class Vacancy {
  public id!: string;
  public slug!: string;
  public title!: string;

  deserialize(record: AirtableRecord): Vacancy {
    this.id = record.id;

    if (record.fields) {
      if (record.fields['slug']) {
        this.slug = record.fields['slug'];
      }
      if (record.fields['title_ru']) {
        this.title = record.fields['title_ru'];
      }
    }
    return this;
  }
}
