import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { CursorActionType, CursorService } from '@beta/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, merge } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[appCursorAction], appCursorAction',
})
export class CursorActionDirective implements AfterViewInit {
  @Input() cursorAction = CursorActionType.Active;

  constructor(
    private el: ElementRef<HTMLElement>,
    private cursorService: CursorService,
  ) {}

  ngAfterViewInit(): void {
    this.setCursorAction();
  }

  setCursorAction(): void {
    merge(
      fromEvent<MouseEvent>(this.el.nativeElement, 'mouseover').pipe(
        mapTo(true),
      ),
      fromEvent<MouseEvent>(this.el.nativeElement, 'mouseleave').pipe(
        mapTo(false),
      ),
    )
      .pipe(untilDestroyed(this))
      .subscribe((hover) => {
        this.cursorService.cursorAction = {
          action: this.cursorAction,
          active: hover,
        };
      });
  }
}
