<div class="header-title">
  <div class="cell"></div>
  <div class="cell cell_space-7">
    <h2
      class="header-title__title"
      (mouseover)="titleLineAnimation(true)"
      (mouseleave)="titleLineAnimation(false)"
    >
      <span
        class="header-title__title-inner"
        #titleEl
        appSplitText
        [maskPadding]="2.3"
        [maskPaddingUnits]="'rem'"
        >{{ title }}</span
      >
      <span
        class="subtitle subtitle_large header-title__title-counter"
        #counterEl
        appSplitText
        >{{ counter || '' }}</span
      >
      <span
        class="header-title__title-line"
        [ngClass]="['header-title__title-line_' + currentHeaderType]"
      >
        <ng-container *ngIf="currentHeaderType === headerType.Projects">
          <svg
            bdtSvgDrawStroke
            #line
            width="259"
            height="320"
            viewBox="0 0 259 320"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M85.985 273.256c239.779-96.01 158.172-145.127 155.557-146.954-39.941-27.89-117.606 46.603-126.407 27.837-8.36-17.828 161.151-204.37 38.476-137.871C55.481 69.463 4.269 245.728 2 318"
              stroke="#EB4626"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </ng-container>
        <ng-container *ngIf="currentHeaderType === headerType.About">
          <svg
            bdtSvgDrawStroke
            #line
            width="340"
            height="306"
            viewBox="0 0 340 306"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M232.377 3C211.693 4.203 139.01 29.134 13.751 119.233c-13.468 8.904-25.11 23.247 36.077 9.386 61.187-13.862 102.939.481 116.168 9.385 19.241 9.866 45.168 44.472-5.051 103.96-46.928 55.59-107.51 64.734-129.877 59.922-19.481-6.739-45.601-30.9 5.772-73.639 64.217-53.424 176.056-52.702 181.106-52.702h17.317c2.405 0 11.705-.63 16.596-10.829 6.195-12.92-14.431-21.659-23.09 20.936-6.926 34.076 69.268-.24 108.231-21.658"
              stroke="#31B5FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </ng-container>
        <ng-container *ngIf="currentHeaderType === headerType.Vacancies">
          <svg
            bdtSvgDrawStroke
            #line
            width="588"
            height="270"
            viewBox="0 0 588 270"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 185.196c71.118-77.094 214.356-191.802 218.36-33.883 5.006 197.4-173.312 111.959 28.156-45.667 161.173-126.1 115.749-21.444 72.578 63.345C298.717 216.868 326.848 258.852 584 4"
              stroke="#854DFF"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </ng-container>
        <ng-container *ngIf="currentHeaderType === headerType.Services">
          <svg
            bdtSvgDrawStroke
            #line
            width="430"
            height="328"
            viewBox="0 0 430 328"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M425.756 323.994c-42.484-1.953-106.376-23.15-22.073-92.308 14.953-13.187 63.37-42.492-215.742-42.492-223.29 0-196.517-50.794-155.22-76.191C176.549 1.653 390.155-73.069 146.644 145.238"
              stroke="#FFE600"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </ng-container>
      </span>
      <app-button-icon
        *ngIf="showreel"
        class="header-title__button"
        #button
        [iconName]="iconName.Play"
        [iconSize]="iconSize.L"
        [buttonColor]="buttonIconColor.Yellow"
        [buttonSize]="buttonIconSize.M"
        [borderColor]="'#1b1b1b'"
      ></app-button-icon>
    </h2>
  </div>
</div>
