import { Injectable } from '@angular/core';
import { ServicesService } from '@beta/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  loadServicesFailure,
  loadServicesSuccess,
  ServicesActionType,
} from './services.actions';

@UntilDestroy()
@Injectable()
export class ServicesEffects {
  loadServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActionType.LOAD_SERVICES),
      switchMap(() =>
        this.servicesService.get().pipe(
          // tap((services) => {
          //   console.log(services, 'services');
          // }),
          map((services) => loadServicesSuccess({ services })),
          catchError((error) =>
            of(loadServicesFailure({ error: error.message || 'Server Error' })),
          ),
        ),
      ),
      untilDestroyed(this),
    ),
  );

  constructor(
    private actions$: Actions,
    private servicesService: ServicesService,
  ) {}
}
