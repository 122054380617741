import { Vacancy } from '@beta/api';
import { Action, createReducer, on } from '@ngrx/store';

import {
  loadVacancies,
  loadVacanciesFailure,
  loadVacanciesSuccess,
} from './vacancies.actions';

export const VACANCIES_FEATURE_KEY = 'vacancies';

export interface VacanciesState {
  vacancies: Vacancy[] | undefined;
  loading: boolean;
  error: string | null;
}

export interface VacanciesPartialState {
  readonly [VACANCIES_FEATURE_KEY]: VacanciesState;
}

export const initialVacanciesState: VacanciesState = {
  vacancies: undefined,
  loading: false,
  error: null,
};

const vacanciesReducer = createReducer(
  initialVacanciesState,
  on(loadVacancies, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadVacanciesSuccess, (state, { vacancies }) => ({
    ...state,
    vacancies,
    loading: false,
  })),
  on(loadVacanciesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: VacanciesState | undefined, action: Action) =>
  vacanciesReducer(state, action);
