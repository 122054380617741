<header class="header" *ngIf="currentLang$ | async as lang" #header>
  <div class="container header__container">
    <div class="header__inner">
      <div
        class="header__layer header__layer_title"
        #title
        [title]="headerData[headerType]?.[lang]?.title || ''"
        [counter]="getCounter() | async"
        [showreel]="getShowreel()"
        [currentHeaderType]="headerType"
        app-header-title
      ></div>
      <div
        class="header__layer header__layer_footer"
        #footer
        [parent]="header"
        [description]="headerData[headerType]?.[lang]?.description || ''"
        [iconName]="headerData[headerType]?.[lang]?.icon || iconName.Like"
        app-header-footer
      ></div>
    </div>
  </div>
</header>
