import { Injectable } from '@angular/core';
import { ProjectsService } from '@beta/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  loadProjectsFailure,
  loadProjectsSuccess,
  ProjectsActionType,
} from './projects.actions';

@UntilDestroy()
@Injectable()
export class ProjectsEffects {
  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionType.LOAD_PROJECTS),
      switchMap(() =>
        this.projectsService.get().pipe(
          // tap((projects) => {
          //   console.log(projects, 'projects');
          // }),
          map((projects) => loadProjectsSuccess({ projects })),
          catchError((error) =>
            of(loadProjectsFailure({ error: error.message || 'Server Error' })),
          ),
        ),
      ),
      untilDestroyed(this),
    ),
  );

  constructor(
    private actions$: Actions,
    private projectsService: ProjectsService,
  ) {}
}
