export interface SocialLink {
  url: string;
  title: string;
}

export const socialLinks: SocialLink[] = [
  // {
  //   url: 'https://www.facebook.com/beta.moscow',
  //   title: 'Facebook',
  // },
  // {
  //   url: 'https://www.instagram.com/beta.moscow/',
  //   title: 'Instagram',
  // },
  {
    url: 'https://dribbble.com/betamoscow',
    title: 'Dribbble',
  },
  // {
  //   url: 'https://www.behance.net/betaagency',
  //   title: 'Behance',
  // },
  {
    url: 'https://vimeo.com/betamoscow',
    title: 'Vimeo',
  },
];
