export enum LogoType {
  Default = 'default',
  Small = 'small',
}

export enum LogoNavigation {
  Home = 'home',
  Top = 'top',
}

export enum LogoColor {
  Light = 'light',
  Dark = 'dark',
}
