import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  HEADER_IMAGES_FEATURE_KEY,
  HeaderImagesState,
} from './header-images.reducer';

export const getHeaderImagesState = createFeatureSelector<HeaderImagesState>(
  HEADER_IMAGES_FEATURE_KEY,
);

export const getHeaderImages = createSelector(
  getHeaderImagesState,
  (state) => state.images,
);

export const getHeaderImagesLoading = createSelector(
  getHeaderImagesState,
  (state) => state.loading,
);

export const getHeaderImagesError = createSelector(
  getHeaderImagesState,
  (state) => state.error,
);
