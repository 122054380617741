import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Service } from '@beta/api';
import { AnchorService, ResizeService, SmoothScrollService } from '@beta/core';
import { SplitTextDirective } from '@beta/shared';
import {
  ButtonComponent,
  ButtonLineColor,
  ButtonLineType,
  IconName,
  IconSize,
} from '@beta/ui-common';
import { SvgDrawStrokeDirective } from '@ng-blue-duct-tape/svg-draw-stroke';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Back, gsap, Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import clamp from 'lodash-es/clamp';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ServicesItemComponent } from './services-item';

@UntilDestroy()
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesComponent implements AfterViewInit {
  @Input() services!: Service[];
  @ViewChild('servicesWrap')
  servicesWrap!: ElementRef<HTMLElement>;

  @ViewChild('line', { read: SvgDrawStrokeDirective })
  line!: SvgDrawStrokeDirective;

  @ViewChildren('title', { read: SplitTextDirective }) title =
    new QueryList<SplitTextDirective>();

  @ViewChildren('subtitle', { read: SplitTextDirective }) subtitle =
    new QueryList<SplitTextDirective>();

  @ViewChild('description', { read: SplitTextDirective })
  description!: SplitTextDirective;

  @ViewChild('subtitleDescription', { read: SplitTextDirective })
  subtitleDescription!: SplitTextDirective;

  @ViewChild('mainDescription', { read: SplitTextDirective })
  mainDescription!: SplitTextDirective;

  @ViewChild('mainDescriptionMini', { read: SplitTextDirective })
  mainDescriptionMini!: SplitTextDirective;

  @ViewChild('icon', { read: ElementRef }) icon!: ElementRef<HTMLElement>;
  @ViewChild('subtitleDescriptionIcon', { read: ElementRef })
  subtitleDescriptionIcon!: ElementRef<HTMLElement>;

  @ViewChildren('item', { read: ServicesItemComponent })
  item = new QueryList<ServicesItemComponent>();

  @ViewChild('button', { read: ButtonComponent })
  button!: ButtonComponent;

  @ViewChild('footerText', { read: SplitTextDirective })
  footerText!: SplitTextDirective;

  iconName = IconName;
  iconSize = IconSize;
  buttonLineColor = ButtonLineColor;
  buttonLineType = ButtonLineType;

  tlShow = gsap.timeline();
  tlLine = gsap.timeline();
  isShowed = false;
  currentServiceIndex: number | undefined;

  public currentServiceIndex$ = new BehaviorSubject<number | undefined>(
    undefined,
  );

  constructor(
    private cd: ChangeDetectorRef,
    private anchorService: AnchorService,
    private smoothScrollService: SmoothScrollService,
    private resizeService: ResizeService,
  ) {}

  ngAfterViewInit(): void {
    this.initTitleLineAnimation();
    this.iniShowAnimation();
    this.setCurrentServiceIndex();
    this.showAnimation();
    this.onLeaveTrigger();
  }

  scrollTo(): void {
    if (this.resizeService.isMobile) {
      return;
    }
    const anchor = this.anchorService.anchorEls.find(
      (item) => item.name === 'contacts',
    );

    if (this.smoothScrollService.scrollbar && anchor) {
      const duration = clamp(
        (400 *
          Math.abs(
            (this.smoothScrollService.smoothScroll?.offsetY || 0) -
              anchor.el.offsetTop,
          )) /
          500,
        400,
        1500,
      );
      this.smoothScrollService.scrollbar.scrollTo(
        0,
        anchor.el.offsetTop,
        duration,
      );
    }
  }

  showAnimation(): void {
    ScrollTrigger.create({
      trigger: this.servicesWrap.nativeElement,
      once: true,
      start: '0 100%',
      onEnter: () => {
        this.tlShow.resume();
      },
    });
  }

  setCurrentServiceIndex(): void {
    this.currentServiceIndex$
      .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((index) => {
        this.currentServiceIndex = index;
        this.cd.detectChanges();
      });
  }

  initTitleLineAnimation(): void {
    this.tlLine
      .clear()
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: 0,
        },
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
          duration: 1,
          ease: Power2.easeInOut,
        },
      )
      .pause();
  }

  iniShowAnimation(): void {
    const title = this.title.map((item) => item.wordEls);
    const description = this.description.wordEls;
    const mainDescriptionMini = this.mainDescriptionMini.wordEls;
    const subtitle = this.subtitle.map((item) => item.wordEls);
    const subtitleDescription = this.subtitleDescription.wordEls;
    this.tlShow.clear().fromTo(
      title,
      {
        yPercent: 120,
      },
      {
        yPercent: 0,
        stagger: 0.1,
        duration: 1,
        ease: Power2.easeOut,
      },
      0,
    );

    this.mainDescription.wordsLine.forEach((item, index) => {
      this.tlShow.fromTo(
        item,
        {
          yPercent: 145,
        },
        {
          yPercent: 0,
          duration: 1,
          ease: Power2.easeOut,
        },
        index * 0.07,
      );
    });

    this.tlShow
      .fromTo(
        description,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        0,
      )
      .fromTo(
        subtitle,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        1,
      )
      .fromTo(
        subtitleDescription,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        1,
      )
      .fromTo(
        mainDescriptionMini,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        1,
      )
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: 1,
          ease: Power2.easeInOut,
        },
        1,
      )
      .fromTo(
        this.icon.nativeElement,
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 0.5,
          ease: Back.easeOut,
        },
        0,
      )
      .fromTo(
        this.subtitleDescriptionIcon.nativeElement,
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 0.5,
          ease: Back.easeOut,
        },
        1,
      );

    this.item.forEach((item, index) => {
      this.tlShow.add(item.tlShow, index * 0.2 + 0.5);
    });

    this.tlShow
      .add(this.button.tlShow, '-=1')
      .fromTo(
        this.footerText.wordEls,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        '-=1',
      )
      .add(() => {
        this.isShowed = true;
      })
      .pause();
  }

  titleLineAnimation(hover: boolean): void {
    if (!this.isShowed) {
      return;
    }
    this.tlLine.reversed(!hover).resume();
  }

  onLeaveTrigger(): void {
    ScrollTrigger.create({
      trigger: this.servicesWrap.nativeElement,
      end: '100% 0',
      invalidateOnRefresh: true,
      onLeave: (trigger) => {
        if (this.isShowed) {
          this.description.removeSplit();
          this.mainDescription.removeSplit();
          this.mainDescriptionMini.removeSplit();
          this.subtitleDescription.removeSplit();
          this.footerText.removeSplit();
          this.subtitle.forEach((item) => {
            item.removeSplit();
          });
          this.title.forEach((item) => {
            item.removeSplit();
          });
          this.item.forEach((item) => {
            item.title.removeSplit();
            item.counter.removeSplit();
          });
          trigger.kill();
        }
      },
    });
  }
}
