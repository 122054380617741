import { HeaderImage } from '@beta/api';
import { createAction, props } from '@ngrx/store';

export enum HeaderImagesActionType {
  LOAD_HEADER_IMAGES = '[Header Images] Load Header Images',
  LOAD_HEADER_IMAGES_SUCCESS = '[Header Images] Load Header Images Success',
  LOAD_HEADER_IMAGES_FAILURE = '[Header Images] Load Header Images Failure',
}

export const loadHeaderImages = createAction(
  HeaderImagesActionType.LOAD_HEADER_IMAGES,
);

export const loadHeaderImagesSuccess = createAction(
  HeaderImagesActionType.LOAD_HEADER_IMAGES_SUCCESS,
  props<{
    images: HeaderImage[];
  }>(),
);

export const loadHeaderImagesFailure = createAction(
  HeaderImagesActionType.LOAD_HEADER_IMAGES_FAILURE,
  props<{ error: string }>(),
);
