import { Project } from '@beta/api';
import { Action, createReducer, on } from '@ngrx/store';

import {
  loadProjects,
  loadProjectsFailure,
  loadProjectsSuccess,
} from './projects.actions';

export const PROJECTS_FEATURE_KEY = 'projects';

export interface ProjectsState {
  projects: Project[] | undefined;
  loading: boolean;
  error: string | null;
}

export interface ProjectsPartialState {
  readonly [PROJECTS_FEATURE_KEY]: ProjectsState;
}

export const initialProjectsState: ProjectsState = {
  projects: undefined,
  loading: false,
  error: null,
};

const projectsReducer = createReducer(
  initialProjectsState,
  on(loadProjects, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadProjectsSuccess, (state, { projects }) => ({
    ...state,
    projects,
    loading: false,
  })),
  on(loadProjectsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: ProjectsState | undefined, action: Action) =>
  projectsReducer(state, action);
