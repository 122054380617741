<div class="footer" #footer>
  <div class="footer__noise">
    <app-noise></app-noise>
  </div>
  <div class="footer__bg" #bg></div>
  <div class="container footer__container">
    <div class="footer__inner">
      <div class="footer__left">
        <div class="footer__button">
          <app-button-icon
            (click)="scrollTop()"
            #button
            [iconName]="iconName.ArrowUpV"
            [iconSize]="iconSize.M"
            [buttonColor]="buttonIconColor.White"
            [buttonSize]="buttonIconSize.S"
          ></app-button-icon>
        </div>
        <div class="footer__links">
          <div class="cell cell_space-2">
            <ul class="list footer-contacts">
              <li class="footer-contacts__item">
                <app-link
                  #linkEl
                  [title]="'+7 (499) 704 63 34'"
                  [href]="'tel:+74997046334'"
                  [reverse]="true"
                ></app-link>
              </li>
              <li class="footer-contacts__item">
                <app-link
                  #linkEl
                  [title]="'hello@betaagency.ru'"
                  [href]="'mailto:hello@betaagency.ru'"
                ></app-link>
              </li>
            </ul>
          </div>
          <div class="cell">
            <ul class="list footer-socials">
              <li class="footer-socials__item" *ngFor="let link of socialLinks">
                <app-link
                  #linkEl
                  [href]="link.url"
                  [title]="link.title"
                  [iconName]="iconName.SquareArrow"
                  [reverse]="true"
                  [blank]="true"
                ></app-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer__right">
        <div class="cell cell_space-4 footer__logo-cell">
          <span
            class="text text_default-spec footer__event"
            #event
            appSplitText
          >
            <!-- Выпить с бетой 🍻 -->
          </span>
          <div class="footer__logo" #logo></div>
        </div>
      </div>
    </div>
  </div>
</div>
