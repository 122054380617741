import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  AnchorService,
  MenuService,
  ResizeService,
  SmoothScrollService,
} from '@beta/core';
import { IconName } from '@beta/ui-common';
import { WINDOW } from '@ng-web-apis/common';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { socialLinks } from '../footer';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  socialLinks = socialLinks;
  iconName = IconName;

  constructor(
    @Inject(WINDOW) private window: Window,
    private resizeService: ResizeService,
    private menuService: MenuService,
    private smoothScrollService: SmoothScrollService,
    private anchorService: AnchorService,
  ) {}

  public menuIsActive$ = this.menuService.menuIsActive$;
  public viewportHeight$ = this.resizeService.currentResolution$.pipe(
    map((value) => value.height + 1),
    distinctUntilChanged(),
  );

  menuActive(): void {
    this.menuService.menuIsActive = !this.menuService.menuIsActive;
    this.smoothScrollService.scrollDisable = this.menuService.menuIsActive;
  }

  scrollTo(url: string): void {
    if (url === 'top') {
      this.window.scrollTo(0, 0);
      this.menuService.menuIsActive = false;
      return;
    }
    const anchor = this.anchorService.anchorEls.find(
      (item) => item.name === url,
    );
    if (anchor) {
      this.window.scrollTo(0, anchor.el.offsetTop);
      this.menuService.menuIsActive = false;
    }
  }
}
