import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SplitTextModule } from '@beta/shared';
import { ButtonIconModule, LinkModule, NoiseModule } from '@beta/ui-common';

import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    NoiseModule,
    CommonModule,
    ButtonIconModule,
    LinkModule,
    SplitTextModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
