<a
  class="link"
  #link
  [ngClass]="['link_' + linkSize]"
  [class.link_reverse]="reverse"
  (mouseover)="underlineAnimation(true)"
  (mouseleave)="underlineAnimation(false)"
  appCursorAction
  [href]="href"
  [target]="blank ? '_blank' : '_self'"
  [rel]="blank ? 'noopener noreferrer' : ''"
>
  <span
    class="link__icon"
    #icon
    app-icon
    [iconName]="iconName"
    [iconSize]="iconSize"
  ></span>
  <span
    [ngClass]="{
      'text text_default': linkSize === linkSizeType.S,
      'subtitle subtitle_medium': linkSize === linkSizeType.M
    }"
    class="link__text"
  >
    <span class="link__text-inner" #text appSplitText>{{ title }}</span>
    <span
      class="link__underline"
      [class.link__underline_reverse]="underlineReversed"
      #underline
    ></span>
  </span>
</a>
