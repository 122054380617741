import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { loadVacancies } from './vacancies.actions';
import { VacanciesPartialState } from './vacancies.reducer';
import {
  getVacancies,
  getVacanciesError,
  getVacanciesLoading,
  getVacanciesState,
} from './vacancies.selectors';

@Injectable()
export class VacanciesFacade {
  vacanciesState$ = this.store.select(getVacanciesState);
  vacancies$ = this.store.select(getVacancies);
  vacanciesLoading$ = this.store.select(getVacanciesLoading);
  vacanciesError$ = this.store.select(getVacanciesError);

  constructor(private store: Store<VacanciesPartialState>) {}

  getVacancies(): void {
    this.store.dispatch(loadVacancies());
  }
}
