import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CursorActionType, CursorService } from '@beta/core';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap, Power2 } from 'gsap';
import { fromEvent } from 'rxjs';
import { skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-cursor',
  templateUrl: './cursor.component.html',
  styleUrls: ['./cursor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CursorComponent implements AfterViewInit {
  @ViewChild('cursor') cursor!: ElementRef<HTMLElement>;
  @ViewChildren('cursorCore') cursorCore = new QueryList<
    ElementRef<HTMLElement>
  >();

  isInit = false;

  constructor(
    @Inject(WINDOW) private window: Window,
    private cursorService: CursorService,
  ) {}

  ngAfterViewInit(): void {
    const cursorCores = this.cursorCore.map((item) => item.nativeElement);
    this.setCursorInitial();
    this.getCursorPosition();
    this.setCursorPosition(cursorCores);
  }

  getCursorPosition(): void {
    fromEvent<MouseEvent>(this.window, 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.cursorService.cursorPosition = {
          x: event.clientX,
          y: event.clientY,
        };
      });
  }

  setCursorPosition(cursorCores: HTMLElement[]): void {
    this.cursorService.cursorPosition$
      .pipe(skip(1), untilDestroyed(this))
      .subscribe((cursorPosition) => {
        if (!this.isInit) {
          gsap.to(this.cursor.nativeElement, {
            opacity: 1,
            duration: 1,
            ease: Power2.easeOut,
          });
          this.isInit = true;
        }

        gsap.to(cursorCores, {
          xPercent: -50,
          yPercent: -50,
          x: cursorPosition.x,
          y: cursorPosition.y,
          duration: (index) => {
            if (index === 0) {
              return 0.1;
            }
            if (index === 1) {
              return 0.2;
            }
            if (index === 2) {
              return 0.3;
            }
            if (index === 3) {
              return 0.4;
            }
            return 0.1;
          },
          ease: Power2.easeOut,
        });

        if (
          this.cursorService.cursorAction.action === CursorActionType.Active
        ) {
          gsap.to(cursorCores, {
            scale: this.cursorService.cursorAction.active ? 3 : 1,
            duration: 0.5,
            ease: Power2.easeOut,
          });
        }

        if (this.cursorService.cursorAction.action === CursorActionType.Hide) {
          gsap.to(cursorCores, {
            scale: this.cursorService.cursorAction.active ? 0 : 1,
            duration: 0.5,
            ease: Power2.easeOut,
          });
        }
      });
  }

  setCursorInitial(): void {
    gsap.set(this.cursor.nativeElement, {
      opacity: 0,
    });
  }
}
