import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  CursorAction,
  CursorActionType,
  CursorPosition,
} from './cursor.common';

@Injectable({
  providedIn: 'root',
})
export class CursorService {
  private _cursorPosition = new BehaviorSubject<CursorPosition>({
    x: 0,
    y: 0,
  });

  private _cursorAction = new BehaviorSubject<CursorAction>({
    action: CursorActionType.Active,
    active: false,
  });

  get cursorPosition(): CursorPosition {
    return this._cursorPosition.value;
  }

  set cursorPosition(value: CursorPosition) {
    this._cursorPosition.next(value);
  }

  get cursorPosition$(): Observable<CursorPosition> {
    return this._cursorPosition.asObservable();
  }

  get cursorAction(): CursorAction {
    return this._cursorAction.value;
  }

  set cursorAction(value: CursorAction) {
    this._cursorAction.next(value);
  }

  get cursorAction$(): Observable<CursorAction> {
    return this._cursorAction.asObservable();
  }
}
