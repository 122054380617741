import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Service } from '@beta/api';
import { LangService, ResizeService } from '@beta/core';
import { SplitTextDirective } from '@beta/shared';
import { IconName, IconSize } from '@beta/ui-common';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Back, gsap, Power2 } from 'gsap';
import { distinctUntilChanged, skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-services-item, [app-services-item]',
  templateUrl: './services-item.component.html',
  styleUrls: ['./services-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesItemComponent implements AfterViewInit, OnChanges {
  @Input() service!: Service;
  @Input() index!: number;
  @Input() active = false;
  @Input() activeNext = false;
  @Input() last = false;
  @Output() selectedIndex = new EventEmitter<number | undefined>();

  @ViewChild('title', { read: SplitTextDirective }) title!: SplitTextDirective;
  @ViewChild('counter', { read: SplitTextDirective })
  counter!: SplitTextDirective;

  @ViewChildren('border') border = new QueryList<ElementRef<HTMLElement>>();
  @ViewChildren('sticker') sticker = new QueryList<ElementRef<HTMLElement>>();
  @ViewChild('stickerArea') stickerArea!: ElementRef<HTMLElement>;

  @ViewChild('icon', { read: ElementRef }) icon!: ElementRef<HTMLElement>;
  @ViewChild('iconMobile', { read: ElementRef })
  iconMobile!: ElementRef<HTMLElement>;

  public currentLang$ = this.langService.currentLang$;

  iconName = IconName;
  iconSize = IconSize;
  tlShow = gsap.timeline();
  tlItem = gsap.timeline();
  isShowed = false;

  stickerHTML: HTMLElement[] = [];
  stickerAreaOffsetHeight = 0;
  stickerAreaOffsetWidth = 0;

  constructor(
    @Inject(WINDOW) private window: Window,
    private langService: LangService,
    private resizeService: ResizeService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['active']) {
      this.tlItem.reversed(!changes['active'].currentValue).resume();
    }
  }

  ngAfterViewInit(): void {
    this.stickerHTML = this.sticker.map((item) => item.nativeElement);
    this.updateValues();
    this.initShowAnimation();
    this.initItemAnimation();

    this.onResize();
  }

  updateValues(): void {
    this.window.requestAnimationFrame(() => {
      this.stickerAreaOffsetHeight =
        this.stickerArea.nativeElement.offsetHeight;
      this.stickerAreaOffsetWidth = this.stickerArea.nativeElement.offsetWidth;
    });
  }

  onResize(): void {
    this.resizeService.currentResolution$
      .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.updateValues();
      });
  }

  initShowAnimation(): void {
    const border = this.border.map((item) => item.nativeElement);

    this.tlShow
      .clear()
      .set(
        this.stickerHTML,
        {
          xPercent: -50,
          yPercent: -50,
        },
        0,
      )
      .set(
        border,
        {
          backgroundColor: 'rgba(0, 0, 0, 1)',
        },
        0,
      )
      .fromTo(
        border,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 0.7,
          ease: Power2.easeInOut,
          clearProps: 'scaleX',
        },
        0,
      )
      .fromTo(
        border,
        {
          backgroundColor: 'rgba(0, 0, 0, 1)',
        },
        {
          backgroundColor: 'rgba(0, 0, 0, 0.07)',
          duration: 0.6,
          ease: Power2.easeInOut,
          clearProps: 'backgroundColor',
        },
      )
      .fromTo(
        this.title.wordEls,
        {
          yPercent: 145,
        },
        {
          yPercent: 0,
          duration: 1,
          ease: Power2.easeOut,
        },
        0,
      )
      .fromTo(
        this.counter.wordEls,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        0.5,
      )
      .fromTo(
        this.iconMobile.nativeElement,
        {
          xPercent: -100,
          opacity: 0,
        },
        {
          xPercent: 0,
          opacity: 1,
          duration: 0.5,
          ease: Power2.easeInOut,
        },
        0,
      );

    this.tlShow.add(() => {
      this.isShowed = true;
    });
  }

  initItemAnimation(): void {
    this.tlItem
      .clear()
      .fromTo(
        this.stickerHTML,
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 0.5,
          stagger: 0.04,
          ease: Back.easeOut,
        },
        0,
      )
      .fromTo(
        this.icon.nativeElement,
        {
          xPercent: -100,
          opacity: 0,
        },
        {
          xPercent: 0,
          opacity: 1,
          duration: 0.5,
          ease: Power2.easeInOut,
        },
        0,
      )
      .pause();
  }

  itemAnimation(hover: boolean): void {
    if (!this.isShowed || this.resizeService.isMobile) {
      return;
    }
    this.selectedIndex.next(hover ? this.index : undefined);
  }

  stickerAnimation(hover: boolean, event?: MouseEvent): void {
    if (!this.isShowed || this.resizeService.isMobile) {
      return;
    }

    this.window.requestAnimationFrame(() => {
      if (hover && event) {
        const x = event.offsetX - this.stickerAreaOffsetWidth / 2;
        const y = event.offsetY - this.stickerAreaOffsetHeight / 2;
        const degrees = (6 * x) / (this.stickerAreaOffsetWidth / 2);

        gsap.to(this.stickerHTML[0], {
          x: x * 0.5,
          y: y * 1,
          duration: 1,
          rotation: degrees,
          ease: Power2.easeOut,
        });

        gsap.to(this.stickerHTML[1], {
          x: x * 0.55,
          y: y * 1.05,
          duration: 1,
          rotation: degrees,
          ease: Power2.easeOut,
        });

        gsap.to(this.stickerHTML[2], {
          x: x * 0.6,
          y: y * 1.1,
          duration: 1,
          rotation: degrees,
          ease: Power2.easeOut,
        });

        gsap.to(this.stickerHTML[3], {
          x: x * 0.65,
          y: y * 1.15,
          duration: 1,
          rotation: degrees,
          ease: Power2.easeOut,
        });
      } else {
        gsap.to(this.stickerHTML, {
          x: 0,
          y: 0,
          duration: 1,
          rotation: 0,
          ease: Power2.easeOut,
        });
      }
    });
  }
}
