<div
  class="services-item"
  [class.services-item_active]="active"
  [class.services-item_active-next]="activeNext"
  [class.services-item_last]="last"
  *ngIf="currentLang$ | async as lang"
>
  <div class="cell cell_space-3"></div>
  <div class="cell cell_space-5">
    <div
      class="services-item__content"
      (mouseover)="itemAnimation(true)"
      (mouseleave)="itemAnimation(false)"
    >
      <div class="services-item__border services-item__border_top" #border>
        <div class="services-item__border-inner"></div>
      </div>

      <div class="services-item__header">
        <div class="cell cell_space-4" appCursorAction>
          <span
            class="text text_caps services-item__counter"
            #counter
            appSplitText
            >{{ (index + 1 + '').padStart(2, '0') }} /</span
          >
          <span class="services-item__title">
            <span
              class="title services-item__title-inner"
              #title
              appSplitText
              [maskPaddingUnits]="'rem'"
              [maskPadding]="0.5"
              [innerHTML]="service.lang[lang].title"
            >
            </span>
          </span>
        </div>
        <span class="cell"></span>
      </div>

      <div
        class="services-item__icon"
        #icon
        app-icon
        [iconName]="iconName.ArrowRight"
        [iconSize]="iconSize.XL"
      ></div>
      <div
        class="services-item__icon-mobile"
        #iconMobile
        app-icon
        [iconName]="iconName.ArrowRight"
        [iconSize]="iconSize.XL"
      ></div>
      <div
        class="services-item__sticker-area"
        [ngClass]="['services-item__sticker-area_' + service.sticker]"
        (mousemove)="stickerAnimation(true, $event)"
        (mouseleave)="stickerAnimation(false)"
        #stickerArea
      >
        <div class="services-item__sticker" #sticker></div>
        <div class="services-item__sticker" #sticker></div>
        <div class="services-item__sticker" #sticker></div>
        <div class="services-item__sticker" #sticker></div>
      </div>
      <div
        class="services-item__border services-item__border_bottom"
        #border
        *ngIf="last"
      >
        <div class="services-item__border-inner"></div>
      </div>
    </div>
  </div>
</div>
