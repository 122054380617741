import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';

import { PlatformService } from '../platform';

import { Resolution } from './resize.common';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  private _currentResolution = new BehaviorSubject<Resolution>({
    width: 1920,
    height: 1080,
  });

  private _isMobile = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(WINDOW) private window: Window,
    private platformService: PlatformService,
  ) {
    this.currentResolution = {
      width: this.window.innerWidth,
      height: this.window.innerHeight,
    };

    this.isMobile =
      this.platformService.bowser.getPlatform().type !== 'desktop';

    fromEvent<UIEvent>(this.window, 'resize')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        // this.isMobile = this.window.innerWidth <= 1024;
        this.currentResolution = {
          width: this.window.innerWidth,
          height: this.window.innerHeight,
        };
      });
  }

  get currentResolution(): Resolution {
    return this._currentResolution.value;
  }

  set currentResolution(value: Resolution) {
    this._currentResolution.next(value);
  }

  get currentResolution$(): Observable<Resolution> {
    return this._currentResolution.asObservable();
  }

  get isMobile(): boolean {
    return this._isMobile.value;
  }

  set isMobile(value: boolean) {
    this._isMobile.next(value);
  }

  get isMobile$(): Observable<boolean> {
    return this._isMobile.asObservable();
  }
}
