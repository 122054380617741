import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CursorActionModule } from '@beta/shared';
import { IconModule } from '@beta/ui-common';
import { SvgDrawStrokeModule } from '@ng-blue-duct-tape/svg-draw-stroke';

import { TickerComponent } from './ticker.component';
import { TickerItemComponent } from './ticker-item';
import { TickerItemMiniComponent } from './ticker-item-mini';

@NgModule({
  declarations: [TickerComponent, TickerItemComponent, TickerItemMiniComponent],
  imports: [CommonModule, IconModule, CursorActionModule, SvgDrawStrokeModule],
  exports: [TickerComponent, TickerItemComponent],
})
export class TickerModule {}
