import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  PlatformService,
  PlatformType,
  ResizeService,
  SmoothScrollService,
} from '@beta/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap, Linear, Power2 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, pairwise, skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TickerComponent implements AfterViewInit {
  @ViewChild('ticker') ticker!: ElementRef<HTMLElement>;
  @ViewChild('listOne') listOne!: ElementRef<HTMLElement>;
  @ViewChildren('item', { read: ElementRef }) item = new QueryList<
    ElementRef<HTMLElement>
  >();

  @ViewChild('listTwo') listTwo!: ElementRef<HTMLElement>;
  @ViewChildren('miniItem', { read: ElementRef }) miniItem = new QueryList<
    ElementRef<HTMLElement>
  >();

  @ViewChild('image') image!: ElementRef<HTMLElement>;
  @ViewChild('video') video!: ElementRef<HTMLVideoElement>;
  @ViewChild('imageLine') imageLine!: ElementRef<HTMLElement>;

  isShowed = true;
  tlTicker = gsap.timeline({
    repeat: -1,
    onReverseComplete: () => {
      if (this.isVisible$.value) {
        this.tlTicker.progress(1).reversed(true);
      }
    },
  });

  tlImage = gsap.timeline();
  itemQuantity = Array.from({ length: 8 }, (a, b) => b + 1);
  activeItems: number[] = [];

  miniItemQuantity = Array.from({ length: 14 }, (a, b) => b + 1);
  activeMiniItems: number[] = [];

  imageLineHover = false;

  isVisible$ = new BehaviorSubject<boolean>(false);

  constructor(
    private cd: ChangeDetectorRef,
    private resizeService: ResizeService,
    private smoothScrollService: SmoothScrollService,
    private platformService: PlatformService,
  ) {}

  ngAfterViewInit(): void {
    gsap.set(this.image.nativeElement, {
      scale: 0,
    });
    setTimeout(() => {
      this.playVideo();
      this.initTickerAnimation();
    }, 1000);
    this.onLeaveTrigger();
    this.onResize();
    this.speedAnimation();
  }

  speedAnimation(): void {
    const platformType = this.platformService.bowser.getPlatform().type;
    combineLatest([
      this.isVisible$.asObservable(),
      this.smoothScrollService.scrollDown$.pipe(distinctUntilChanged()),
      this.smoothScrollService.scrollSpeed$,
    ])
      .pipe(
        filter(() => platformType === PlatformType.Desktop),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filter(([isVisible, scrollDown, scrollSpeed]) => isVisible),
        untilDestroyed(this),
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe(([isVisible, scrollDown, scrollSpeed]) => {
        this.tlTicker.timeScale(1 + scrollSpeed * 3).reversed(!scrollDown);
      });
  }

  imageAnimation(hover: boolean): void {
    if (
      (!this.isShowed || this.resizeService.isMobile) &&
      this.imageLineHover !== hover
    ) {
      return;
    }
    this.imageLineHover = hover;
    if (hover) {
      gsap.to(this.image.nativeElement, {
        scale: 1,
        direction: 1,
        ease: Power2.easeInOut,
      });
    } else {
      gsap.to(this.image.nativeElement, {
        scale: 0,
        direction: 1,
        ease: Power2.easeInOut,
      });
    }
  }

  imageMoveAnimation(event: MouseEvent): void {
    const x = event.clientX - this.imageLine.nativeElement.offsetWidth / 2;
    const y =
      event.clientY -
      this.imageLine.nativeElement.offsetHeight / 2 -
      this.imageLine.nativeElement.getBoundingClientRect().top;

    const degrees = (5 * x) / (this.imageLine.nativeElement.offsetWidth / 2);
    gsap.to(this.image.nativeElement, {
      x: x * 0.6,
      y: y * 0.6,
      xPercent: -50,
      yPercent: -50,
      rotation: degrees,
      direction: 2,
      ease: Power2.easeOut,
    });
  }

  playVideo(): void {
    this.video.nativeElement.muted = true;
    this.video.nativeElement.play();
  }

  initTickerAnimation(): void {
    const itemWidth = this.item
      .map((item) => item.nativeElement)[0]
      .getBoundingClientRect().width;
    const miniItemWidth = this.miniItem
      .map((item) => item.nativeElement)[0]
      .getBoundingClientRect().width;

    this.tlTicker
      .clear()
      .fromTo(
        this.listOne.nativeElement,
        {
          x: 0,
        },
        {
          x: -itemWidth * 4,
          duration: 14,
          ease: Linear.easeNone,
        },
        0,
      )
      .fromTo(
        this.listTwo.nativeElement,
        {
          x: 0,
        },
        {
          x: -miniItemWidth * 7,
          duration: 14,
          ease: Linear.easeNone,
        },
        0,
      )
      .pause();
  }

  setActiveItems(itemIndex: number | undefined): void {
    if (itemIndex) {
      if (itemIndex <= this.itemQuantity.length / 2) {
        this.activeItems = [
          itemIndex,
          itemIndex + this.itemQuantity.length / 2,
        ];
      } else {
        this.activeItems = [
          itemIndex - this.itemQuantity.length / 2,
          itemIndex,
        ];
      }
    } else {
      this.activeItems = [];
    }
    this.cd.markForCheck();
  }

  setActiveMiniItems(itemIndex: number | undefined): void {
    if (itemIndex) {
      if (itemIndex <= this.miniItemQuantity.length / 2) {
        this.activeMiniItems = [
          itemIndex,
          itemIndex + this.miniItemQuantity.length / 2,
        ];
      } else {
        this.activeMiniItems = [
          itemIndex - this.miniItemQuantity.length / 2,
          itemIndex,
        ];
      }
    } else {
      this.activeMiniItems = [];
    }
    this.cd.markForCheck();
  }

  onLeaveTrigger(): void {
    ScrollTrigger.create({
      trigger: this.ticker.nativeElement,
      start: '0 100%',
      end: '100% 0',
      invalidateOnRefresh: true,
      onEnter: () => {
        this.tlTicker.resume();
        this.video.nativeElement.play();
        this.isVisible$.next(true);
      },
      onLeave: () => {
        this.tlTicker.pause();
        this.video.nativeElement.pause();
        this.isVisible$.next(false);
      },
      onEnterBack: () => {
        this.tlTicker.resume();
        this.video.nativeElement.play();
        this.isVisible$.next(true);
      },
      onLeaveBack: () => {
        this.tlTicker.pause();
        this.video.nativeElement.pause();
        this.isVisible$.next(false);
      },
    });
  }

  onResize(): void {
    this.resizeService.currentResolution$
      .pipe(skip(1), pairwise(), untilDestroyed(this))
      .subscribe(([prevRes, currentRes]) => {
        if (prevRes.width !== currentRes.width) {
          this.initTickerAnimation();
        }

        if (this.isVisible$.value) {
          this.tlTicker.resume();
        }
      });
  }
}
