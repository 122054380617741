import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SmoothScrollbar } from '@beta/smooth-scrollbar';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { BehaviorSubject, fromEvent, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  pairwise,
  skip,
  switchMap,
} from 'rxjs/operators';

import { PlatformService, PlatformType } from '../platform';
import { ResizeService } from '../resize';

import { EdgeEasingPlugin, SmoothScroll } from './smooth-scroll.common';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SmoothScrollService {
  public scrollbar: SmoothScrollbar | undefined;
  private _smoothScroll = new BehaviorSubject<SmoothScroll | undefined>(
    undefined,
  );

  private _scrollSpeed = new BehaviorSubject<number>(0);

  private _scrollDown = new BehaviorSubject<boolean>(true);

  private _scrollDisable = new BehaviorSubject<boolean>(false);

  constructor(
    private resizeService: ResizeService,
    private platformService: PlatformService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
  ) {}

  set scrollDisable(value: boolean) {
    this._scrollDisable.next(value);
  }

  get scrollDisable(): boolean {
    return this._scrollDisable.value;
  }

  get scrollDisable$(): Observable<boolean> {
    return this._scrollDisable.asObservable();
  }

  set smoothScroll(value: SmoothScroll | undefined) {
    this._smoothScroll.next(value);
  }

  get smoothScroll(): SmoothScroll | undefined {
    return this._smoothScroll.value;
  }

  get smoothScroll$(): Observable<SmoothScroll | undefined> {
    return this._smoothScroll.asObservable();
  }

  get scrollSpeed(): number {
    return this._scrollSpeed.value;
  }

  set scrollSpeed(value: number) {
    if (Number.isFinite(value)) {
      this._scrollSpeed.next(value);
    } else {
      this._scrollSpeed.next(0);
    }
  }

  get scrollSpeed$(): Observable<number> {
    return this._scrollSpeed.asObservable();
  }

  get scrollDown(): boolean {
    return this._scrollDown.value;
  }

  set scrollDown(value: boolean) {
    this._scrollDown.next(value);
  }

  get scrollDown$(): Observable<boolean> {
    return this._scrollDown.asObservable();
  }

  public init(scrollContainer: HTMLElement, scrollContent: HTMLElement): void {
    if (
      this.platformService.bowser.getPlatform().type === PlatformType.Desktop
    ) {
      SmoothScrollbar.use(EdgeEasingPlugin);
      this.scrollbar = SmoothScrollbar.init(scrollContainer, {
        scrollContentEl: scrollContent,
      });
      SmoothScrollbar.detachStyle();
      ScrollTrigger.defaults({
        scroller: scrollContainer,
      });

      this.scrollbar.addListener((status) => {
        this.window.requestAnimationFrame(() => {
          this.smoothScroll = {
            offsetY: status.offset.y,
            time: new Date().getTime(),
          };
        });
      });
    } else {
      fromEvent<UIEvent>(this.window, 'scroll')
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.window.requestAnimationFrame(() => {
            this.smoothScroll = {
              offsetY: this.window.scrollY,
              time: new Date().getTime(),
            };
          });
        });
    }

    this.smoothScroll$
      .pipe(
        filter((value): value is SmoothScroll => !!value),
        pairwise(),
        switchMap(([prev, current]: [SmoothScroll, SmoothScroll]) => {
          this.window.requestAnimationFrame(() => {
            const s = current.offsetY - prev.offsetY;

            const t = current.time - prev.time;
            this.scrollSpeed = Math.abs(s / t);
            this.scrollDown = s > 0;
          });

          return of([prev, current]);
        }),
        debounceTime(40),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.window.requestAnimationFrame(() => {
          this.scrollSpeed = 0;
        });
      });

    this.onResize();
  }

  onResize(): void {
    this.resizeService.currentResolution$
      .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        ScrollTrigger.refresh();
        this.window.requestAnimationFrame(() => {
          if (this.scrollbar) {
            this.scrollbar.update();
          }
        });
      });
  }
}
