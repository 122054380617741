import { Service } from '@beta/api';
import { Action, createReducer, on } from '@ngrx/store';

import {
  loadServices,
  loadServicesFailure,
  loadServicesSuccess,
} from './services.actions';

export const SERVICES_FEATURE_KEY = 'services';

export interface ServicesState {
  services: Service[] | undefined;
  loading: boolean;
  error: string | null;
}

export interface ServicesPartialState {
  readonly [SERVICES_FEATURE_KEY]: ServicesState;
}

export const initialServicesState: ServicesState = {
  services: undefined,
  loading: false,
  error: null,
};

const servicesReducer = createReducer(
  initialServicesState,
  on(loadServices, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadServicesSuccess, (state, { services }) => ({
    ...state,
    services,
    loading: false,
  })),
  on(loadServicesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: ServicesState | undefined, action: Action) =>
  servicesReducer(state, action);
