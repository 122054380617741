import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ResizeService } from '@beta/core';
import { IconName, IconSize } from '@beta/ui-common';
import { SvgDrawStrokeDirective } from '@ng-blue-duct-tape/svg-draw-stroke';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap, Power2 } from 'gsap';
import { distinctUntilChanged, skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-ticker-item-mini, [app-ticker-item-mini]',
  templateUrl: './ticker-item-mini.component.html',
  styleUrls: ['./ticker-item-mini.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TickerItemMiniComponent implements AfterViewInit, OnChanges {
  @Input() active = false;
  @ViewChild('item') item!: ElementRef<HTMLElement>;
  @ViewChild('line', { read: SvgDrawStrokeDirective })
  line!: SvgDrawStrokeDirective;

  iconName = IconName;
  iconSize = IconSize;

  tlItem = gsap.timeline();
  isShowed = true;

  constructor(private resizeService: ResizeService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resizeService.isMobile) {
      return;
    }
    this.tlItem.reversed(!changes?.active.currentValue).resume();
  }

  ngAfterViewInit(): void {
    this.initItemAnimation();
    this.onResize();
  }

  initItemAnimation(): void {
    this.tlItem
      .clear()
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: 0.8,
          ease: Power2.easeIn,
        },
        0,
      )
      .fromTo(
        this.line.stokeElements[1].element,
        {
          strokeDashoffset: this.line.stokeElements[1].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: 0.3,
          ease: Power2.easeOut,
        },
      )
      .pause();
  }

  onResize(): void {
    this.resizeService.currentResolution$
      .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        if (this.resizeService.isMobile) {
          this.tlItem.reversed(false).progress(0).pause();
        }
      });
  }
}
