<div class="ticker-item__separator">
  <div class="ticker-item__line">
    <svg
      #line
      bdtSvgDrawStroke
      width="97"
      height="64"
      viewBox="0 0 97 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.002 62c4.92-72.836 19.114-54.913 26.21-48.584C73.623 56.959 87.586 23.992 88.902 2.02"
        stroke="#EB4626"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="m81.098 10.11 7.804-8.09 6.085 8.22"
        stroke="#EB4626"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="ticker-item__separator-icon"></div>
</div>
<div class="ticker-item" #item appCursorAction>
  <span class="subtitle subtitle_large ticker-item__text">Стремление</span>
</div>
