<div class="ticker" #ticker>
  <div class="container">
    <div class="ticker__border"></div>
  </div>
  <div class="ticker__inner">
    <div class="ticker__line ticker__line_1">
      <ul
        class="list ticker-list"
        #listOne
        (mouseleave)="setActiveItems(undefined)"
        appCursorAction
      >
        <li
          class="ticker-list__item"
          (mouseover)="setActiveItems(itemIndex)"
          #item
          *ngFor="let itemIndex of itemQuantity"
          app-ticker-item
          [active]="activeItems.includes(itemIndex)"
        ></li>
      </ul>
    </div>
  </div>
  <div class="container">
    <div class="ticker__border"></div>
  </div>
  <div class="ticker__inner">
    <div
      class="ticker__line ticker__line_2"
      (mousemove)="imageMoveAnimation($event)"
      (mouseover)="imageAnimation(true)"
      (mouseleave)="imageAnimation(false)"
      #imageLine
    >
      <div class="ticker__image" #image>
        <video
          #video
          src="/assets/videos/line-robots.mp4"
          autoplay
          muted
          loop
          class="ticker__image-inner"
        ></video>
      </div>
      <ul
        class="list ticker-list"
        #listTwo
        (mouseleave)="setActiveMiniItems(undefined)"
        appCursorAction
      >
        <li
          class="ticker-list__item"
          (mouseover)="setActiveMiniItems(itemIndex)"
          #miniItem
          *ngFor="let itemIndex of miniItemQuantity"
          app-ticker-item-mini
          [active]="activeMiniItems.includes(itemIndex)"
        ></li>
      </ul>
    </div>
  </div>
  <div class="container">
    <div class="ticker__border"></div>
  </div>
</div>
