import { createFeatureSelector, createSelector } from '@ngrx/store';

import { META_FEATURE_KEY, MetaState } from './meta.reducer';

export const getMetaState = createFeatureSelector<MetaState>(META_FEATURE_KEY);

export const getMeta = createSelector(getMetaState, (state) => state.meta);

export const getMetaLoading = createSelector(
  getMetaState,
  (state) => state.loading,
);

export const getMetaError = createSelector(
  getMetaState,
  (state) => state.error,
);
