import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ServicesFacade } from '@beta/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, mapTo, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ServicesResolver implements Resolve<boolean> {
  constructor(private servicesFacade: ServicesFacade) {}

  resolve(): Observable<boolean> {
    return this.servicesFacade.servicesState$.pipe(
      tap((servicesState) => {
        if (
          !servicesState.services &&
          !servicesState.loading &&
          !servicesState.error
        ) {
          this.servicesFacade.getServices();
        }
      }),
      filter(
        (servicesState) =>
          !servicesState.loading &&
          !servicesState.error &&
          !!servicesState.services,
      ),
      take(1),
      mapTo(true),
      untilDestroyed(this),
    );
  }
}
