import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CursorActionModule,
  ParallaxModule,
  SplitTextModule,
} from '@beta/shared';
import {
  ButtonModule,
  IconModule,
  LinkModule,
  NoiseModule,
} from '@beta/ui-common';
import { SvgDrawStrokeModule } from '@ng-blue-duct-tape/svg-draw-stroke';

import { ContactsComponent } from './contacts.component';

@NgModule({
  declarations: [ContactsComponent],
  imports: [
    NoiseModule,
    CommonModule,
    CursorActionModule,
    SplitTextModule,
    IconModule,
    ButtonModule,
    SvgDrawStrokeModule,
    LinkModule,
    ParallaxModule,
  ],
  exports: [ContactsComponent],
})
export class ContactsModule {}
