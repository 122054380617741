<div class="menu__wrap" [class.menu_active]="menuIsActive$ | async">
  <div class="menu__button" (click)="menuActive()" (tap)="menuActive()">
    <div class="menu__button-stick"></div>
    <div class="menu__button-stick"></div>
  </div>
  <div class="menu" [style.height.px]="viewportHeight$ | async">
    <div class="container menu__container">
      <div class="menu__inner">
        <div class="menu__navigation">
          <div class="cell"></div>
          <div class="cell cell_space-5 menu__navigation-inner">
            <!--          <div class="menu__item">-->
            <!--            <a class="menu-item menu__link">Главная</a>-->
            <!--          </div>-->
            <div class="menu__item">
              <a class="menu-item menu__link" (click)="scrollTo('projects')"
                >Проекты</a
              >
            </div>
            <div class="menu__item">
              <a class="menu-item menu__link" (click)="scrollTo('services')"
                >Услуги</a
              >
            </div>
            <!--          <div class="menu__item">-->
            <!--            <a class="menu-item menu__link">О нас</a>-->
            <!--          </div>-->
            <!--          <div class="menu__item">-->
            <!--            <a class="menu-item menu__link">Вакансии</a>-->
            <!--          </div>-->
            <div class="menu__item">
              <a class="menu-item menu__link" (click)="scrollTo('contacts')"
                >Контакты</a
              >
            </div>
          </div>
        </div>
        <div class="menu__footer">
          <div class="cell"></div>
          <div class="cell cell_space-5 menu__footer-inner">
            <ul class="list menu-socials">
              <li class="menu-socials__item" *ngFor="let link of socialLinks">
                <app-link
                  #linkEl
                  [href]="link.url"
                  [title]="link.title"
                  [iconName]="iconName.SquareArrow"
                  [reverse]="true"
                  [blank]="true"
                ></app-link>
              </li>
            </ul>
            <ul class="list menu__contacts">
              <li class="menu__contacts-item">
                <app-link
                  [title]="'+7 (499) 704 63 34'"
                  [href]="'tel:+74997046334'"
                  [reverse]="true"
                ></app-link>
              </li>
              <li class="menu__contacts-item">
                <app-link
                  [title]="'hello@betaagency.ru'"
                  [href]="'mailto:hello@betaagency.ru'"
                ></app-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="menu__logo" (click)="scrollTo('top')"></div>
  </div>
</div>
