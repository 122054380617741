<div #servicesWrap class="services">
  <div class="container">
    <div class="services__inner">
      <div class="services__header">
        <div class="cell"></div>
        <div class="cell cell_space-5 services__title-cell">
          <h2
            class="services__title"
            (mouseover)="titleLineAnimation(true)"
            (mouseleave)="titleLineAnimation(false)"
          >
            <span
              class="services__title-inner"
              #title
              appSplitText
              [maskPadding]="2.3"
              [maskPaddingUnits]="'rem'"
              >Что мы</span
            >
            <span class="services__title-line">
              <svg
                bdtSvgDrawStroke
                #line
                width="401"
                height="360"
                viewBox="0 0 401 360"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M274.658 2C250.072 3.428 163.674 33.013 14.78 139.93c-16.01 10.566-29.847 27.586 42.884 11.137 72.732-16.448 122.363.571 138.087 11.138 22.872 11.708 53.691 52.773-6.004 123.366-55.782 65.966-127.795 76.818-154.383 71.106-23.157-7.995-54.205-36.667 6.862-87.384 76.334-63.396 209.275-62.54 215.279-62.54h20.584c2.859 0 13.913-.747 19.727-12.85 7.364-15.332-17.154-25.701-27.446 24.844-8.233 40.43 82.308-.272 128.63-25.689"
                  stroke="#31B5FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </h2>
        </div>
        <div class="cell cell_space-2 services__description-cell">
          <p class="text text_caps services__description">
            <span class="services__description-inner" #description appSplitText>
              Мы не занимаемся: SMM <br />
              и рекламкой; продвижением <br />
              сайтов; проектами, которые <br />
              обманывают людей
            </span>
            <span
              class="services__description-icon"
              #icon
              app-icon
              [iconName]="iconName.Cancel"
              [iconSize]="iconSize.M"
            ></span>
          </p>
        </div>
      </div>
      <div class="services__header services__header_2">
        <div class="cell cell_space-3"></div>
        <div class="cell cell_space-5 services__title-cell">
          <h2
            class="services__title"
            (mouseover)="titleLineAnimation(true)"
            (mouseleave)="titleLineAnimation(false)"
          >
            <span
              class="services__title-inner"
              #title
              appSplitText
              [maskPadding]="2.3"
              [maskPaddingUnits]="'rem'"
              >Делаем</span
            >
          </h2>
        </div>
      </div>
      <div class="services__main-description-wrap">
        <div class="cell cell_space-3"></div>
        <div class="cell cell_space-5">
          <p
            class="title services__main-description"
            #mainDescription
            appSplitText
            [maskPadding]="1.5"
            [maskPaddingUnits]="'rem'"
          >
            Придумываем, проектируем, рисуем и разрабатываем цифровые продукты
            от концепции до релиза
          </p>
          <span
            class="text text_caps services__main-description-mini"
            #mainDescriptionMini
            appSplitText
            >агентство, студия, команда</span
          >
        </div>
      </div>
      <div class="services__subtitle-wrap">
        <div class="cell cell_space-2"></div>
        <div class="cell cell_space-4">
          <p class="text text_caps services__subtitle">
            <span class="services__subtitle-part" appSplitText #subtitle
              >Придумать,</span
            >
            <br />
            <span class="services__subtitle-part" appSplitText #subtitle
              >Спроектировать</span
            >
            <br />
            <span class="services__subtitle-part" appSplitText #subtitle
              >Разработать</span
            >
          </p>
        </div>
        <div class="cell cell_space-2 services__subtitle-description-cell">
          <p class="text text_caps services__subtitle-description">
            <span
              class="services__subtitle-description-part"
              appSplitText
              #subtitleDescription
              >Направления деятельности</span
            >
            <span
              class="services__subtitle-description-icon"
              #subtitleDescriptionIcon
              app-icon
              [iconName]="iconName.LookDown"
              [iconSize]="iconSize.M"
            ></span>
          </p>
        </div>
      </div>
      <ul class="list services-list" #list>
        <li
          class="services-list__item"
          #item
          app-services-item
          *ngFor="let service of services; let i = index; let last = last"
          [index]="i"
          [active]="i === currentServiceIndex"
          [activeNext]="
            currentServiceIndex !== undefined && i === currentServiceIndex + 1
          "
          [last]="last"
          [service]="service"
          (selectedIndex)="currentServiceIndex$.next($event)"
        ></li>
      </ul>
      <div class="services-list__button">
        <div class="cell cell_space-3"></div>
        <div class="cell cell_space-3">
          <!--          TODO: вернуть название кнопки-->
          <app-button
            #button
            (click)="scrollTo()"
            [buttonTitle]="'Наши контакты'"
            [iconSize]="iconSize.S"
            [iconName]="iconName.ButtonArrowRight"
            [lineColor]="buttonLineColor.Blue"
            [lineType]="buttonLineType.One"
          ></app-button>
        </div>
        <div class="cell cell_space-2 cell_reverse">
          <span class="text text_default" appSplitText #footerText
            >Работаем с 2012 года</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
