import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CursorActionModule, SplitTextModule } from '@beta/shared';

import { IconModule } from '../icon';

import { LinkComponent } from './link.component';

@NgModule({
  declarations: [LinkComponent],
  imports: [CommonModule, IconModule, CursorActionModule, SplitTextModule],
  exports: [LinkComponent],
})
export class LinkModule {}
