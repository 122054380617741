import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VacanciesEffects } from './vacancies.effects';
import { VacanciesFacade } from './vacancies.facade';
import {
  initialVacanciesState,
  reducer,
  VACANCIES_FEATURE_KEY,
} from './vacancies.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(VACANCIES_FEATURE_KEY, reducer, {
      initialState: initialVacanciesState,
    }),
    EffectsModule.forFeature([VacanciesEffects]),
  ],
  providers: [VacanciesFacade],
})
export class VacanciesModule {}
