import { Inject, Injectable } from '@angular/core';
import { USER_AGENT } from '@ng-web-apis/common';
import Bowser, { Parser } from 'bowser';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  private _bowser = new BehaviorSubject<Parser.Parser>(
    Bowser.getParser(this.userAgent),
  );

  constructor(@Inject(USER_AGENT) private userAgent: string) {}

  get bowser(): Parser.Parser {
    return this._bowser.value;
  }

  get bowser$(): Observable<Parser.Parser> {
    return this._bowser.asObservable();
  }
}
