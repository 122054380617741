import { Injectable } from '@angular/core';
import { MetaService } from '@beta/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  loadMetaFailure,
  loadMetaSuccess,
  MetaActionType,
} from './meta.actions';

@UntilDestroy()
@Injectable()
export class MetaEffects {
  loadMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetaActionType.LOAD_META),
      switchMap(() =>
        this.metaService.get().pipe(
          // tap((meta) => {
          //   console.log(meta, 'meta');
          // }),
          map((meta) => loadMetaSuccess({ meta })),
          catchError((error) =>
            of(loadMetaFailure({ error: error.message || 'Server Error' })),
          ),
        ),
      ),
      untilDestroyed(this),
    ),
  );

  constructor(private actions$: Actions, private metaService: MetaService) {}
}
