import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NoiseComponent } from './noise.component';

@NgModule({
  declarations: [NoiseComponent],
  imports: [CommonModule],
  exports: [NoiseComponent],
})
export class NoiseModule {}
