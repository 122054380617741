import { AirtableImage, AirtableRecord } from '../api.common';

export class HeaderImage {
  public image!: AirtableImage;

  deserialize(record: AirtableRecord): HeaderImage {
    if (record.fields) {
      if (record.fields['image'] && record.fields['image']?.length) {
        this.image = new AirtableImage().deserialize(record.fields['image'][0]);
        const image = new Image();
        image.src = this.image.src;
        image.decode();
      }
    }
    return this;
  }
}
