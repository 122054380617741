export enum IconName {
  LinkArrow = 'link-arrow',
  ArrowRightV = 'arrow-right-v',
  ArrowDownV = 'arrow-down-v',
  ArrowUpV = 'arrow-up-v',
  ButtonArrowRight = 'button-arrow-right',
  Play = 'play',
  Like = 'like',
  ArrowRight = 'arrow-right',
  Cancel = 'cancel',
  LookDown = 'look-down',
  Star = 'star',
  Star2 = 'star2',
  MapPin = 'map-pin',
  SquareArrow = 'square-arrow',
  Search = 'search',
}

export enum IconSize {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}

export interface IconModel {
  name: IconName;
  icons: {
    [key in IconSize]?: string;
  };
}

export const icons: IconModel[] = [
  {
    name: IconName.Search,
    icons: {
      [IconSize.M]:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.333 12.667A5.333 5.333 0 1 0 7.333 2a5.333 5.333 0 0 0 0 10.667ZM14 14l-2.9-2.9" stroke="#fff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.Star2,
    icons: {
      [IconSize.M]:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8 1.333 2.06 4.174 4.606.673-3.333 3.247.787 4.586L8 11.847l-4.12 2.166.786-4.586L1.333 6.18l4.607-.673L8 1.333Z" stroke="#fff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.SquareArrow,
    icons: {
      [IconSize.XS]:
        '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1.875 8.125 6.25-6.25M1.875 1.875h6.25v6.25" stroke="#000" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.MapPin,
    icons: {
      [IconSize.M]:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.25 6.833c0 4.084-5.25 7.584-5.25 7.584s-5.25-3.5-5.25-7.584a5.25 5.25 0 1110.5 0z" stroke="#000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 8.583a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z" stroke="#000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.Star,
    icons: {
      [IconSize.L]:
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.667 7.722L12 0l-1.667 7.722-6.165-4.827 3.61 7.003L0 10.225l7.2 3.008-5.753 5.327 7.42-2.396L7.832 24 12 17.322 16.168 24l-1.035-7.836 7.42 2.396-5.753-5.327 7.2-3.008-7.779-.327 3.611-7.003-6.165 4.827zm-1.111 3.1L12 8.25l-.556 2.574-2.055-1.61 1.204 2.335L8 11.657l2.4 1.003-1.918 1.775 2.474-.798-.345 2.612L12 14.023l1.39 2.226-.346-2.612 2.473.798L13.6 12.66l2.4-1.003-2.593-.109 1.204-2.334-2.055 1.609z" fill="#000"/></svg>',
    },
  },
  {
    name: IconName.LookDown,
    icons: {
      [IconSize.M]:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.17 9.75l-2.917 2.917L3.336 9.75" stroke="#000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.667 3.333H8.583A2.333 2.333 0 006.25 5.668v7" stroke="#000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.Cancel,
    icons: {
      [IconSize.M]:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.757 3.757a6 6 0 118.486 8.486 6 6 0 01-8.486-8.486v0zM4 4l8 8" stroke="#000" stroke-width="1.2" stroke-linecap="round"/></svg>',
    },
  },
  {
    name: IconName.ArrowRight,
    icons: {
      [IconSize.XL]:
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.037 20h25.926M20 7.037L32.963 20 20 32.963" stroke="#000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.Like,
    icons: {
      [IconSize.M]:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.156 3.69a3.21 3.21 0 00-4.538 0L8 4.307l-.619-.619a3.209 3.209 0 00-4.538 4.538l.619.619L8 13.384l4.538-4.538.618-.619a3.207 3.207 0 000-4.538v0z" stroke="#000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.Play,
    icons: {
      [IconSize.L]:
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.718 4.353C6.217 3.525 5 4.203 5 5.866v12.267c0 1.664 1.217 2.341 2.718 1.515l11.156-6.15c1.501-.827 1.501-2.168 0-2.996L7.718 4.352z" fill="#000"/></svg>',
    },
  },
  {
    name: IconName.LinkArrow,
    icons: {
      [IconSize.XS]:
        '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.876 8.125l6.25-6.25M1.876 1.875h6.25v6.25" stroke="#000000" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.ArrowRightV,
    icons: {
      [IconSize.XS]:
        '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 7.813l3-3-3-3" stroke="#000000" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      [IconSize.S]:
        '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 11l5-5-5-5" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.ArrowDownV,
    icons: {
      [IconSize.M]:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 7l4 4 4-4" stroke="#000" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.ArrowUpV,
    icons: {
      [IconSize.M]:
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 9 8 5 4 9" stroke="#000" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
  {
    name: IconName.ButtonArrowRight,
    icons: {
      [IconSize.S]:
        '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 6h8M7 2.5L10.5 6 7 9.5" stroke="#000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  },
];
