import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Meta } from '@beta/api';
import { MetaFacade } from '@beta/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { LangService } from '../lang';
import { LogoNavigation, LogoService, LogoType } from '../logo';
import { MetaDataService } from '../meta-data';
import { NavigationService } from '../navigation';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private _preloaderIsHidden = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private logoService: LogoService,
    private navigationService: NavigationService,
    private metaFacade: MetaFacade,
    private title: Title,
    private langService: LangService,
    private metaDataService: MetaDataService,
  ) {
    combineLatest([
      this.router.events.pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      ),
      this.metaFacade.meta$.pipe(filter((meta): meta is Meta[] => !!meta)),
    ])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(([event, meta]) => {
        if (event.urlAfterRedirects === '/') {
          this.logoService.logoType = LogoType.Default;
          this.navigationService.eventIsVisible = true;
          this.logoService.logoLine = true;
          this.logoService.logoNavigation = LogoNavigation.Top;
        } else {
          this.logoService.logoType = LogoType.Small;
          this.navigationService.eventIsVisible = false;
          this.logoService.logoLine = false;
          this.logoService.logoNavigation = LogoNavigation.Home;
        }

        const metaData = meta.find(
          (item) => item.page === event.urlAfterRedirects,
        );

        if (metaData) {
          this.metaDataService.setMeta(metaData);
          this.title.setTitle(
            metaData.lang[this.langService.currentLang].title,
          );
        }
      });
  }

  get preloaderIsHidden(): boolean {
    return this._preloaderIsHidden.value;
  }

  set preloaderIsHidden(value: boolean) {
    this._preloaderIsHidden.next(value);
  }

  get preloaderIsHidden$(): Observable<boolean> {
    return this._preloaderIsHidden.asObservable();
  }
}
