import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { HeaderType } from '@beta/ui-layout';
import { HeaderImagesResolver } from './header-images.resolver';
import { MetaResolver } from './meta.resolver';
// import { ProjectResolver } from './project.resolver';
import { ProjectsResolver } from './projects.resolver';
import { ServicesResolver } from './services.resolver';
import { TransitionGuard } from './transition.guard';
import { VacanciesResolver } from './vacancies.resolver';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@beta/page-home').then((m) => m.PageHomeModule),
    resolve: {
      meta: MetaResolver,
      headerImages: HeaderImagesResolver,
      vacancies: VacanciesResolver,
      projects: ProjectsResolver,
      services: ServicesResolver,
    },
    canDeactivate: [TransitionGuard],
  },
  // TODO: Вернуть роутинг для страниц
  // {
  //   path: 'projects',
  //   loadChildren: () =>
  //     import('@beta/page-projects').then((m) => m.PageProjectsModule),
  //   resolve: {
  //     meta: MetaResolver,
  //     headerImages: HeaderImagesResolver,
  //     vacancies: VacanciesResolver,
  //     projects: ProjectsResolver,
  //     services: ServicesResolver,
  //   },
  //   data: {
  //     headerType: HeaderType.Projects,
  //   },
  //   canDeactivate: [TransitionGuard],
  // },
  // {
  //   path: 'projects/:project',
  //   loadChildren: () =>
  //     import('@beta/page-project').then((m) => m.PageProjectModule),
  //   resolve: {
  //     headerImages: HeaderImagesResolver,
  //     vacancies: VacanciesResolver,
  //     projects: ProjectResolver,
  //     services: ServicesResolver,
  //   },
  //   canDeactivate: [TransitionGuard],
  // },
  // {
  //   path: 'services',
  //   loadChildren: () =>
  //     import('@beta/page-services').then((m) => m.PageServicesModule),
  //   resolve: {
  //     meta: MetaResolver,
  //     headerImages: HeaderImagesResolver,
  //     vacancies: VacanciesResolver,
  //     projects: ProjectsResolver,
  //     services: ServicesResolver,
  //   },
  //   data: {
  //     headerType: HeaderType.Services,
  //   },
  //   canDeactivate: [TransitionGuard],
  // },
  // {
  //   path: 'services/:service',
  //   loadChildren: () =>
  //     import('@beta/page-project').then((m) => m.PageProjectModule),
  //   resolve: {
  //     headerImages: HeaderImagesResolver,
  //     vacancies: VacanciesResolver,
  //     projects: ProjectsResolver,
  //     services: ServicesResolver,
  //   },
  //   canDeactivate: [TransitionGuard],
  // },
  // {
  //   path: 'about',
  //   loadChildren: () =>
  //     import('@beta/page-about').then((m) => m.PageAboutModule),
  //   resolve: {
  //     meta: MetaResolver,
  //     headerImages: HeaderImagesResolver,
  //     vacancies: VacanciesResolver,
  //     projects: ProjectsResolver,
  //     services: ServicesResolver,
  //   },
  //   data: {
  //     headerType: HeaderType.About,
  //   },
  //   canDeactivate: [TransitionGuard],
  // },
  // {
  //   path: 'vacancies',
  //   loadChildren: () =>
  //     import('@beta/page-vacancies').then((m) => m.PageVacanciesModule),
  //   resolve: {
  //     meta: MetaResolver,
  //     headerImages: HeaderImagesResolver,
  //     vacancies: VacanciesResolver,
  //     projects: ProjectsResolver,
  //     services: ServicesResolver,
  //   },
  //   data: {
  //     headerType: HeaderType.Vacancies,
  //   },
  //   canDeactivate: [TransitionGuard],
  // },
  // {
  //   path: 'contacts',
  //   loadChildren: () =>
  //     import('@beta/page-contacts').then((m) => m.PageContactsModule),
  //   resolve: {
  //     meta: MetaResolver,
  //     headerImages: HeaderImagesResolver,
  //     vacancies: VacanciesResolver,
  //     projects: ProjectsResolver,
  //     services: ServicesResolver,
  //   },
  //   data: {
  //     headerType: HeaderType.Contacts,
  //   },
  //   canDeactivate: [TransitionGuard],
  // },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      // { enableTracing: true }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
