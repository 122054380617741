<nav
  class="navigation"
  [class.navigation_hidden]="isMobile$ | async"
  [ngClass]="['navigation_' + (navigationColor$ | async)]"
  #navigation
>
  <div class="container">
    <div class="navigation__inner" #navigationInner>
      <div class="cell"></div>
      <div class="cell cell_space-4"></div>
      <div class="cell navigation__links-cell">
        <div class="navigation__links-left">
          <ul class="list navigation-links">
            <li
              class="navigation-links__item"
              #linkItem
              *ngFor="let link of navigationLinks; let i = index"
              app-navigation-link
              [link]="link"
              [lineDuration]="0.5"
              [index]="i"
              [disabled]="
                currentLinkIndex !== i && currentLinkIndex !== undefined
              "
              (selectedIndex)="setCurrentIndex($event)"
            ></li>
          </ul>
        </div>
        <div class="navigation__links-right">
          <a
            href="tel:+74997046334"
            class="text text_default navigation__lang"
            appCursorAction
            >+7 (499) 704 63 34
            <!--                    <span-->
            <!--                      class="navigation__lang-icon"-->
            <!--                      app-icon-->
            <!--                      [iconName]="iconName.LinkArrow"-->
            <!--                      [iconSize]="iconSize.XS"-->
            <!--                    ></span-->
            <!--                  >-->
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>

<!--<nav class="navigation">-->
<!--  <div class="container">-->
<!--    <div class="navigation__inner">-->
<!--      <div class="cell"></div>-->
<!--      <div class="cell cell_space-7">-->
<!--        <span-->
<!--          class="text text_default navigation__slogan"-->
<!--          [class.navigation__slogan_show]="-->
<!--            (eventIsVisible$ | async) && (eventIsEnabled$ | async)-->
<!--          "-->
<!--          appSplitText-->
<!--          #slogan-->
<!--          appCursorAction-->
<!--          >Выпить с Бетой 🍺</span-->
<!--        >-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</nav>-->
