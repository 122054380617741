import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AnchorService,
  CursorActionType,
  ResizeService,
  SmoothScrollService,
} from '@beta/core';
import { VacanciesFacade } from '@beta/store';
import { SvgDrawStrokeDirective } from '@ng-blue-duct-tape/svg-draw-stroke';
import { gsap, Power2 } from 'gsap';
import clamp from 'lodash-es/clamp';
import { filter } from 'rxjs/operators';

import { LinkLineType, NavigationLink } from '../navigation.common';

@Component({
  selector: 'app-navigation-link, [app-navigation-link]',
  templateUrl: './navigation-link.component.html',
  styleUrls: ['./navigation-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationLinkComponent implements AfterViewInit {
  @Input() link!: NavigationLink;
  @Input() lineDuration = 0.8;
  @Input() index!: number;
  @Input() disabled = false;
  @Output() selectedIndex = new EventEmitter<number | undefined>();
  @ViewChild('linkLine', { read: SvgDrawStrokeDirective })
  linkLine!: SvgDrawStrokeDirective;

  @ViewChild('title') title!: ElementRef<HTMLElement>;

  cursorActionType = CursorActionType;
  linkLineType = LinkLineType;
  tlLine = gsap.timeline({});
  tlShow = gsap.timeline({});

  vacancies$ = this.vacanciesFacade.vacancies$.pipe(
    filter((vacancies) => !!vacancies?.length),
  );

  constructor(
    private vacanciesFacade: VacanciesFacade,
    private anchorService: AnchorService,
    private smoothScrollService: SmoothScrollService,
    private resizeService: ResizeService,
  ) {}

  ngAfterViewInit(): void {
    this.initLineAnimation();
    this.initShowAnimation();
  }

  scrollTo(): void {
    if (this.resizeService.isMobile) {
      return;
    }
    const anchor = this.anchorService.anchorEls.find(
      (item) => item.name === this.link.slug,
    );

    if (this.smoothScrollService.scrollbar && anchor) {
      const duration = clamp(
        (400 *
          Math.abs(
            (this.smoothScrollService.smoothScroll?.offsetY || 0) -
              anchor.el.offsetTop,
          )) /
          500,
        400,
        1500,
      );
      this.smoothScrollService.scrollbar.scrollTo(
        0,
        anchor.el.offsetTop,
        duration,
      );
    }
  }

  initLineAnimation(): void {
    this.tlLine
      .clear()
      .fromTo(
        this.linkLine.stokeElements[0].element,
        {
          strokeDashoffset: this.linkLine.stokeElements[0].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: this.lineDuration,
          ease: Power2.easeInOut,
        },
      )
      .pause();
  }

  linkAnimation(hover: boolean): void {
    this.tlLine.reversed(!hover).resume();
    if (hover) {
      this.selectedIndex.next(this.index);
    } else {
      this.selectedIndex.next(undefined);
    }
  }

  initShowAnimation(): void {
    this.tlShow.clear().from(this.title.nativeElement, {
      yPercent: 100,
      duration: 0.5,
      ease: Power2.easeOut,
    });
  }
}
