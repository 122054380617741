const VENDOR_PREFIX = ['webkit', 'moz', 'ms', 'o'];

const RE = new RegExp(`^-(?!(?:${VENDOR_PREFIX.join('|')})-)`);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function autoPrefix(styles: any) {
  const res = {};

  Object.keys(styles).forEach((prop) => {
    if (!RE.test(prop)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res[prop] = styles[prop];
      return;
    }

    const val = styles[prop];

    // eslint-disable-next-line no-param-reassign
    prop = prop.replace(/^-/, '');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    res[prop] = val;

    VENDOR_PREFIX.forEach((prefix) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res[`-${prefix}-${prop}`] = val;
    });
  });

  return res;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function setStyle(elem: HTMLElement, styles: any) {
  // eslint-disable-next-line no-param-reassign
  styles = autoPrefix(styles);

  Object.keys(styles).forEach((prop) => {
    const cssProp = prop
      .replace(/^-/, '')
      .replace(/-([a-z])/g, (_, $1) => $1.toUpperCase());
    // eslint-disable-next-line no-param-reassign,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    elem.style[cssProp] = styles[prop];
  });
}
