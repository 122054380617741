import { Injectable } from '@angular/core';
import { VacanciesService } from '@beta/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  loadVacanciesFailure,
  loadVacanciesSuccess,
  VacanciesActionType,
} from './vacancies.actions';

@UntilDestroy()
@Injectable()
export class VacanciesEffects {
  loadVacancies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VacanciesActionType.LOAD_VACANCIES),
      switchMap(() =>
        this.vacanciesService.get().pipe(
          // tap((vacancies) => {
          //   console.log(vacancies, 'vacancies');
          // }),
          map((vacancies) => loadVacanciesSuccess({ vacancies })),
          catchError((error) =>
            of(
              loadVacanciesFailure({ error: error.message || 'Server Error' }),
            ),
          ),
        ),
      ),
      untilDestroyed(this),
    ),
  );

  constructor(
    private actions$: Actions,
    private vacanciesService: VacanciesService,
  ) {}
}
