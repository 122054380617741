import { Meta } from '@beta/api';
import { Action, createReducer, on } from '@ngrx/store';

import { loadMeta, loadMetaFailure, loadMetaSuccess } from './meta.actions';

export const META_FEATURE_KEY = 'meta';

export interface MetaState {
  meta: Meta[] | undefined;
  loading: boolean;
  error: string | null;
}

export interface MetaPartialState {
  readonly [META_FEATURE_KEY]: MetaState;
}

export const initialMetaState: MetaState = {
  meta: undefined,
  loading: false,
  error: null,
};

const metaReducer = createReducer(
  initialMetaState,
  on(loadMeta, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadMetaSuccess, (state, { meta }) => ({
    ...state,
    meta,
    loading: false,
  })),
  on(loadMetaFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: MetaState | undefined, action: Action) =>
  metaReducer(state, action);
