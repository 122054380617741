import { ScrollbarPlugin } from '@beta/smooth-scrollbar';
import { Data2d } from 'smooth-scrollbar/interfaces';

export interface SmoothScroll {
  offsetY: number;
  time: number;
}

export class EdgeEasingPlugin extends ScrollbarPlugin {
  static pluginName = 'edgeEasing';
  private _remainMomentum: Data2d = {
    x: 0,
    y: 0,
  };

  transformDelta(delta: Data2d): Data2d {
    const { limit, offset } = this.scrollbar;

    const x = this._remainMomentum.x + delta.x;
    const y = this._remainMomentum.y + delta.y;

    this.scrollbar.setMomentum(
      Math.max(-offset.x, Math.min(x, limit.x - offset.x)),
      Math.max(-offset.y, Math.min(y, limit.y - offset.y)),
    );

    return { x: 0, y: 0 };
  }

  onRender(remainMomentum: Data2d): void {
    Object.assign(this._remainMomentum, remainMomentum);
  }
}
