import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  NavigationService,
  PreloaderService,
  ResizeService,
  SmoothScroll,
  SmoothScrollService,
} from '@beta/core';
import { IconName, IconSize } from '@beta/ui-common';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap } from 'gsap';
import clamp from 'lodash-es/clamp';
import { distinctUntilChanged, filter, skip, switchMap } from 'rxjs/operators';

import { navigationLinks } from './navigation.common';
import { NavigationLinkComponent } from './navigation-link';

@UntilDestroy()
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements AfterViewInit {
  // @ViewChild('slogan', { read: SplitTextDirective })
  // slogan!: SplitTextDirective;

  @ViewChild('navigation') navigation!: ElementRef<HTMLElement>;
  @ViewChild('navigationInner') navigationInner!: ElementRef<HTMLElement>;

  @ViewChildren('linkItem', { read: NavigationLinkComponent })
  linkItem = new QueryList<NavigationLinkComponent>();

  navigationLinks = navigationLinks;
  iconName = IconName;
  iconSize = IconSize;
  currentLinkIndex: number | undefined;

  tlShow = gsap.timeline({});

  triggerPosition = 0; // Позция скролла, во вермя переключения направления
  lastPosition = 0; // Позиция навигации, во вермя переключения направления
  currentPosition = 0; // Текущая позиция навигации

  navigationHeight = 0;

  public eventIsVisible$ = this.navigationService.eventIsVisible$;
  public eventIsEnabled$ = this.navigationService.eventIsEnabled$;
  public navigationColor$ = this.navigationService.navigationColor$;
  public isMobile$ = this.resizeService.isMobile$;

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(WINDOW) private window: Window,
    private smoothScrollService: SmoothScrollService,
    private resizeService: ResizeService,
    private preloaderService: PreloaderService,
    private navigationService: NavigationService,
  ) {}

  ngAfterViewInit(): void {
    this.navigationService.navigationEl = this.navigation.nativeElement;
    this.initShowAnimation();
    this.fixAnimation();

    this.getNavigationHeight();
    this.navigationAnimation();
    this.onResize();
  }

  setCurrentIndex(index: number | undefined): void {
    this.currentLinkIndex = index;
    this.cd.markForCheck();
  }

  fixAnimation(): void {
    // this.smoothScrollService.smoothScroll$
    //   .pipe(
    //     filter(
    //       (value): value is SmoothScroll =>
    //         !!value && !this.resizeService.isMobile,
    //     ),
    //     untilDestroyed(this),
    //   )
    //   .subscribe((value) => {
    //     gsap.set(this.navigation.nativeElement, {
    //       y: value.offsetY,
    //       force3D: true,
    //     });
    //   });

    this.preloaderService.preloaderIsHidden$
      .pipe(
        filter((ishHidden) => ishHidden),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.tlShow.resume();
      });
  }

  navigationAnimation(): void {
    this.smoothScrollService.scrollDown$
      .pipe(
        distinctUntilChanged(),
        switchMap(() => {
          if (this.smoothScrollService.smoothScroll) {
            this.triggerPosition =
              this.smoothScrollService.smoothScroll?.offsetY;
          }
          this.lastPosition = this.currentPosition;
          return this.smoothScrollService.smoothScroll$
            .pipe()
            .pipe(
              filter(
                (value): value is SmoothScroll =>
                  !!value && !this.resizeService.isMobile,
              ),
            );
        }),
        untilDestroyed(this),
      )
      .subscribe((value) => {
        if (this.smoothScrollService.scrollDown) {
          this.currentPosition = clamp(
            this.lastPosition - Math.abs(this.triggerPosition - value.offsetY),
            -this.navigationHeight,
            0,
          );
        } else {
          this.currentPosition = clamp(
            this.lastPosition + Math.abs(this.triggerPosition - value.offsetY),
            -this.navigationHeight,
            0,
          );
        }
        gsap.set(this.navigationInner.nativeElement, {
          y: this.currentPosition,
        });
      });
  }

  initShowAnimation(): void {
    const stagger = 0.1;
    // this.tlShow.clear().fromTo(
    //   this.slogan.wordEls,
    //   {
    //     yPercent: 105,
    //   },
    //   {
    //     yPercent: 0,
    //     duration: 0.5,
    //     ease: Power2.easeOut,
    //   },
    // );
    this.linkItem.toArray().forEach((component, index) => {
      this.tlShow.add(component.tlShow, index * stagger).pause();
    });
  }

  getNavigationHeight(): void {
    this.navigationHeight = this.navigation.nativeElement.offsetHeight;
  }

  onResize(): void {
    this.resizeService.currentResolution$
      .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.getNavigationHeight();
      });
  }
}
