export enum NodeType {
  ELEMENT_NODE = 1,
  ATTRIBUTE_NODE = 2,
  TEXT_NODE = 3,
  CDATA_SECTION_NODE = 4,
  ENTITY_REFERENCE_NODE = 5,
  ENTITY_NODE = 6,
  PROCESSING_INSTRUCTION_NODE = 7,
  COMMENT_NODE = 8,
  DOCUMENT_NODE = 9,
  DOCUMENT_TYPE_NODE = 10,
  DOCUMENT_FRAGMENT_NODE = 11,
  NOTATION_NODE = 12,
}

export enum SplitAction {
  Chars = 'chars',
  Words = 'words',
}

export enum Units {
  Px = 'px',
  Rem = 'rem',
  Em = 'em',
  Percent = '%',
}

export const tagTypes = [
  NodeType.ELEMENT_NODE,
  NodeType.DOCUMENT_NODE,
  NodeType.DOCUMENT_FRAGMENT_NODE,
];

export const textTypes = [NodeType.TEXT_NODE, NodeType.CDATA_SECTION_NODE];
