import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Lang, Meta as PageMeta } from '@beta/api';

import { LangService } from '../lang';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  constructor(private meta: Meta, private langService: LangService) {}

  setMeta(metaData: PageMeta): void {
    this.meta.addTags([
      {
        property: 'og:title',
        content: metaData.lang[this.langService.currentLang].title,
      },
      {
        property: 'og:description',
        content: metaData.lang[this.langService.currentLang].description,
      },
      {
        property: 'og:url',
        content: metaData.url,
      },
      {
        property: 'og:image',
        content: metaData.shareImage.src,
      },
      {
        property: 'og:image:secure_url',
        content: metaData.shareImage.src,
      },
      {
        property: 'og:site_name',
        content: this.langService.currentLang === Lang.Ru ? 'бета' : 'beta',
      },
      {
        name: 'description',
        content: metaData.lang[this.langService.currentLang].description,
      },
      {
        name: 'keywords',
        content: metaData.lang[this.langService.currentLang].keywords,
      },
    ]);
  }
}
