import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ResizeService } from '@beta/core';
import { SplitTextDirective } from '@beta/shared';
import { SvgDrawStrokeDirective } from '@ng-blue-duct-tape/svg-draw-stroke';
import { gsap, Power2, Power3 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { IconName, IconSize } from '../icon';

import { ButtonLineColor, ButtonLineType } from './button.common';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements AfterViewInit {
  @Input() buttonTitle = 'Button Name';
  @Input() iconName: IconName = IconName.ButtonArrowRight;
  @Input() iconSize: IconSize = IconSize.S;
  @Input() lineBackDuration = 0.6;
  @Input() lineFrontDuration = 0.6;
  @Input() lineType: ButtonLineType = ButtonLineType.One;
  @Input() lineColor: ButtonLineColor = ButtonLineColor.Red;
  @Input() reverseColor = false;
  @Input() url: string | undefined;

  @ViewChild('button') button!: ElementRef<HTMLElement>;
  @ViewChild('buttonInner') buttonInner!: ElementRef<HTMLElement>;
  @ViewChild('buttonContent') buttonContent!: ElementRef<HTMLElement>;
  @ViewChild('buttonBackLine', {
    read: SvgDrawStrokeDirective,
  })
  buttonBackLine!: SvgDrawStrokeDirective;

  @ViewChild('buttonFrontLine', {
    read: SvgDrawStrokeDirective,
  })
  buttonFrontLine!: SvgDrawStrokeDirective;

  @ViewChild('buttonText', { read: SplitTextDirective })
  buttonText!: SplitTextDirective;

  @ViewChild('buttonIcon', { read: ElementRef })
  buttonIcon!: ElementRef<HTMLElement>;

  buttonLineType = ButtonLineType;

  tlHover = gsap.timeline();
  public tlShow = gsap.timeline();
  isShowed = false;

  constructor(
    private cd: ChangeDetectorRef,
    private resizeService: ResizeService,
  ) {}

  ngAfterViewInit(): void {
    this.initShowAnimation();
    this.initLineAnimation();
    this.onLeaveTrigger();
  }

  initShowAnimation(): void {
    this.tlShow
      .clear()
      .fromTo(
        this.buttonInner.nativeElement,
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 1,
          ease: Power2.easeInOut,
        },
        0,
      )
      .fromTo(
        this.buttonText.wordEls,
        {
          yPercent: 100,
        },
        {
          yPercent: 0,
          duration: 0.7,
          stagger: 0.05,
          ease: Power2.easeInOut,
          clearProps: 'yPercent',
        },
        0.5,
      )
      .fromTo(
        this.buttonIcon.nativeElement,
        {
          opacity: 0,
          xPercent: -100,
        },
        {
          xPercent: 0,
          opacity: 1,
          duration: 0.5,
          ease: Power2.easeInOut,
        },
      )
      .add(() => {
        this.isShowed = true;
      });
  }

  initLineAnimation(): void {
    this.tlHover
      .clear()
      .fromTo(
        this.buttonBackLine.stokeElements[0].element,
        {
          strokeDashoffset: this.buttonBackLine.stokeElements[0].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: this.lineBackDuration,
          ease: Power3.easeIn,
        },
      )
      .fromTo(
        this.buttonFrontLine.stokeElements[0].element,
        {
          strokeDashoffset: this.buttonFrontLine.stokeElements[0].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: this.lineFrontDuration,
          ease: Power3.easeOut,
        },
      )
      .pause();
  }

  buttonAnimation(hover: boolean, event?: MouseEvent): void {
    if (!this.isShowed || this.resizeService.isMobile) {
      return;
    }

    this.tlHover.reversed(!hover).resume();

    if (hover && event) {
      const x = event.offsetX - this.button.nativeElement.offsetWidth / 2;
      const y = event.offsetY - this.button.nativeElement.offsetHeight / 2;

      gsap.to(this.buttonInner.nativeElement, {
        x: x * 0.25,
        y: y * 0.25,
        direction: 1,
        ease: Power2.easeOut,
      });

      gsap.to(this.buttonContent.nativeElement, {
        x: x * 0.4,
        y: y * 0.4,
        direction: 1,
        ease: Power2.easeOut,
      });
    } else {
      gsap.to(this.buttonInner.nativeElement, {
        x: 0,
        y: 0,
        direction: 0.5,
        ease: Power2.easeOut,
      });

      gsap.to(this.buttonContent.nativeElement, {
        x: 0,
        y: 0,
        direction: 0.5,
        ease: Power2.easeOut,
      });
    }
  }

  onLeaveTrigger(): void {
    ScrollTrigger.create({
      trigger: this.button.nativeElement,
      end: '100% 0',
      invalidateOnRefresh: true,
      onLeave: (trigger) => {
        if (this.isShowed) {
          this.buttonText.removeSplit();
          trigger.kill();
        }
      },
    });
  }
}
