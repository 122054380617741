<div class="header-footer">
  <div class="cell">
    <app-button-icon
      #button
      (click)="scrollBottom()"
      [iconName]="iconNameType.ArrowDownV"
      [iconSize]="iconSize.M"
      [buttonColor]="buttonIconColor.White"
      [buttonSize]="buttonIconSize.S"
    ></app-button-icon>
  </div>
  <div class="cell cell_space-5">
    <app-link
      #link
      [href]="'mailto:hello@betaagency.ru'"
      [title]="'hello@betaagency.ru'"
    ></app-link>
  </div>
  <div class="cell cell_space-2">
    <p class="text text_caps header-footer__description">
      <span
        class="header-footer__description-inner"
        #descriptionEl
        appSplitText
        [innerHTML]="description"
      >
      </span>

      <span
        class="header-footer__description-icon"
        #icon
        app-icon
        [iconName]="iconName"
        [iconSize]="iconSize.M"
      ></span>
    </p>
  </div>
</div>
