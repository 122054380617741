import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SplitTextDirective } from './split-text.directive';

@NgModule({
  declarations: [SplitTextDirective],
  imports: [CommonModule],
  exports: [SplitTextDirective],
})
export class SplitTextModule {}
