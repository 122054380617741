import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangService, PreloaderService, TransitionService } from '@beta/core';
import { ProjectsFacade, VacanciesFacade } from '@beta/store';
import { IconName } from '@beta/ui-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { headerData, HeaderType } from './header.common';
import { HeaderFooterComponent } from './header-footer';
import { HeaderTitleComponent } from './header-title';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('title', { read: HeaderTitleComponent })
  title!: HeaderTitleComponent;

  @ViewChild('footer', { read: HeaderFooterComponent })
  footer!: HeaderFooterComponent;

  @ViewChild('header') header!: ElementRef<HTMLElement>;

  headerType!: HeaderType;
  headerData = headerData;
  iconName = IconName;
  tlShow = gsap.timeline();
  isShowed = false;

  public currentLang$ = this.langService.currentLang$;
  constructor(
    private route: ActivatedRoute,
    private langService: LangService,
    private projectsFacade: ProjectsFacade,
    private vacanciesFacade: VacanciesFacade,
    private preloaderService: PreloaderService,
    private transitionService: TransitionService,
  ) {}

  ngOnInit(): void {
    this.getHeaderType();
  }

  ngAfterViewInit(): void {
    this.initShowAnimation();

    combineLatest([
      this.preloaderService.preloaderIsHidden$,
      this.transitionService.transitionOpenEnd$,
    ])
      .pipe(
        filter(([preloader, transition]) => preloader && transition),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.tlShow.resume();
      });

    this.onLeaveTrigger();
  }

  initShowAnimation(): void {
    this.tlShow
      .clear()
      .add(this.title.tlShow, 0)
      .add(this.footer.tlShow, 0.3)
      .add(() => {
        this.isShowed = true;
      })
      .pause();
  }

  getHeaderType(): void {
    this.headerType = this.route.snapshot.data['headerType'];
  }

  getCounter(): Observable<number | undefined> {
    if (this.headerType === HeaderType.Projects) {
      return this.projectsFacade.projects$.pipe(
        filter((projects) => !!projects),
        map((projects) => projects?.length || undefined),
        take(1),
        untilDestroyed(this),
      );
    }
    if (this.headerType === HeaderType.Vacancies) {
      return this.vacanciesFacade.vacancies$.pipe(
        filter((vacancies) => !!vacancies),
        map((vacancies) => vacancies?.length || undefined),
        take(1),
        untilDestroyed(this),
      );
    }
    return of(undefined);
  }

  getShowreel(): boolean {
    if (this.headerType === HeaderType.About) {
      return true;
    }
    return false;
  }

  onLeaveTrigger(): void {
    ScrollTrigger.create({
      trigger: this.header.nativeElement,
      end: '100% 0',
      invalidateOnRefresh: true,
      onLeave: (trigger) => {
        if (this.isShowed) {
          this.title.titleEl.removeSplit();
          this.title.counterEl.removeSplit();
          this.footer.descriptionEl.removeSplit();
          trigger.kill();
        }
      },
    });
  }
}
