import $debounce from 'lodash-es/debounce';

export function debounce(...options: any[]) {
  return (_proto: any, key: string, descriptor: PropertyDescriptor) => {
    const fn = descriptor.value;

    return {
      get(): any {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.hasOwnProperty(key)) {
          Object.defineProperty(this, key, {
            value: $debounce(fn, ...options),
          });
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this[key];
      },
    };
  };
}
