import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProjectsEffects } from './projects.effects';
import { ProjectsFacade } from './projects.facade';
import {
  initialProjectsState,
  PROJECTS_FEATURE_KEY,
  reducer,
} from './projects.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(PROJECTS_FEATURE_KEY, reducer, {
      initialState: initialProjectsState,
    }),
    EffectsModule.forFeature([ProjectsEffects]),
  ],
  providers: [ProjectsFacade],
})
export class ProjectsModule {}
