import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MetaFacade } from '@beta/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, mapTo, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MetaResolver implements Resolve<boolean> {
  constructor(private metaFacade: MetaFacade) {}

  resolve(): Observable<boolean> {
    return this.metaFacade.metaState$.pipe(
      tap((metaState) => {
        if (!metaState.meta && !metaState.loading && !metaState.error) {
          this.metaFacade.getMeta();
        }
      }),
      filter(
        (metaState) =>
          !metaState.loading && !metaState.error && !!metaState.meta,
      ),
      take(1),
      mapTo(true),
      untilDestroyed(this),
    );
  }
}
