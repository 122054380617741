export enum ButtonLineType {
  One = 'one',
  Two = 'two',
  Three = 'three',
  Four = 'four',
  Five = 'five',
  Six = 'six',
  Seven = 'seven',
  Eight = 'eight',
}

export enum ButtonLineColor {
  DarkRed = 'D30203',
  Red = 'EB4626',
  Orange = 'F67F1B',
  Yellow = 'FFE600',
  Blue = '31B5FF',
  Purple = '854DFF',
  Green = '009E60',
  Pink = 'FF87C8',
}
