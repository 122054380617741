// eslint-disable-next-line max-classes-per-file
import { getPosition } from './get-position';

export class Tracker {
  updateTime = Date.now();
  delta = { x: 0, y: 0 };
  velocity = { x: 0, y: 0 };
  lastPosition = { x: 0, y: 0 };

  constructor(touch: Touch) {
    this.lastPosition = getPosition(touch);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  update(touch: Touch) {
    const { velocity, updateTime, lastPosition } = this;

    const now = Date.now();
    const position = getPosition(touch);

    const delta = {
      x: -(position.x - lastPosition.x),
      y: -(position.y - lastPosition.y),
    };

    const duration = now - updateTime || 16;
    const vx = (delta.x / duration) * 16;
    const vy = (delta.y / duration) * 16;
    velocity.x = vx * 0.9 + velocity.x * 0.1;
    velocity.y = vy * 0.9 + velocity.y * 0.1;

    this.delta = delta;
    this.updateTime = now;
    this.lastPosition = position;
  }
}

export class TouchRecord {
  private _activeTouchID = 0;
  private _touchList: { [id: number]: Tracker } = {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private static get _primitiveValue() {
    return { x: 0, y: 0 };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isActive() {
    return this._activeTouchID !== undefined;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getDelta() {
    const tracker = this._getActiveTracker();

    if (!tracker) {
      return TouchRecord._primitiveValue;
    }

    return { ...tracker.delta };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getVelocity() {
    const tracker = this._getActiveTracker();

    if (!tracker) {
      return TouchRecord._primitiveValue;
    }

    return { ...tracker.velocity };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  track(evt: TouchEvent) {
    const { targetTouches } = evt;

    Array.from(targetTouches).forEach((touch) => {
      this._add(touch);
    });

    return this._touchList;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  update(evt: TouchEvent) {
    const { touches, changedTouches } = evt;

    Array.from(touches).forEach((touch) => {
      this._renew(touch);
    });

    this._setActiveID(changedTouches);

    return this._touchList;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  release(evt: TouchEvent) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this._activeTouchID;

    Array.from(evt.changedTouches).forEach((touch) => {
      this._delete(touch);
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private _add(touch: Touch) {
    if (this._has(touch)) {
      return;
    }

    this._touchList[touch.identifier] = new Tracker(touch);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private _renew(touch: Touch) {
    if (!this._has(touch)) {
      return;
    }

    const tracker = this._touchList[touch.identifier];

    tracker.update(touch);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private _delete(touch: Touch) {
    delete this._touchList[touch.identifier];
  }

  private _has(touch: Touch): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return this._touchList.hasOwnProperty(touch.identifier);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private _setActiveID(touches: TouchList) {
    this._activeTouchID = touches[touches.length - 1].identifier;
  }

  private _getActiveTracker(): Tracker {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _touchList, _activeTouchID } = this;

    return _touchList[_activeTouchID];
  }
}
