import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkModule } from '@beta/ui-common';

import { MenuComponent } from './menu.component';

@NgModule({
  declarations: [MenuComponent],
  imports: [CommonModule, LinkModule],
  exports: [MenuComponent],
})
export class MenuModule {}
