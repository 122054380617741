export enum LinkLineType {
  One = 'one',
  Two = 'two',
  Three = 'three',
  Four = 'four',
  Five = 'five',
  Six = 'six',
  Seven = 'seven',
  Eight = 'eight',
}

export enum LinkLineColor {
  DarkRed = 'D30203',
  Red = 'EB4626',
  Orange = 'F67F1B',
  Yellow = 'FFE600',
  Blue = '31B5FF',
  Purple = '854DFF',
  Green = '009E60',
  Pink = 'FF87C8',
}

export interface NavigationLink {
  title: string;
  slug: string;
  counter?: boolean;
  lineType: LinkLineType;
  lineColor: LinkLineColor;
}
export const navigationLinks: NavigationLink[] = [
  {
    title: 'Проекты',
    slug: 'projects',
    lineType: LinkLineType.One,
    lineColor: LinkLineColor.DarkRed,
  },
  {
    title: 'Услуги',
    slug: 'services',
    lineType: LinkLineType.Three,
    lineColor: LinkLineColor.Blue,
    // TODO: Поменять обратно
  },
  // {
  //   title: 'О нас',
  //   slug: 'about',
  //   lineType: LinkLineType.Three,
  //   lineColor: LinkLineColor.Blue,
  // },
  // {
  //   title: 'Вакансии',
  //   slug: 'vacancies',
  //   counter: true,
  //   lineType: LinkLineType.Four,
  //   lineColor: LinkLineColor.Purple,
  // },
  {
    title: 'Контакты',
    slug: 'contacts',
    lineType: LinkLineType.Five,
    lineColor: LinkLineColor.Red,
  },
];
