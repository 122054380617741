<div
  class="scroll-container"
  [ngClass]="['scroll-container_' + (platformType$ | async)]"
  [class.scroll-disable]="(preloaderIsHidden$ | async) === false"
  #scrollContainer
>
  <div class="scroll-content" #scrollContent>
    <div class="wrapper">
      <router-outlet></router-outlet>
      <app-navigation></app-navigation>
      <app-logo></app-logo>
      <app-menu></app-menu>
    </div>
  </div>
</div>
<app-social-widget></app-social-widget>
<!--<app-transition></app-transition>-->
<app-preloader *ngIf="(preloaderIsHidden$ | async) === false"></app-preloader>
<!--<app-cursor *ngIf="(isMobile$ | async) === false"></app-cursor>-->
