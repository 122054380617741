import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Project } from '@beta/api';
import { ResizeService, SmoothScroll, SmoothScrollService } from '@beta/core';
import { SplitTextDirective } from '@beta/shared';
import {
  ButtonLineColor,
  ButtonLineType,
  IconName,
  IconSize,
} from '@beta/ui-common';
import { SvgDrawStrokeDirective } from '@ng-blue-duct-tape/svg-draw-stroke';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Back, gsap, Power2 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import clamp from 'lodash-es/clamp';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, skip } from 'rxjs/operators';

import { ProjectsItemComponent } from './projects-item';

export interface ItemTrigger {
  index: number;
  triggerPosition: number;
}

@UntilDestroy()
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsComponent implements AfterViewInit {
  @Input() projects!: Project[];

  @ViewChild('projectsWrap') projectsWrap!: ElementRef<HTMLElement>;
  @ViewChild('icon', { read: ElementRef }) icon!: ElementRef<HTMLElement>;
  @ViewChild('title', { read: SplitTextDirective }) title!: SplitTextDirective;
  @ViewChild('counter', { read: SplitTextDirective })
  counter!: SplitTextDirective;

  @ViewChild('description', { read: SplitTextDirective })
  description!: SplitTextDirective;

  @ViewChild('line', { read: SvgDrawStrokeDirective })
  line!: SvgDrawStrokeDirective;

  @ViewChildren('subtitle', { read: SplitTextDirective }) subtitle =
    new QueryList<SplitTextDirective>();

  @ViewChildren('item', { read: ProjectsItemComponent }) item =
    new QueryList<ProjectsItemComponent>();

  @ViewChildren('item', { read: ElementRef }) itemEl = new QueryList<
    ElementRef<HTMLElement>
  >();

  iconName = IconName;
  iconSize = IconSize;
  buttonLineColor = ButtonLineColor;
  buttonLineType = ButtonLineType;

  tlShow = gsap.timeline();
  tlLine = gsap.timeline();
  isShowed = false;
  currentProjectIndex: number | undefined;
  stubHeight = 0;

  itemTriggers: ItemTrigger[] = [];
  itemQuantity = 0;

  public currentProjectIndex$ = new BehaviorSubject<number | undefined>(
    undefined,
  );

  _activeItemOnFastMove = new BehaviorSubject<boolean>(false);
  public activeItemOnFastMove$ = this._activeItemOnFastMove
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    @Inject(WINDOW) private window: Window,
    private cd: ChangeDetectorRef,
    private resizeService: ResizeService,
    private smoothScrollService: SmoothScrollService,
  ) {}

  ngAfterViewInit(): void {
    this.updateValues();
    this.initTitleLineAnimation();
    this.iniShowAnimation();
    this.setCurrentProjectIndex();
    this.showAnimation();

    this.onLeaveTrigger();
    this.onScroll();
    this.onResize();
  }

  updateValues(): void {
    this.window.requestAnimationFrame(() => {
      this.itemTriggers = this.itemEl.reduce(
        (acc: ItemTrigger[], item, index) => {
          acc.push({
            index,
            triggerPosition:
              (this.smoothScrollService.smoothScroll?.offsetY || 0) +
              item.nativeElement.getBoundingClientRect().top -
              this.window.innerHeight / 2,
          });
          return acc;
        },
        [],
      );
      this.itemQuantity = this.itemTriggers.length;
    });
  }

  showAnimation(): void {
    ScrollTrigger.create({
      trigger: this.projectsWrap.nativeElement,
      once: true,
      start: '0 50%',
      onEnter: () => {
        this.tlShow.resume();
      },
    });
  }

  initTitleLineAnimation(): void {
    this.tlLine
      .clear()
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: 0,
        },
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
          duration: 1,
          ease: Power2.easeInOut,
        },
      )
      .pause();
  }

  titleLineAnimation(hover: boolean): void {
    if (!this.isShowed) {
      return;
    }
    this.tlLine.reversed(!hover).resume();
  }

  iniShowAnimation(): void {
    const title = this.title.wordEls;
    const counter = this.counter.wordEls;
    const description = this.description.wordEls;
    this.tlShow
      .clear()
      .fromTo(
        title,
        {
          yPercent: 120,
        },
        {
          yPercent: 0,
          stagger: 0.1,
          duration: 1,
          ease: Power2.easeOut,
        },
        0,
      )
      .fromTo(
        counter,
        {
          yPercent: 120,
        },
        {
          yPercent: 0,
          duration: 1,
          ease: Power2.easeOut,
        },
        0.7,
      )
      .fromTo(
        description,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        0,
      )
      .fromTo(
        this.line.stokeElements[0].element,
        {
          strokeDashoffset: this.line.stokeElements[0].strokeTotal,
        },
        {
          strokeDashoffset: 0,
          duration: 1,
          ease: Power2.easeInOut,
        },
        1,
      )
      .fromTo(
        this.icon.nativeElement,
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 0.5,
          ease: Back.easeOut,
        },
        0,
      );

    this.subtitle.forEach((item, index) => {
      this.tlShow.fromTo(
        item.wordEls,
        {
          yPercent: 105,
        },
        {
          yPercent: 0,
          duration: 0.5,
          ease: Power2.easeOut,
        },
        index * 0.1,
      );
    });

    this.item
      .toArray()
      .slice(0, 4)
      .forEach((item, index) => {
        this.tlShow.add(item.tlShow, index * 0.2);
      });

    this.item
      .toArray()
      .slice(4)
      .forEach((item) => {
        this.tlShow.add(item.tlShow, 4 * 0.2);
      });

    this.tlShow
      .add(() => {
        if (!this.resizeService.isMobile) {
          this.currentProjectIndex$.next(0);
        }
      }, 0.6)
      .add(() => {
        this.isShowed = true;
      })
      .pause();
  }

  onScroll(): void {
    combineLatest([
      this.smoothScrollService.smoothScroll$.pipe(
        filter((value): value is SmoothScroll => !!value),
        distinctUntilChanged(),
      ),
      this.smoothScrollService.scrollSpeed$.pipe(distinctUntilChanged()),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([scroll, speed]) => {
        if (speed !== 0) {
          return;
        }
        requestAnimationFrame(() => {
          for (const item of this.itemTriggers) {
            if (scroll.offsetY < item.triggerPosition) {
              this.currentProjectIndex$.next(item.index - 1);
              break;
            } else if (item.index === this.itemTriggers.length - 1) {
              this.currentProjectIndex$.next(item.index);
              break;
            }
          }
        });
      });
    // this.smoothScrollService.smoothScroll$
    //   .pipe(
    //     filter((value): value is SmoothScroll => !!value),
    //     distinctUntilChanged(),
    //     untilDestroyed(this),
    //   )
    //   .subscribe((scroll) => {
    //     requestAnimationFrame(() => {
    //       for (const item of this.itemTriggers) {
    //         if (scroll.offsetY < item.triggerPosition) {
    //           this.currentProjectIndex$.next(item.index - 1);
    //           break;
    //         } else if (item.index === this.itemTriggers.length - 1) {
    //           this.currentProjectIndex$.next(item.index);
    //           break;
    //         }
    //       }
    //     });
    //   });
  }

  hoverItem(index: number | undefined): void {
    if (
      this.currentProjectIndex$.value !== index &&
      !this.resizeService.isMobile
    ) {
      this.currentProjectIndex$.next(index);
    }
  }

  setCurrentProjectIndex(): void {
    combineLatest([
      this.currentProjectIndex$.pipe(
        skip(1),
        map((index) => clamp(index || 0, 0, this.itemQuantity - 1)),
        distinctUntilChanged(),
      ),
      this.smoothScrollService.scrollSpeed$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([index, scrollSpeed]) => {
        if (index !== this.currentProjectIndex) {
          this.currentProjectIndex = index;
          this.cd.markForCheck();
        }
        // this._activeItemOnFastMove.next(scrollSpeed > 2.5);
        this._activeItemOnFastMove.next(scrollSpeed > 0);
      });
  }

  onResize(): void {
    this.resizeService.currentResolution$
      .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.updateValues();
      });
  }

  onLeaveTrigger(): void {
    ScrollTrigger.create({
      trigger: this.projectsWrap.nativeElement,
      end: '100% 0',
      invalidateOnRefresh: true,
      onLeave: (trigger) => {
        if (this.isShowed) {
          this.title.removeSplit();
          this.description.removeSplit();
          this.counter.removeSplit();
          this.subtitle.forEach((item) => {
            item.removeSplit();
          });
          trigger.kill();
        }
      },
    });
  }
}
