import { Lang } from '@beta/api';
import { IconName } from '@beta/ui-common';

export enum HeaderType {
  Projects = 'projects',
  Services = 'services',
  About = 'about',
  Vacancies = 'vacancies',
  Contacts = 'contacts',
}

export interface HeaderDate {
  title: string;
  description: string;
  icon: IconName;
}

export const headerData: {
  [i in HeaderType]?: { [k in Lang]?: HeaderDate };
} = {
  [HeaderType.Projects]: {
    [Lang.Ru]: {
      title: 'Проекты',
      description: 'Которыми мы <br> действительно <br> гордимся',
      icon: IconName.Like,
    },
    [Lang.En]: {
      title: 'Projects',
      description: 'We are truly proud of',
      icon: IconName.Like,
    },
  },
  [HeaderType.About]: {
    [Lang.Ru]: {
      title: 'О нас',
      description:
        'придумаем, спроектируем <br> и реализуем цифровой продукт <br> от концепции до финального <br> результата',
      icon: IconName.Star2,
    },
    [Lang.En]: {
      title: 'About',
      description:
        'we will think up, design and we implement a digital product from concept to final result',
      icon: IconName.Star2,
    },
  },
  [HeaderType.Vacancies]: {
    [Lang.Ru]: {
      title: 'Вакансии',
      description:
        'всегда находимся в поиске <br> людей близких по духу <br> и ценностям',
      icon: IconName.Search,
    },
    [Lang.En]: {
      title: 'Vacancies',
      description:
        'we are always in search of people who are close in spirit and values',
      icon: IconName.Search,
    },
  },
  [HeaderType.Services]: {
    [Lang.Ru]: {
      title: 'Услуги',
      description:
        'Не занимаемся SMM, <br> рекламкой и проектами, <br> которые обманывают людей',
      icon: IconName.Cancel,
    },
    [Lang.En]: {
      title: 'Services',
      description:
        'We are not engaged in SMM, advertising and projects that deceive people',
      icon: IconName.Cancel,
    },
  },
};
