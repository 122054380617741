import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CursorActionModule } from '@beta/shared';
import { SvgDrawStrokeModule } from '@ng-blue-duct-tape/svg-draw-stroke';

import { LogoComponent } from './logo.component';

@NgModule({
  declarations: [LogoComponent],
  imports: [CommonModule, SvgDrawStrokeModule, CursorActionModule],
  exports: [LogoComponent],
})
export class LogoModule {}
