import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CursorActionModule, SplitTextModule } from '@beta/shared';
import { SvgDrawStrokeModule } from '@ng-blue-duct-tape/svg-draw-stroke';

import { IconModule } from '../icon';
import { NoiseModule } from '../noise';

import { ButtonComponent } from './button.component';

@NgModule({
  declarations: [ButtonComponent],
  imports: [
    NoiseModule,
    CommonModule,
    IconModule,
    CursorActionModule,
    SplitTextModule,
    SvgDrawStrokeModule,
  ],
  exports: [ButtonComponent],
})
export class ButtonModule {}
