import { createFeatureSelector, createSelector } from '@ngrx/store';

import { VACANCIES_FEATURE_KEY, VacanciesState } from './vacancies.reducer';

export const getVacanciesState = createFeatureSelector<VacanciesState>(
  VACANCIES_FEATURE_KEY,
);

export const getVacancies = createSelector(
  getVacanciesState,
  (state) => state.vacancies,
);

export const getVacanciesLoading = createSelector(
  getVacanciesState,
  (state) => state.loading,
);

export const getVacanciesError = createSelector(
  getVacanciesState,
  (state) => state.error,
);
