import { Vacancy } from '@beta/api';
import { createAction, props } from '@ngrx/store';

export enum VacanciesActionType {
  LOAD_VACANCIES = '[Vacancies] Load Vacancies',
  LOAD_VACANCIES_SUCCESS = '[Vacancies] Load Vacancies Success',
  LOAD_VACANCIES_FAILURE = '[Vacancies] Load Vacancies Failure',
}

export const loadVacancies = createAction(VacanciesActionType.LOAD_VACANCIES);

export const loadVacanciesSuccess = createAction(
  VacanciesActionType.LOAD_VACANCIES_SUCCESS,
  props<{
    vacancies: Vacancy[];
  }>(),
);

export const loadVacanciesFailure = createAction(
  VacanciesActionType.LOAD_VACANCIES_FAILURE,
  props<{ error: string }>(),
);
