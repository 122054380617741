import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApiModule } from '@beta/api';
import { CoreModule } from '@beta/core';
import {
  HeaderImagesModule,
  MetaModule,
  ProjectsModule,
  ServicesModule,
  VacanciesModule,
} from '@beta/store';
import { CursorModule, SocialWidgetModule } from '@beta/ui-common';
import {
  LogoModule,
  MenuModule,
  NavigationModule,
  PreloaderModule,
  TransitionModule,
} from '@beta/ui-layout';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment.prod';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    MenuModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    ApiModule,
    MetaModule,
    HeaderImagesModule,
    ProjectsModule,
    VacanciesModule,
    ServicesModule,
    PreloaderModule,
    CursorModule,
    NavigationModule,
    LogoModule,
    TransitionModule,
    SocialWidgetModule,
    StoreModule.forRoot({}),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
