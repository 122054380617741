import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SplitTextModule } from '@beta/shared';

import { PreloaderComponent } from './preloader.component';

@NgModule({
  declarations: [PreloaderComponent],
  imports: [CommonModule, SplitTextModule],
  exports: [PreloaderComponent],
})
export class PreloaderModule {}
